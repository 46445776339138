import http from './apiServices';
import {makeAuthHeader} from './auth';
import _config from '../assets/helper/apiHelper.json';
import _default from '../assets/helper/default';
import * as _ from "lodash";
import moment from "moment-jalaali";
import jsPDF from 'jspdf';
import {persianDigit, personalInfoStatus} from "./dashboard";


export function getLocalStorage() {
    return JSON.parse(localStorage.getItem(_default.tokenName));
}

export async function getFundSimples() {

    const requestData = {
        "exportTo": "json",
        "columnNames": [
            {"filedName": "personalityMaximumOwnershipPercent", "displayTitle": " حداکثر مقدار مالکیت شخص"},
            {"filedName": "maximumUnitCapital", "displayTitle": "حداکثر واحد سرمایه"},
            {"filedName": "minimumUnitCapital", "displayTitle": " حداقل واحد سرمایه"},
            {"filedName": "legalTitle", "displayTitle": "عنوان صندوق"},
            {"filedName": "abbreviation", "displayTitle": "نام اختصاری صندوق"},
            {"filedName": "registerNumber", "displayTitle": "شماره ثبت صندوق"},
            {"filedName": "legalRegistrationDate", "displayTitle": "تاریخ ثبت"},
            {"filedName": "fundTypeTitle", "displayTitle": "نوع صندوق"},
            {"filedName": "isCharityFund", "displayTitle": "صندوق نیکوکاری"},
            {"filedName": "isEtf", "displayTitle": "صندوق‌ قابل معامله در بورس"},
            {"filedName": "annualProfit", "displayTitle": "نرخ بازدهی صندوق"},
            {"filedName": "navCalcDate", "displayTitle": "تاریخ آخرین ان ای وی"},
            {"filedName": "navFundCapital", "displayTitle": "تعداد کل واحدهای صندوق "},
            {"filedName": "navPurchase", "displayTitle": "ان ای وی صدور"},
            {"filedName": "navSale", "displayTitle": "ان ای وی ابطال"},
            {"filedName": "navStatistic", "displayTitle": "Nav آماری	"},
            {"filedName": "navIssuedUnit", "displayTitle": "جمع تعداد واحدهای صادر شده"},
            {"filedName": "navRevokedUnit", "displayTitle": "جمع تعداد واحدهای باطل شده"}
        ]
    };

    return await http.post(_config.getFundSimples, requestData);
}

export async function GetFundInfoByFundId(fundId = _default.fundRegisterNumber) {
    const requestGetUrl = _config.getFundInfoById + fundId;
    return await http.get(requestGetUrl);
}

export function groupByData(info) {
    info = _.groupBy(info, row => row.fundManagerTypeId);
    return info;
}

export function prepareFundGrid(info, gridOption) {
    for (let opt in gridOption) {
        console.log(opt);
    }
    return '';
}

export function convertCurrencyTpye(data) {
    if (data !== "" || data !== null) {
        data = parseInt(data);
        return data.toLocaleString();
    } else {
        return data;
    }
}

export async function getCustomerAssets() {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requstData = {
        "personalityId": personalityId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.getFundAssets, requstData, opt);
}

export async function getFundBankAccountList(fundRegisterNumber = _default.fundRegisterNumber) {
    const Url = _config.getFundBankAccountList + fundRegisterNumber;
    const opt = makeAuthHeader();
    return await http.post(Url, {}, opt);
}

export async function setBuyFundWithBankAccount(info, fundId) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];

    const requestData = {
        "fundRegisterNumber": fundId,
        "trackingNumber": info.trackingNumber,
        "receiptNumber": info.trackingNumber,
        "personalityId": personalityId,
        "receiptAmount": info.amount,
        "receiptedDate": info.payDate,
        "description": info.description,
        "registerNumber": fundId,
        "destinationAccountId": info.bankAccount
    };

    const opt = makeAuthHeader();
    return await http.post(_config.setBuyFundWithBankAccount, requestData, opt);


}

export async function getBankAccountList() {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {"personalityId": personalityId};
    const opt = makeAuthHeader();
    return await http.post(_config.setBankAccountListById, requestData, opt);
}

export async function setRevokeOrder(info, fundId, licenseNumber) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "licenseNumber": licenseNumber,
        "personalityId": personalityId,
        "description": info.description,
        "fundRegisterNumber": fundId,
        "requestQuantity": info.unit,
        "withdrawalBankAccountId": info.bankAccount

    };
    const opt = makeAuthHeader();
    return await http.post(_config.setRevokeOrder, requestData, opt);
}

export function SortInfo(list) {
    list = _.orderBy(list, ['id'], ['desc']);
    return list;
}

export function checkTimeStatus(time) {
    return parseInt(moment(time).format("HH")) > _default.endBuyTime;
}

export async function licenseDownload(fundId) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId,
        "fundRegisterNumber": fundId,
        "exportTo": "html"
    };
    const opt = makeAuthHeader();
    return await http.post(_config.getLicenseContent, requestData, opt);

}

export function convertToPdf(info, name) {
    let doc = new jsPDF();
    doc.text(info, 10, 10);
    return doc.save(name);
}

export async function getFundAssetsBarChart(fundId = _default.fundRegisterNumber) {

    // api/FundBasic/GetFundAssets?registerNumber=10929
    return await http.get(_config.getFundAssetsChart + fundId);
}

// efficintly chart
export async function getEfficentChart(currentDate, timeFrame, fundId = _default.fundRegisterNumber) {
    currentDate = moment(currentDate).format("YYYY-MM-DD");
    const startDate = convertTimeFrameToDate(currentDate, timeFrame);
    timeFrame = timeFrame.toString();
    const requestData = {
        "fundRegisterNumber": fundId,
        "timeFrame": timeFrame,
        "fromDateTime": startDate,
        "toDateTime": currentDate
    };
    return await http.post(_config.getFundStatisticalStatistics, requestData);

}

export function convertTimeFrameToDate(currentDate, timeFrame) {

    switch (timeFrame.toString()) {
        case("1"): {
            return moment(currentDate).subtract(1, 'months').format("YYYY-MM-DD");
        }
        case("3"): {
            return moment(currentDate).subtract(12, 'months').format("YYYY-MM-DD");
        }
        case("4"): {
            return moment(currentDate).subtract(24, 'months').format("YYYY-MM-DD");
        }
        case("6"): {
            return moment(currentDate).subtract(10, 'years').format("YYYY-MM-DD");
        }
        default: {
            return currentDate;
        }
    }
}

// prepare efficient chart Data
export function prepareChartData(data, timeFrame) {
    let chartSeries = [];
    let chartCategory = [];
    let timeFrameIndex = _default.timeFrameIdToIndex[timeFrame];


    for (const index in data) {
        chartSeries.push(data[index][timeFrameIndex]);
        chartCategory.push(moment(data[index]['fromDateTime']).format("jYYYY/jM/jD"));
    }

    return {series: chartSeries, cat: chartCategory};
}

// nav chart
export async function getNavChart(currentDate, timeFrame = "1", fundId = _default.fundRegisterNumber) {
    currentDate = moment(currentDate).format("YYYY-MM-DD");
    const startDate = convertTimeFrameToDate(currentDate, timeFrame);
    timeFrame = timeFrame.toString();
    const requestData = {
        "fundRegisterNumber": fundId,
        "timeFrame": timeFrame,
        "fromDateTime": startDate,
        "toDateTime": currentDate
    };
    return await http.post(_config.getFundNavStatistics, requestData);
}

export function prepareNavChartData(data) {
    let chartSeries = [];
    let chartCategory = [];
    let navChartField = _default.navTypeIndex;
    let finalExport = [];
    let navInfo = [];
    for (const navType of navChartField) {
        for (const row of data) {
            navInfo.push(row[navType['name']]);
            if (finalExport.length === 0) {
                chartCategory.push(moment(row['fromDateTime']).format("jYYYY/jM/jD"));
            }
        }
        finalExport.push({name: navType.faTitle, data: navInfo});
        navInfo = [];
    }
    return {series: finalExport, cat: chartCategory};
}

export async function uploadBuyPaymentDocument(file, orderId) {
    let formInfo = new FormData;
    formInfo.append("issuingOrderAttachmentFile", file[0]);
    formInfo.append("orderId", orderId);
    const opt = makeAuthHeader();
    return await http.post(_config.uploadBuyPaymentDocument, formInfo, opt);
}

export async function cancelOrder(orderId) {
    const requestData = {
        "orderId": orderId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.cancelOrder, requestData, opt);
}

export async function orderHistory(orderId) {
    const requestData = {
        "orderId": orderId,
        "orderBy": "Id",
        "direction": "desc"
    };
    const opt = makeAuthHeader();
    return await http.post(_config.orderHistory, requestData, opt);
}

export function cancelOrderLocal(orders, orderId) {

    // orderStatusEnglishTitle: "Canceled"
    // orderStatusId: 5
    // orderStatusTitle: "لغو"
    // orderTypeEnglishTitle: "Issuing"

    let finalExport = [];
    for (let order of orders) {
        if (order.id === orderId) {
            order['orderStatusEnglishTitle'] = "Canceled";
            order['orderStatusId'] = 5;
            order['orderStatusTitle'] = "لغو";
        }
        finalExport.push(order);
    }
    return finalExport;
}

export async function GetOrderReceiptByOrderId(orderId) {
    const requestData = {
        "orderId": orderId,
        "exportTo": "html",
    };
    const opt = makeAuthHeader();
    return await http.post(_config.GetOrderReceiptByOrderId, requestData, opt);
}

export async function setMessageReplay(msg, msgId, state = "1") {

    const requestData = {
        "contactUsMessageId": msgId,
        "contactUsMessageStateId": state,
        "description": msg
    };
    const opt = makeAuthHeader();
    return await http.post(_config.replayMessage, requestData, opt);
}

export function newMsgLocal(newMsg, msgList) {
    let finalList = [];
    if (!_.isEmpty(msgList)) {
        msgList.histories.push(newMsg);
    }
    return msgList;
}

export async function getMsgTag() {
    return await http.get(_config.getMsgTag);
}

export function prepareTagsOptions(info) {
    let exportInformation = [];
    info = _.orderBy(info, ['title'], ['asc']);
    for (let row of info) {
        exportInformation.push({
            value: row.id,
            label: row.title
        });
    }
    exportInformation = _.orderBy(exportInformation, ['label'], ['asc']);
    return exportInformation;
}

export async function sendNewMessage(info) {

    const requestData = {
        "senderName": "",
        "phoneNumber": "",
        "body": info.msg,
        "subject": info.subject,
        "url": "www.aminsaman.com",
        "email": "",
        "tags": info.category
    };
    const opt = makeAuthHeader();
    return await http.post(_config.sendNewMsg, requestData, opt);
}

export async function licenseIssuanceHistories(info, exportType = "") {
    console.log(info);
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];

    const bodyData = {

        "personalityId": personalityId,
        "personalityTitle": "",
        "personalityNationalCode": "",
        "issuanceLicenseNumber": "",
        "personalityTypeId": "",

        "fromDateTime": info.fromDate,
        "toDateTime": info.toDate,
        "skip": info.start,
        "take": _default.pagingSize,
        "orderBy": "id",
        "direction": "desc",

        "exportTo": exportType,
        "columnNames": [

            {"filedName": "id", "displayTitle": "id"},
            {"filedName": "fundLicenseAssetId", "displayTitle": "fundLicenseAssetId"},
            {"filedName": "fundId", "displayTitle": "fundId"},
            {"filedName": "fundRegisterNumber", "displayTitle": "fundRegisterNumber"},
            {"filedName": "fundAbbreviation", "displayTitle": "fundAbbreviation"},
            {"filedName": "fundTitle", "displayTitle": "fundTitle"},
            {"filedName": "fundEnglishTitle", "displayTitle": "fundEnglishTitle"},
            {"filedName": "personalityId", "displayTitle": "personalityId"},
            {"filedName": "personalityTypeId", "displayTitle": "personalityTypeId"},
            {"filedName": "personalityNationalCode", "displayTitle": "personalityNationalCode"},
            {"filedName": "personalityTitle", "displayTitle": "personalityTitle"},
            {"filedName": "personalityEnglishTitle", "displayTitle": "personalityEnglishTitle"},
            {"filedName": "licenseIssuanceLicenseNumber", "displayTitle": "licenseIssuanceLicenseNumber"},
            {"filedName": "licenseIssuanceQuantity", "displayTitle": "licenseIssuanceQuantity"},
            {"filedName": "licenseIssuanceEntryUserId", "displayTitle": "licenseIssuanceEntryUserId"},
            {"filedName": "licenseIssuanceEntryDate", "displayTitle": "licenseIssuanceEntryDate"},
            {"filedName": "licenseIssuanceDescription", "displayTitle": "licenseIssuanceDescription"},
            {"filedName": "licenseIssuanceEnglishDescription", "displayTitle": "licenseIssuanceEnglishDescription"}
        ]
    };
    const opt = makeAuthHeader(exportType);
    return await http.post(_config.getIssuingHistory, bodyData, opt);
}


// sina
export async function getLoginLogoutList(info, exportType = "") {
    console.log(info);
    // control eportType intialise
    const userInfo = getLocalStorage();
    const userId = userInfo[_default.userInfoTokenName]['userId'];
    const bodyData = {
        "userId": userId,
        "fromDateTime": info.fromDate,
        "toDateTime": info.toDate,
        "skip": info.startId,
        "take": _default.pagingSize,
        "orderBy": "id",
        "direction": "desc",

        "exportTo": exportType,
        "columnNames": [
            {"filedName": "id", "displayTitle": "id"},
            {"filedName": "userId", "displayTitle": "userId"},
            {"filedName": "userName", "displayTitle": "userName"},
            {"filedName": "userTypeId", "displayTitle": "userTypeId"},
            {"filedName": "code", "displayTitle": "code"},
            {"filedName": "userStateTypeId", "displayTitle": "userStateTypeId"},
            {"filedName": "userStateTypeTitle", "displayTitle": "userStateTypeTitle"},
            {"filedName": "userStateTypeEnglishTitle", "displayTitle": "userStateTypeEnglishTitle"},
            {"filedName": "entryUserName", "displayTitle": "entryUserName"},
            {"filedName": "entryUserIp", "displayTitle": "entryUserIp"},
            {"filedName": "entryDate", "displayTitle": "entryDate"}
        ]
    };
    const opt = makeAuthHeader(exportType);
    return await http.post(_config.getUserLoginLogoutResponse, bodyData, opt);
}

export async function getOrdersList(info, exportType = "") {
    console.log(info);
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];

    const bodyData = {
        "fundId": "",
        "fundRegisterNumber": "",
        "personalityId": personalityId,
        "personalityTitle": "",
        "personalityNationalCode": "",
        "entryUserName": "",
        "orderId": "",
        "orderTypeId": "",
        "internalOrderId": "",
        "internalOrderNumber": "",
        "orderStatuses": "",

        "fromDateTime": info.fromDate,
        "toDateTime": info.toDate,
        "skip": info.startId,
        "take": _default.pagingSize,
        "orderBy": "id",
        "direction": "desc",

        "exportTo": exportType,
        "columnNames": [
            {"filedName": "fundAbbreviation", "displayTitle": "fundAbbreviation"},
            {"filedName": "fundTitle", "displayTitle": "fundTitle"},
            {"filedName": "fundEnglishTitle", "displayTitle": "fundEnglishTitle"},
            {"filedName": "fundRegisterNumber", "displayTitle": "fundRegisterNumber"},
            {"filedName": "personalityTitle", "displayTitle": "personalityTitle"},
            {"filedName": "personalityEnglishTitle", "displayTitle": "personalityEnglishTitle"},
            {"filedName": "personalityNationalCode", "displayTitle": "personalityNationalCode"},
            {"filedName": "entryDate", "displayTitle": "entryDate"},
            {"filedName": "entryUserName", "displayTitle": "entryUserName"},
            {"filedName": "modifyDate", "displayTitle": "modifyDate"},
            {"filedName": "modifyUserName", "displayTitle": "modifyUserName"},
            {"filedName": "issuingRequestAmount", "displayTitle": "issuingRequestAmount"},
            {"filedName": "revokeRequestQuantity", "displayTitle": "revokeRequestQuantity"},
            {"filedName": "internalOrderNumber", "displayTitle": "internalOrderNumber"},
            {"filedName": "executedAmount", "displayTitle": "executedAmount"},
            {"filedName": "price", "displayTitle": "price"},
            {"filedName": "quantity", "displayTitle": "quantity"},
            {"filedName": "orderStatusTitle", "displayTitle": "orderStatusTitle"},
            {"filedName": "orderStatusEnglishTitle", "displayTitle": "orderStatusEnglishTitle"},
            {"filedName": "orderTypeTitle", "displayTitle": "orderTypeTitle"},
            {"filedName": "orderTypeEnglishTitle", "displayTitle": "orderTypeEnglishTitle"}
        ]

    };
    const opt = makeAuthHeader(exportType);
    return await http.post(_config.getOrdersResponse, bodyData, opt);
}

export async function getTransactionsList(info, exportType = "") {
    console.log(info);
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];

    const bodyData = {
        "personalityId": personalityId,
        "personalityTitle": "",
        "personalityNationalCode": "",

        "bankAccountId": "",
        "bankAccountNumber": "",
        "transactionTypeTitle": "",
        "transactionTypes": "",

        "fromDateTime": info.fromDate,
        "toDateTime": info.toDate,
        "skip": info.startId,
        "take": _default.pagingSize,
        "orderBy": "id",
        "direction": "desc",

        "exportTo": exportType,
        "columnNames": [
            {"filedName": "transactionTypeTitle", "displayTitle": "transactionTypeTitle"},
            {"filedName": "transactionTypeEnglishTitle", "displayTitle": "transactionTypeEnglishTitle"},
            {"filedName": "operationTypeTitle", "displayTitle": "operationTypeTitle"},
            {"filedName": "operationTypeEnglishTitle", "displayTitle": "operationTypeEnglishTitle"},
            {"filedName": "bankTitle", "displayTitle": "bankTitle"},
            {"filedName": "bankAccountNumber", "displayTitle": "bankAccountNumber"},
            {"filedName": "personalityTitle", "displayTitle": "personalityTitle"},
            {"filedName": "personalityNationalCode", "displayTitle": "personalityNationalCode"},
            {"filedName": "value", "displayTitle": "value"},
            {"filedName": "balance", "displayTitle": "balance"},
            {"filedName": "transactionDate", "displayTitle": "transactionDate"},
            {"filedName": "entryDate", "displayTitle": "entryDate"},
            {"filedName": "description", "displayTitle": "description"},
            {"filedName": "englishDescription", "displayTitle": "englishDescription"}
        ]
    };
    const opt = makeAuthHeader(exportType);

    return await http.post(_config.getTransactionsResponse, bodyData, opt);
}

export async function getTransactionType() {
    return http.get(_config.getTransactonTypeList);
}

export async function getMessages() {
    const userInfo = getLocalStorage();
    const userId = userInfo[_default.userInfoTokenName]['userId'];

    const bodyData = {
        "email": "",
        "phone": "",
        "subject": "",
        "filterClientIp": "",

        "fromDateTime": "2010-08-28",
        "toDateTime": "2019-09-01",
        "skip": "0",
        "take": "40",
        "orderBy": "id",
        "direction": "desc"

    };
    const opt = makeAuthHeader();

    return await http.post(_config.getMessages, bodyData, opt);
}

export async function getMessagesCount() {
    const opt = makeAuthHeader();
    return await http.post(_config.getNewMsgCount, {}, opt);
}

export async function getMessagesById(id) {
    const bodyData = {
        "contactUsMessageId": id
    };
    const opt = makeAuthHeader();
    return await http.post(_config.getMessagesById, bodyData, opt);
}

export function pagingHandler(count, currentPage) {

    // calc totalPage
    const pageSize = _default.pagingSize;
    const pageCount = Math.ceil(parseInt(count) / pageSize);

    // calc start/end id
    let start = (parseInt(currentPage) - 1) * pageSize;
    let end = ((parseInt(currentPage)) * pageSize) - 1;

    let loadMore = true;
    if (currentPage === pageCount) {
        loadMore = false;
    }

    return {pageCount, currentPage, start, end, loadMore};


}

export function newMsgCount(list) {
    let count = 0;
    list.contactUsMessages.map(row => {
        if (row.lastChangeByAdmin) {
            count++;
        }
    });
    return count
}

// aliQ related Sina

export function prepareLogsTableRow(info) {
    let rows = [];
    for (let i = 1; i < info.length; i++) {
        let x = [
            persianDigit(i.toString()),
            persianDigit(moment(info[i].entryDate).format('jYYYY/jM/jD')),
            persianDigit(moment(info[i].entryDate).format('HH:mm')),
            info[i].entryUserIp,
            info[i].userStateTypeTitle
        ];
        rows.push(x);
    }
}

export function initalFilterDate(currentDate = "") {
    console.log("bb", currentDate);
    if (currentDate !== "") {
        console.log(currentDate);
        // return moment(currentDate).subtract(1, 'months').format("YYYY-MM-DD");
    } else {
        const okok = http.get(_config.GetFundMarketStatus);
        console.log(okok);
    }


}


// End aliQ related Sina


export function personalInfoStatusForIssuing() {
    // requied fields
    // 1,2,6,16,32 should be true
    //256,512 one be true

    const info = personalInfoStatus();
    let personalRequireInfo = ["PersonalInformation", "BankAccount", "Phones", "IncompleteInformation"];
    const personalRequireInfoErrors = {
        "PersonalInformation": "اطلاعات اولیه",
        "BankAccount": "اطلاعات بانکی",
        "Phones": "اطلاعات تماس",
        "IncompleteInformation": "اطلاعات اولیه"
    };
    let externalRequireInfo = ["KnowYourCustomerByFund", "KnowYourCustomerBySamanBank"];
    const externalRequireInfoErrors = {
        "KnowYourCustomerByFund": "تایید صندوق",
        "KnowYourCustomerBySamanBank": "تایید بانک سامان"
    };


    let errors = [];
    let personFinalStatus = true;
    personalRequireInfo.map(row => {
        if (!personalRequireInfo.includes(row)) {
            errors.push(personalRequireInfoErrors[row])
        }
        personFinalStatus = info[row] && personFinalStatus;
    });

    let externalFinalStatus = false;
    externalRequireInfo.map(row => {
        externalFinalStatus = info[row] || externalFinalStatus;
    });

    return {status: personFinalStatus && externalFinalStatus, errors};
}