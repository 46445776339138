import http from './apiServices';
import {makeAuthHeader} from './auth';
import _config from '../assets/helper/apiHelper.json';
import _success from '../assets/helper/successMsgHelper.json';
import _default from '../assets/helper/default';
import config from "../assets/helper/apiHelper";
import {setStore} from "trim-redux";
import React from "react";
import _ from "lodash";

export function setBackgroundDefault() {
    document.body.style.background = "#262261";
}

export function getCaptchaUrl() {
    const captchaKey = Math.floor(Math.random() * _default.random_length);
    return {
        'address': _default.api_base_url + _config.getCaptcha + "?captchaKey=" + captchaKey,
        'captchaKey': captchaKey
    }
}

export function verifyCaptcha(data) {
    let info = {
        captcha: data.captcha,
        captchaKey: data.captchaKey
    };
    return http.post(_config.captchaValidation, info);
}

export function verifyUser(data) {
    const info = new URLSearchParams();
    info.append("username", data.username);
    info.append("password", data.password);
    info.append("grant_type", "password");
    info.append("clientIp", '192.168.1.110');
    return http.post(config.login, info);
}

export function checkToken(id) {
    const requestData = {
        "entryUserId": id
    };
    let opt = makeAuthHeader();
    return http.post(_config.validateToken, requestData, opt);

}

export async function checkNationalCode(info) {
    let reqData = {
        "fundRegisterNumber": _default.fundRegisterNumber,
        "nationalCode": info.username,
        "clientIp": "192.168.1.110"
    };
    let exportObject = {newUser: false, type: 'error', msg: 'اطلاعات موجود نیست!', data: {}};
    await http.post(config.checkNationalCodeExCustomer, reqData)
        .then(response => {
            let responseBody = response.data;
            if (responseBody.isSuccess) {
                let msgText = _success.existUserMsg;
                msgText = msgText.replace("%NOTIFI%", responseBody.notifications.sms);
                exportObject = {newUser: false, type: 'success', msg: msgText, data: {}};
            } else {
                let userStatus = responseBody.exceptions[0]['exception'];
                if (userStatus["code"] === '000014') {
                    // new user
                    exportObject = {
                        newUser: true,
                        type: 'success',
                        msg: 'next step',
                        data: {nationalCode: info.username}
                    };
                } else {
                    let msgText = responseBody.exceptions[0]['exception']['persianDescription'];
                    exportObject = {newUser: false, type: 'error', msg: msgText, data: {}};
                }
            }
        })
        .catch(error => {
            exportObject = {newUser: false, type: 'error', msg: 'خطای سیستمی' + error, data: {}};
        });

    return exportObject;

}

export function submitMobile(info) {
    let requestData = {
        nationalCode: info.username,
        mobileNumber: info.mobile
    };
    return http.post(config.submitMobileRegister, requestData);
}

export async function verificationMobile(info) {
    let requestData = {
        "clientIp": "192.168.1.110",
        "verificationCode": info.code,
        "registrationVerificationId": info.verificationId
    };
    let exportObject = {success: false, type: 'error', msg: 'اطلاعات موجود نیست!'};
    await http.post(config.verifyMobileNumber, requestData)
        .then(response => {
            let responseBody = response.data;
            if (responseBody.isSuccess) {
                let msgText = _success.existUserMsg;
                msgText = msgText.replace("%NOTIFI%", responseBody.notifications.sms);
                exportObject = {success: true, type: 'success', msg: msgText};
            } else {
                let msgText = responseBody.exceptions[0]['exception']['persianDescription'];
                exportObject = {success: false, type: 'error', msg: msgText};
            }
        })
        .catch(error => {
            exportObject = {success: false, type: 'error', msg: 'خطای سیستمی' + error};
        });

    return exportObject;
}

export async function reGenerate(info) {
    let requestData = {
        "registrationVerificationId": info.verificationId
    };
    let exportObject = {newCode: false, type: 'error', msg: 'اطلاعات موجود نیست!', data: {}};
    await http.post(config.reGenerateVerificationCode, requestData)
        .then(response => {
            const responseBody = response.data;
            if (responseBody.isSuccess) {
                let msgText = _success.newVerifyCode;
                msgText = msgText.replace("%NOTIFI%", responseBody.notifications.sms);
                exportObject = {newCode: true, type: 'success', msg: msgText, data: responseBody};
            } else {
                let msgText = responseBody.exceptions[0]['exception']['persianDescription'];
                exportObject = {newCode: false, type: 'error', msg: msgText, data: responseBody};
            }
        })
        .catch(err => {
            exportObject = {newCode: false, type: 'error', msg: 'خطای سیستمی', data: {}};
        });
    return exportObject;

}

export async function setAuth(token, userInfo) {

    let requestForUserInfo = {
        "userName": userInfo.username,
        "password": userInfo.password
    };
    const tokenKey = "bearer " + token.access_token;
    const opt = {headers: {"Authorization": tokenKey}};
    let responseUserInfo = '';
    await http.post(_config.userInfo, requestForUserInfo, opt)
        .then(res => {
            responseUserInfo = res.data;
        })
        .catch(err => {
            responseUserInfo = '';
            console.log(err);
        });
    const finalSaved = {};
    finalSaved[_default.authTokenName] = token;
    finalSaved[_default.userInfoTokenName] = setCustomInfo(responseUserInfo);
    setStore({profileInfo: setCustomInfoInStore(responseUserInfo)});
    localStorage.setItem(_default.tokenName, JSON.stringify(finalSaved));
}

export function setCustomInfo(data) {
    data = data['userInfo'];
    const reqKey = _default.customUserInfo;
    let info = {};
    for (let row of reqKey) {
        info[row] = data[row];
    }
    return info;
}

export function setCustomInfoInStore(data) {
    data = data['userInfo'];
    const reqKey = _default.customUserInfoInStor;
    let info = {};
    for (let row of reqKey) {
        info[row] = data[row];
    }
    return info;
}

export function clearUserInfoLoginAgain() {
    localStorage.removeItem(_default.tokenName);
}

export async function forgetPassword(info) {
    let reqData = {
        "nationalCode": info.username,
    };
    let exportObject = {status: false, type: 'error', msg: 'اطلاعات موجود نیست!', data: {}};
    await http.post(config.forgetPassword, reqData)
        .then(response => {
            let responseBody = response.data;
            if (responseBody.isSuccess) {
                let msgText = _success.forgetPasswordMsg;
                msgText = msgText.replace("%NOTIFI%", responseBody.notifications.sms);
                exportObject = {status: true, type: 'success', msg: msgText, data: {}};
            } else {
                let msg = response.data.exceptions[0];
                msg = msg.exception.persianDescription;
                exportObject = {status: false, type: 'error', msg: msg, data: {}};
            }
        })
        .catch(error => {
            exportObject = {status: false, type: 'error', msg: 'خطای سیستمی' + error, data: {}};
        });

    return exportObject;
}

export function randomKeyBoard() {
    let row1 = '1 2 3 4 5 6 7 8 9 0 - =';
    let row2 = 'q w e r t y u i o p [ ]';
    let row3 = 'a s d f g h j k l';
    let row4 = 'z x c v b n m , . / @';

    let all = [row1, row2, row3, row4];

    let final = [];
    let Lfinal = [];
    let i = 0;
    all.map(unit => {
        let arrayRow = _.split(unit, " ");
        arrayRow = _.shuffle(arrayRow);
        let capsLockedRandom = convertToShiftValue(arrayRow);

        let random = arrayRow.join(" ");
        let lRandom = capsLockedRandom.join(" ");

        if (i === 0) {
            random = random + " {bksp}";
            lRandom = lRandom + " {bksp}";
        }
        if (i === 2) {
            random = "{lock} " + random;
            lRandom = "{lock} " + lRandom;
        }

        final.push(random);
        Lfinal.push(lRandom);
        i++;
    });

    return {
        'default': final,
        'shift': Lfinal
    };
}

function convertToShiftValue(list) {
    const dictionery = {
        "1": "!",
        "2": "@",
        "3": "#",
        "4": "$",
        "5": "%",
        "6": "^",
        "7": "&",
        "8": "*",
        "9": "(",
        "0": ")",
        "-": "_",
        "=": "+",
        "q": "Q",
        "w": "W",
        "e": "E",
        "r": "R",
        "t": "T",
        "y": "Y",
        "u": "U",
        "i": "I",
        "o": "O",
        "p": "P",
        "[": "{",
        "]": "}",
        "a": "A",
        "s": "S",
        "d": "D",
        "f": "F",
        "g": "G",
        "h": "H",
        "j": "J",
        "k": "K",
        "l": "L",
        ";": ":",
        "'": "\"",
        "z": "Z",
        "x": "X",
        "c": "C",
        "v": "V",
        "b": "B",
        "n": "N",
        "m": "M",
        ",": "<",
        ".": ">",
        "/": "?",
        "@": "@"
    }
    let row = [];
    for (const item of list) {
        row.push(dictionery[item]);
    }
    return row;

}

