import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {convertCurrencyTpye} from "../../../../services/fund";

class FundListUnit extends Component {
    render() {
        const {info, slide, assets} = this.props;
        return (
            <div className={slide ? "px-1 " : "col-md-3 px-2"}>
                <div className="card active">
                    <div className="row no-gutters">
                        <div className="col-md-8 col-8 border-left">
                            <div className="card-body">
                                <Link className="pb-2" to="/dashboard">
                                    <h5 className="card-title m-0">
                                        صندوق
                                        {info.abbreviation}
                                    </h5>
                                </Link>
                                <ul className="list-group list-group-horizontal p-0">
                                    <li className="list-group-item buy w-50">{convertCurrencyTpye(info.navPurchase)}</li>
                                    <li className="list-group-item sell w-50">{convertCurrencyTpye(info.navSale)}</li>
                                </ul>
                                <ul className="list-group list-group-horizontal p-0 info">
                                    <li className="list-group-item title w-50">واحد های شما</li>
                                    <li className="list-group-item title w-50">{assets ? convertCurrencyTpye(assets.quantity) : "0"}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-4">
                            <Link to="/dashboard">
                                <img src="img/admin/logo-admin.svg" className="card-img" alt="..."/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FundListUnit;