import React, {Component} from 'react';
import {connect, setStore} from 'trim-redux';
import {NavLink} from "react-router-dom";

class AdminLeftSideBar extends Component {
    constructor(props) {
        super(props);
    }

    handleCloseClick=()=>{
      setStore({showLeftMenu:false});
    };

    render() {
        const {showLeftMenu} = this.props;
        return (
            <nav
                className={showLeftMenu ? "rtl bg-light border-left left-sidebar show" : "rtl bg-light border-left left-sidebar"}>
                <div className="left-sidebar-sticky px-1">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/report/orders" className="nav-link" onClick={this.handleCloseClick.bind(this)}>
                                <img src="/img/admin/icon/report/order-list-report.svg" width="35" height="auto"
                                     alt=""/>
                                <span className="sr-only">(current)</span>
                                <span className="title">سفارش ها</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/report/certs" className="nav-link" onClick={this.handleCloseClick.bind(this)}>
                                <img src="/img/admin/icon/report/cert-report.svg" width="35" height="auto" alt=""/>
                                <span className="title">گواهی ها</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/report/logs" className="nav-link" onClick={this.handleCloseClick.bind(this)}>
                                <img src="/img/admin/icon/report/login-report.svg" width="35" height="auto" alt=""/>
                                <span className="title">سوابق ورود</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/report/transactions" className="nav-link" onClick={this.handleCloseClick.bind(this)}>
                                <img src="/img/admin/icon/report/transaction.svg" width="35" height="auto" alt=""/>
                                <span className="title">گردش حساب</span>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </nav>
        );
    }
}

const mstp = state => ({
    showLeftMenu: state.showLeftMenu
});

export default connect(mstp)(AdminLeftSideBar);