import React, {Component} from 'react';
import Home from './components/page/home';
import {Switch} from "react-router-dom";
import Route from "react-router-dom/es/Route";

import PrivateRoute from "./components/main/privateRoute";
import {ToastContainer} from "react-toastify";
import RegisterStep1 from "./components/page/login/register1";
import RegisterStep2 from "./components/page/login/register2";
import RegisterStep3 from "./components/page/login/register3";
import ForgetPassword from "./components/page/login/forgetPass";
import Uselect from "./components/page/login/uselect";
import LoginForm from "./components/page/login/loginForm";
import AdminIndex from "./components/page/admin/index";
import Message from "./components/page/admin/message";
import Report from "./components/page/admin/report";



class App extends Component {



    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/forgetPassword" component={ForgetPassword}/>
                    <Route path="/login" component={LoginForm}/>
                    <Route path="/register" component={RegisterStep1}/>
                    <Route path="/confirm" component={RegisterStep2}/>
                    <Route path="/verification" component={RegisterStep3}/>
                    <Route path="/usel" component={Uselect}/>
                    <PrivateRoute path="/report/:type" component={Report}/>
                    <PrivateRoute path="/dashboard" component={AdminIndex}/>
                    <PrivateRoute path="/message" component={Message}/>
                </Switch>
                <ToastContainer rtl className="rtl toast-style"/>
            </div>
        );
    }
}

export default App;
