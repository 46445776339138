import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FundListUnit from "./subcomponent/fundListUnit";
import InlineSVG from 'svg-inline-react';

class FundListSlide extends Component {

    targetComponent(fundList, customerAssetInfo) {
        if (fundList.length <= 4) {
            return (
                <section className="mt-2 fund-list-slide rtl">
                    <div className="container-fluid">
                        <div className="row">
                            {
                                fundList.map(fund => {
                                    let targetFundCustomerAssets = null;
                                    if (customerAssetInfo.count > 0) {
                                        targetFundCustomerAssets = customerAssetInfo.fundLicenseAsset.filter(row => row.fundRegistrationId === fund.registerNumber);
                                        targetFundCustomerAssets=targetFundCustomerAssets[0]
                                    }
                                    return <FundListUnit key={fund.id} slide={false} info={fund}
                                                         assets={targetFundCustomerAssets}/>
                                })
                            }
                        </div>
                    </div>
                </section>
            );
        }
        else {
            const settings = {
                dots: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                rtl: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            };
            return (
                <section className="mt-2 fund-list-slide rtl">
                    <div className="container-fluid">
                        <div className="row">
                            <Slider {...settings}>
                                {
                                    fundList.map(fund => (<FundListUnit key={fund.id} slide={true} info={fund}/>))
                                }
                            </Slider>
                        </div>
                    </div>

                </section>
            )
        }
    }

    render() {
        const {fundList, inProcess, inProcessCustomerAssets, customerAssetInfo} = this.props;
        const svgSource = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="50px" height="50px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B69F33;}
</style>
<g>
	<g>
	<polygon class="tp-1 sp-1 st0" points="47.5,32 35.4,25.1 35.4,39 47.5,46 		"/>
	<polygon class="tp-2 sp-1 st0" points="64.5,39.1 64.5,25 52.5,32 52.4,46 		"/>
	<polygon class="tp-3 st0 sp-2" points="35.4,5.4 35.4,19.4 47.6,26.5 47.6,12.5 		"/>
	<polygon class="tp-4 st0 sp-2" points="64.5,5.4 52.3,12.5 52.3,26.6 64.5,19.5 		"/>
	<polygon class="tp-5 sp-1 st0" points="66.9,43.2 54.7,50.2 66.9,57.2 79,50.2 		"/>
	<polygon class="tp-6 st0 sp-2" points="81.4,15.2 69.2,22.2 69.2,36.4 81.4,29.4 		"/>
	<polygon class="tp-7 st0 sp-2" points="71.6,40.4 83.8,47.6 96,40.5 83.8,33.4 		"/>
	<polygon class="tp-8 sp-1 st0" points="64.5,75.3 64.5,61.4 52.4,54.4 52.4,68.3 		"/>
	<polygon class="tp-9 st0 sp-2" points="71.5,60 83.7,67.1 96,60.1 83.8,53 		"/>
	<polygon class="tp-10 st0 sp-2" points="69.2,78.3 81.3,85.3 81.3,71.1 69.2,64.1 		"/>
	<polygon class="tp-11 sp-1 st0" points="35.4,75.4 47.5,68.4 47.5,54.3 35.4,61.3 		"/>
	<polygon class="tp-12 st0 sp-2" points="52.3,73.8 52.3,87.9 64.5,94.9 64.5,81 		"/>
	<polygon class="tp-13 st0 sp-2" points="35.4,94.9 47.6,87.9 47.6,73.8 35.4,80.8 		"/>
	<polygon class="tp-14 sp-1 st0" points="33,43.2 20.8,50.1 33,57.2 45.1,50.2 		"/>
	<polygon class="tp-15 st0 sp-2" points="18.5,85.2 30.7,78.1 30.7,64 18.5,71 		"/>
	<polygon class="tp-16 st0 sp-2" points="19.4,54.7 16.1,52.8 3.9,59.9 16.1,67 28.4,59.9 		"/>
	<polygon class="tp-17 st0 sp-2" points="19.4,45.5 28.4,40.4 16.2,33.3 3.9,40.3 16.1,47.4 		"/>
	<polygon class="tp-18 st0 sp-2" points="30.6,22.1 18.5,15.1 18.5,29.2 30.6,36.3 		"/>
		
	</g>
</g>
</svg>`;
        if (inProcess || inProcessCustomerAssets) {
            return (
                <section className="mt-2 fund-list-slide rtl">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 p-4 text-center">
                                <InlineSVG src={svgSource}/>
                            </div>
                        </div>
                    </div>

                </section>
            )
        } else {
            return (
                this.targetComponent(fundList, customerAssetInfo)
            )
        }
    }
}

export default FundListSlide;