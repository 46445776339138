import http from './apiServices';
import {makeAuthHeader} from './auth';
import _config from '../assets/helper/apiHelper.json';
import _default from '../assets/helper/default';
import * as _ from "lodash";
import moment from "moment-jalaali";
import jsPDF from 'jspdf';


//import plugin level
import fileDownload from 'file-saver';


export function downloadFile(data, prifix = "", ext = "xlsx") {
    fileDownload(data, prifix + 'report.' + ext);
}