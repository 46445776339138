import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import InlineSVG from "./addBankAccountModal";
import {addAddress, checkValidation, editAddress, prepareSelectData} from "../../../../services/dashboard";
import _error from "../../../../assets/helper/errorMsgHelper";
import {connect, setStore} from 'trim-redux';
import Select from "react-virtualized-select";
import {toast} from "react-toastify";
import _success from "../../../../assets/helper/successMsgHelper";
import ModalLoading from "./../../../main/modalLoading";

class AddAddressModal extends Component {
    state = {
        fields: {
            address: '',
            addressType: '',
            postCode: ''
        },
        reqiredField: {
            address: {},
            addressType: {numeric: true}
        },
        errors: {},
        listBasicInfo: {},
        inProcess: true,
        editFlag: false,
        editId: ''
    };

    componentDidMount() {
        this.setState({
            listBasicInfo: this.props.listBasicInfo,
            inProcess: false
        })
    }

    handleChange(event) {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    }

    handleChangeSelect = (info, name) => {
        let val = null;
        let fields = this.state.fields;
        if (info !== null) {
            val = info.value
        }
        fields[name] = val;
        this.setState({fields});
    };

    handleClose() {
        setStore({addAddressModal: false});
        this.resetChangePasswordForm();
        this.props.editFunction();
    }

    checkValidation(callback) {
        let {fields, reqiredField} = this.state;
        let errors = {};
        let formIsValid = true;

        for (let fieldIndex in reqiredField) {
            const validUnit = checkValidation(fields[fieldIndex] === null ? '' : fields[fieldIndex], reqiredField[fieldIndex]);
            if (!validUnit) {
                errors[fieldIndex] = _error.isRequire;
            }
            formIsValid = formIsValid && validUnit;
        }
        this.setState({errors}, () => {
            return callback(formIsValid);
        });

    }

    resetChangePasswordForm = () => {
        this.setState({
            fields: {
                address: '',
                addressType: '',
                postCode: ''
            },
            errors: {},
            editFlag: false,
            editId: ''
        });
    };

    handleAddaddress(e) {
        e.preventDefault();
        const {fields, editFlag, editId} = this.state;
        if (editFlag) {
            this.checkValidation(sts => {
                if (sts) {
                    editAddress(fields, editId)
                        .then(res => {
                            if (res.data.isSuccess) {
                                this.props.updateAddressList(res.data.personalityLocationAddress, 'update');
                                toast.success(_success.updatedNewAddress);
                                this.handleClose();
                            } else {
                                let msg = res.data.exceptions[0];
                                msg = msg.exception.persianDescription;
                                toast.error(msg);
                            }
                        })
                        .catch(err => {
                            toast.error(_error.SystemError);
                        })
                }
            });
        } else {
            this.checkValidation(sts => {
                if (sts) {
                    addAddress(fields)
                        .then(res => {
                            if (res.data.isSuccess) {
                                this.props.updateAddressList(res.data.personalityLocationAddress, 'add');
                                toast.success(_success.addedNewAddress);
                                this.handleClose();
                            } else {
                                let msg = res.data.exceptions[0];
                                msg = msg.exception.persianDescription;
                                toast.error(msg);
                            }
                        })
                        .catch(err => {
                            toast.error(_error.SystemError);
                        })
                }
            });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.edit && nextProps.editType === 'address') {
            const info = nextProps.editInfo;
            this.setState({
                fields: {
                    address: info.value,
                    addressType: info.locationAddressTypeId,
                    postCode: info.postalCode === null ? "" : info.postalCode
                },
                editFlag: true,
                editId: info.id
            })
        }

    }

    render() {
        const {inProcess, editFlag} = this.state;
        if (inProcess) {
            const svgSource = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                            width="50px" height="50px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B69F33;}
</style>
                                    <g>
	<g>
	<polygon class="tp-1 sp-1 st0" points="47.5,32 35.4,25.1 35.4,39 47.5,46 		"/>
        <polygon class="tp-2 sp-1 st0" points="64.5,39.1 64.5,25 52.5,32 52.4,46 		"/>
        <polygon class="tp-3 st0 sp-2" points="35.4,5.4 35.4,19.4 47.6,26.5 47.6,12.5 		"/>
        <polygon class="tp-4 st0 sp-2" points="64.5,5.4 52.3,12.5 52.3,26.6 64.5,19.5 		"/>
        <polygon class="tp-5 sp-1 st0" points="66.9,43.2 54.7,50.2 66.9,57.2 79,50.2 		"/>
        <polygon class="tp-6 st0 sp-2" points="81.4,15.2 69.2,22.2 69.2,36.4 81.4,29.4 		"/>
        <polygon class="tp-7 st0 sp-2" points="71.6,40.4 83.8,47.6 96,40.5 83.8,33.4 		"/>
        <polygon class="tp-8 sp-1 st0" points="64.5,75.3 64.5,61.4 52.4,54.4 52.4,68.3 		"/>
        <polygon class="tp-9 st0 sp-2" points="71.5,60 83.7,67.1 96,60.1 83.8,53 		"/>
        <polygon class="tp-10 st0 sp-2" points="69.2,78.3 81.3,85.3 81.3,71.1 69.2,64.1 		"/>
        <polygon class="tp-11 sp-1 st0" points="35.4,75.4 47.5,68.4 47.5,54.3 35.4,61.3 		"/>
        <polygon class="tp-12 st0 sp-2" points="52.3,73.8 52.3,87.9 64.5,94.9 64.5,81 		"/>
        <polygon class="tp-13 st0 sp-2" points="35.4,94.9 47.6,87.9 47.6,73.8 35.4,80.8 		"/>
        <polygon class="tp-14 sp-1 st0" points="33,43.2 20.8,50.1 33,57.2 45.1,50.2 		"/>
        <polygon class="tp-15 st0 sp-2" points="18.5,85.2 30.7,78.1 30.7,64 18.5,71 		"/>
        <polygon class="tp-16 st0 sp-2" points="19.4,54.7 16.1,52.8 3.9,59.9 16.1,67 28.4,59.9 		"/>
        <polygon class="tp-17 st0 sp-2" points="19.4,45.5 28.4,40.4 16.2,33.3 3.9,40.3 16.1,47.4 		"/>
        <polygon class="tp-18 st0 sp-2" points="30.6,22.1 18.5,15.1 18.5,29.2 30.6,36.3 		"/>
		
	</g>
</g>
</svg>`;
            return (
                <Modal className="rtl modal-style" show={this.props.addAddressModal}
                       backdropClassName="multi-level-modal"
                       onHide={this.handleClose.bind(this)} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{editFlag ? 'ویرایش آدرس' : 'افزودن آدرس'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="col-md-12 profile-body border p-3 text-center">
                                <InlineSVG src={svgSource}/>
                                <p className="text-center mt-2">لطفا کمی صبر کنید...</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        } else {
            const {locationAddressTypes} = this.state.listBasicInfo;
            const AddressOptionList = prepareSelectData('bankName', locationAddressTypes);
            const {address, addressType, postCode} = this.state.fields;
            const {errors} = this.state;
            return (
                <Modal className="rtl modal-style" show={this.props.addAddressModal}
                       backdropClassName="multi-level-modal"
                       onHide={this.handleClose.bind(this)} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{editFlag ? 'ویرایش آدرس' : 'افزودن آدرس'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2 w-100"/>
                                <div className="col-md-8">
                                    <form className="modal-form" onSubmit={this.handleAddaddress.bind(this)}>
                                        <div className="form-group">
                                            <label htmlFor="address">*آدرس</label>
                                            <textarea name="address"
                                                      className={["form-control ", errors['address'] ? 'is-invalid' : ''].join('')}
                                                      onChange={this.handleChange.bind(this)}
                                                      value={address}
                                                      id="address"/>
                                            <small className="form-text error">
                                                {errors['address'] ? errors['address'] : ''}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="addressType">*نوع آدرس</label>
                                            <Select
                                                className={["select-search ", errors['addressType'] ? 'is-invalid' : ''].join('')}
                                                value={addressType}
                                                onChange={(info) => this.handleChangeSelect(info, 'addressType')}
                                                options={AddressOptionList}
                                                name="addressType"
                                                isSearchable
                                                placeholder="نوع آدرس را انتخاب کنید"
                                            />
                                            <small className="form-text error">
                                                {errors['addressType'] ? errors['addressType'] : ''}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="postCode">کدپستی</label>
                                            <input name="postCode" type="text"
                                                   className={["form-control ", errors['postCode'] ? 'is-invalid' : ''].join('')}
                                                   onChange={this.handleChange.bind(this)}
                                                   value={postCode}
                                                   id="postCode"/>
                                            <small className="form-text error">
                                                {errors['postCode'] ? errors['postCode'] : ''}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-lg w-100">ثبت</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-2 w-100"></div>
                            </div>
                        </div>
                        <ModalLoading show={inProcess}/>
                    </Modal.Body>
                </Modal>
            );
        }
    }
}

const mstp = state => ({
    addAddressModal: state.addAddressModal
});

export default connect(mstp)(AddAddressModal);