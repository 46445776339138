import axios from 'axios';
import {toast} from 'react-toastify';
import _default from '../assets/helper/default';
import _errorDesc from '../assets/helper/errorMsgHelper.json'

axios.interceptors.request.use(function (config) {
        config.timeout = _default.apiTimeOut;
        if (config.headers.needAuth) {
            let localUserInfo = localStorage.getItem(_default.tokenName);
            localUserInfo = JSON.parse(localUserInfo);
            const token = localUserInfo[_default.authTokenName];
            delete config.headers.needAuth;
            config.headers.Authorization = "Bearer " + token.access_token;
        }
        if (config.url.toLocaleUpperCase().includes("LOGINPERSONALITYUSER")) {
            config.url = _default.api_login_base_url + config.url;
        } else {
            config.url = _default.api_base_url + config.url;
        }
        return config;
    },
    function (error) {
        toast.error(_errorDesc.SystemError);
        return Promise.reject(error);
    });

axios.interceptors.response.use(null, error => {
    const expectedError = error.response && error.response.status === 200 && error.response.status === 400;
    if (!expectedError) {
        if (error.response.status === 400) {
            toast.error(_errorDesc.unAuthError);
        } else if (error.response.status === 401) {
            // clear localStorage
            localStorage.removeItem(_default.tokenName);
            window.location.reload(true);
        } else {
            toast.error(_errorDesc.requestError);
        }
    }

    return Promise.reject(error);
});


export default {
    get: axios.get,
    post: axios.post
};


