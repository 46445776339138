import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";
import React, {Component} from 'react';
import Select from "react-virtualized-select";
import moment from 'moment-jalaali';

import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';

import {
    checkValidation, persianDigit,
    prepareSelectData,
    setInfoInUserProfile,
    updatePersonInformation,
    updateTokenInfo
} from "../../../../services/dashboard";
import _error from "../../../../assets/helper/errorMsgHelper";
import {toast} from "react-toastify";
import _success from "../../../../assets/helper/successMsgHelper";
import _default from "../../../../assets/helper/default.json";
import ModalLoading from "../../../main/modalLoading";

moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: false
});

class Tab1UserProfileModal extends Component {

    state = {
        fields: {
            firstName: '',
            lastName: '',
            birthDate: '',
            fatherName: '',
            genderTypeId: '',
            identityNo: '',
            identitySerial: '',
            married: '',
            nationalityId: '',
            passportExpirationDate: null,
            passportNo: '',
            citizenUniqueCode: '',
            placeOfBirthId: '',
            placeOfIssueId: ''
        },
        reqiredField: {
            firstName: {min: 3},
            lastName: {min: 3},
            birthDate: {},
            fatherName: {min: 3},
            identityNo: {},
            placeOfBirthId: {numeric: true},
            placeOfIssueId: {numeric: true}
        },
        errors: {},
        inProcessUpdatePerson: false,
        _moment: moment(),
        _momentPassport: moment(),
        isNull: false,
        isNullPassport: true,
        foreignFlag: false
    };

    componentDidMount() {
        const {
            firstName, lastName, birthDate,
            fatherName, genderTypeId, identityNo,
            identitySerial, married, nationalityId,
            passportExpirationDate, passportNo,
            citizenUniqueCode, placeOfBirthId, placeOfIssueId
        } = this.props.personalInfo;

        let nullFlag = false;
        let formatedBirthDate = '';
        let _mmt = "";
        if (birthDate === null) {
            formatedBirthDate = "";
            nullFlag = true;
            _mmt = moment();
        } else {
            formatedBirthDate = moment(birthDate).format();
            nullFlag = false;
            _mmt = moment(birthDate);
        }

        this.setState(prevState => (
            {
                fields: {
                    ...prevState.fields,
                    firstName, lastName, birthDate: formatedBirthDate,
                    fatherName, genderTypeId, identityNo,
                    identitySerial, married, nationalityId,
                    passportExpirationDate, passportNo,
                    citizenUniqueCode, placeOfBirthId, placeOfIssueId
                },
                _moment: _mmt,
                isNull: nullFlag
            }
        ))
    };

    handleChange = (rawMoment) => {
        console.log(rawMoment);
        const newMoment = moment(rawMoment).format();
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                birthDate: newMoment
            },
            _moment: rawMoment,
            isNull: false
        }));
    };

    handleChangePassport = (rawMoment) => {
        const newMoment = moment(rawMoment).format();
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                passportExpirationDate: newMoment
            },
            _momentPassport: rawMoment,
            isNullPassport: false
        }));
    };

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inProcessUpdatePerson: true})
        } else {
            this.setState({inProcessUpdatePerson: false})
        }
    }

    handleChangeSelect = (info, name) => {
        let val = null;
        let fields = this.state.fields;
        if (info !== null) {
            val = info.value
        }
        if (name === "nationalityId") {
            this.handleRequiredForeignInfo(val);
        }
        fields[name] = val;
        this.setState({fields});
    };

    handleChangeInput = (event) => {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    };

    checkValidation(callback) {
        let {fields, reqiredField} = this.state;
        let errors = {};
        let formIsValid = true;

        for (let fieldIndex in reqiredField) {
            const validUnit = checkValidation(fields[fieldIndex] === null ? '' : fields[fieldIndex], reqiredField[fieldIndex]);
            if (!validUnit) {
                errors[fieldIndex] = _error.isRequire;
            }
            formIsValid = formIsValid && validUnit;
        }
        this.setState({errors}, () => {
            return callback(formIsValid);
        });

    }

    handleSubmitForm(e) {
        e.preventDefault();
        let {fields} = this.state;
        this.handleLoading();
        this.checkValidation(sts => {
            if (sts) {
                updatePersonInformation(fields)
                    .then(res => {
                        if (res.data.isSuccess) {
                            updateTokenInfo(res.data.userInfo, "userTitle");
                            this.props.updateInfo('personInfo', res.data.userInfo.personInfo, 'append');
                            toast.success(_success.updatePersonInfo);
                            this.handleLoading(false);
                        } else {
                            let msg = res.data.exceptions[0];
                            msg = msg.exception.persianDescription;
                            toast.error(msg);
                            this.handleLoading(false);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        toast.error(_error.SystemError);
                        this.handleLoading(false);
                    })
            } else {
                this.handleLoading(false);
            }
        });
    }

    handleRequiredForeignInfo = (natinaletyId) => {
        let isIranian = natinaletyId === _default.iranNationalityId;
        // this.setState({foreignFlag: !isIranian});
        if (isIranian) {
            let reqiredField = {
                firstName: {min: 3},
                lastName: {min: 3},
                birthDate: {},
                fatherName: {min: 3},
                identityNo: {},
                placeOfBirthId: {numeric: true},
                placeOfIssueId: {numeric: true}
            };
            this.setState({reqiredField, foreignFlag: false});
        } else {
            let reqiredField = {
                firstName: {min: 3},
                lastName: {min: 3},
                birthDate: {},
                fatherName: {min: 3},
                identityNo: {},
                placeOfBirthId: {numeric: true},
                placeOfIssueId: {numeric: true},
                passportExpirationDate: {},
                passportNo: {},
                citizenUniqueCode: {},
            };
            this.setState({reqiredField, foreignFlag: true});
        }
    };

    render() {
        const {
            firstName, lastName, birthDate, fatherName, identityNo, genderTypeId, identitySerial, married,
            nationalityId, passportExpirationDate, passportNo, citizenUniqueCode, placeOfBirthId, placeOfIssueId
        } = this.state.fields;
        const {errors,inProcessUpdatePerson} = this.state;
        const {cities, nationalities, genders} = this.props.listBasicInfo;
        const cityOptionList = prepareSelectData('city', cities);
        const nationalOptionList = prepareSelectData('national', nationalities);
        const gendersOptionList = prepareSelectData('gender', genders);
        const marriedOptionList = prepareSelectData('married', {});
        const {_moment, isNull, isNullPassport, _momentPassport} = this.state;
        const value = !isNull && _moment ? persianDigit(_moment.format("jYYYY/jMM/jDD")) : "";
        const passportValue = !isNullPassport && _momentPassport ? persianDigit(_momentPassport.format("jYYYY/jMM/jDD")) : "";
        return (
            <>
                <ModalLoading show={inProcessUpdatePerson}/>
                <form className="w-100" onSubmit={this.handleSubmitForm.bind(this)}>
                    <div className="col-md-12 profile-body border">
                        <div className="px-md-4 px-2">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="firstName">*نام</label>
                                    <input type="text" name="firstName"
                                           className={["form-control ", errors['firstName'] ? 'is-invalid' : ''].join('')}
                                           onChange={this.handleChangeInput.bind(this)}
                                           value={firstName === null ? '' : firstName}
                                           id="firstName"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="lastName">*نام خانوادگی</label>
                                    <input type="text" name="lastName" id="lastName"
                                           className={["form-control ", errors['lastName'] ? 'is-invalid' : ''].join('')}
                                           onChange={this.handleChangeInput.bind(this)}
                                           value={lastName === null ? '' : lastName}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="fatherName">*نام پدر</label>
                                    <input type="text" name="fatherName" id="fatherName"
                                           className={["form-control ", errors['fatherName'] ? 'is-invalid' : ''].join('')}
                                           onChange={this.handleChangeInput.bind(this)}
                                           value={fatherName === null ? '' : fatherName}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="genderTypeId">جنسیت</label>
                                    <Select
                                        id="genderTypeId"
                                        className="select-search"
                                        value={genderTypeId}
                                        maxHeight={30}
                                        onChange={(info) => this.handleChangeSelect(info, 'genderTypeId')}
                                        options={gendersOptionList}
                                        name="genderTypeId"
                                        placeholder="انتخاب جنسیت"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="identityNo">*شماره شناسنامه</label>
                                    <input type="text" id="identityNo" name="identityNo"
                                           className={["form-control ", errors['identityNo'] ? 'is-invalid' : ''].join('')}
                                           onChange={this.handleChangeInput.bind(this)}
                                           value={identityNo === null ? '' : identityNo}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="identitySerial">شماره سریال شناسنامه(بخش عددی)</label>
                                    <input type="text" className="form-control" id="identitySerial"
                                           name="identitySerial"
                                           onChange={this.handleChangeInput.bind(this)}
                                           value={identitySerial === null ? '' : identitySerial}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="birthDate">*تاریخ تولد</label>
                                    <DatetimePickerTrigger
                                        moment={_moment}
                                        onChange={this.handleChange}
                                        isSolar={true}
                                        lang="fa"
                                        position="top"
                                        closeOnSelectDay={true}
                                        showTimePicker={false}
                                    >
                                        <input
                                            id="birthDate"
                                            className={["form-control birthDateInput", errors['birthDate'] ? 'is-invalid' : ''].join(' ')}
                                            type="text"
                                            name="birthDate"
                                            value={value} readOnly/>
                                    </DatetimePickerTrigger>

                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="bornCity">*محل تولد</label>
                                    <Select
                                        className={["select-search ", errors['placeOfBirthId'] ? 'is-invalid' : ''].join(' ')}
                                        value={placeOfBirthId}
                                        onChange={(info) => this.handleChangeSelect(info, 'placeOfBirthId')}
                                        options={cityOptionList}
                                        name="placeOfBirthId"
                                        isSearchable
                                        placeholder="نام شهر خود را جستجو کنید"
                                    />

                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="placeOfIssueId">*محل صدور شناسنامه</label>
                                    <Select
                                        id="placeOfIssueId"
                                        className={["select-search ", errors['placeOfIssueId'] ? 'is-invalid' : ''].join('')}
                                        value={placeOfIssueId}
                                        onChange={(info) => this.handleChangeSelect(info, 'placeOfIssueId')}
                                        options={cityOptionList}
                                        name="placeOfIssueId"
                                        isSearchable
                                        placeholder="نام شهر خود را جستجو کنید"
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="married">وضعیت تاهل</label>
                                    <Select
                                        id="married"
                                        className="select-search"
                                        value={married}
                                        onChange={(info) => this.handleChangeSelect(info, 'married')}
                                        options={marriedOptionList}
                                        name="married"
                                        isSearchable={false}
                                        placeholder="وضعیت تاهل خود را انتخاب کنید"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="nationalityId">ملیت</label>
                                    <Select
                                        id="nationalityId"
                                        className="select-search"
                                        value={nationalityId}
                                        onChange={(info) => this.handleChangeSelect(info, 'nationalityId')}
                                        options={nationalOptionList}
                                        name="nationalityId"

                                        isSearchable
                                        placeholder="نام کشور خود را جستجو کنید"
                                    />
                                </div>
                            </div>


                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="citizenUniqueCode">کد اختصاصی اتباع</label>
                                    <input type="text" id="citizenUniqueCode"
                                           className={["form-control", errors['citizenUniqueCode'] ? 'is-invalid' : ''].join(' ')}
                                           onChange={this.handleChangeInput.bind(this)}
                                           name="citizenUniqueCode"
                                           value={citizenUniqueCode === null ? '' : citizenUniqueCode}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="passportNo">شماره پاسپورت</label>
                                    <input type="text" id="passportNo"
                                           className={["form-control", errors['passportNo'] ? 'is-invalid' : ''].join(' ')}
                                           onChange={this.handleChangeInput.bind(this)}
                                           name="passportNo"
                                           value={passportNo === null ? '' : passportNo}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="passportExpirationDate">تاریخ انقضای پاسپورت</label>
                                    <DatetimePickerTrigger
                                        moment={_momentPassport}
                                        onChange={this.handleChangePassport}
                                        isSolar={true}
                                        lang="fa"
                                        position="top"
                                        closeOnSelectDay={true}
                                        showTimePicker={false}>
                                        <input
                                            id="passportExpirationDate"
                                            className={["form-control birthDateInput", errors['passportExpirationDate'] ? 'is-invalid' : ''].join(' ')}
                                            type="text"
                                            name="passportExpirationDate"
                                            value={passportValue} readOnly/>
                                    </DatetimePickerTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-right my-2">
                    <span className="small" style={{float: "right"}}>
                        {_error.isRequireMsg}
                    </span>
                        <button type="submit"
                                disabled={this.state.inProcessUpdatePerson}
                                className="btn btn-lg btn-primary px-5">
                            ثبت
                        </button>
                    </div>
                </form>
            </>
        );
    }

}


export default Tab1UserProfileModal;