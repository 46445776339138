import React, {Component} from 'react';
import Dashboard from "./dashboard";
import './../../../assets/admin/css/style.css';
import './../../../assets/admin/css/style2.css';
import './../../../assets/admin/css/sidebar.css';
import './../../../assets/admin/css/responsive.css';


class AdminIndex extends Component {


    render() {
        return (
            <section className="user-panel">
                <div className="container-fluid px-0">
                    <Dashboard/>
                </div>

            </section>
        );
    }
}

export default AdminIndex;