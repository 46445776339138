import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {getCaptchaUrl, verifyCaptcha, submitMobile, setBackgroundDefault} from "../../../services/login";
import {connect, setStore} from 'trim-redux';
import validator from "validator";
import _error from "../../../assets/helper/errorMsgHelper.json";
import _success from "../../../assets/helper/successMsgHelper.json";

import {toast} from "react-toastify";

class RegisterStep2 extends Component {

    constructor(props) {
        super(props);
        if (this.props.step !== 2 || this.props.NationalCode === '') {
            this.props.history.replace('/register');
        }
    }

    state = {
        fields: {
            mobile: '',
            username: '',
            captcha: '',
            captchaKey: ''
        },
        errors: {},
        captchaURL: '',
        inProcess: false
    };

    componentDidMount() {
        setBackgroundDefault("bg1");
        this.reCaptcha();
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                username: this.props.NationalCode
            }
        }));
    }

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inProcess: true})
        } else {
            this.setState({inProcess: false})
        }
    }

    reCaptcha() {
        const captchaAddress = getCaptchaUrl();
        this.setState({captchaURL: captchaAddress.address});
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                captcha: '',
                captchaKey: captchaAddress.captchaKey
            }
        }));
    };

    handleChangeCaptcha(e) {
        e.preventDefault();
        this.reCaptcha();
    }

    handleChange(event) {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    }

    checkValidation(callback) {
        let {fields} = this.state;
        let errors = {};
        let formIsValid = true;

        // username validate
        if (validator.isEmpty(fields.mobile)) {
            formIsValid = false;
            errors['mobile'] = _error.emptyMobile;
        } else if (!validator.isLength(fields.mobile, {min: 11, ma: 11})) {
            formIsValid = false;
            errors['mobile'] = _error.incorrectMobile;
        }

        // captcha validate
        if (validator.isEmpty(fields.captcha)) {
            formIsValid = false;
            errors['captcha'] = _error.emptyCaptcha;
        } else if (validator.isLength(fields.captcha, {min: 1, max: 4})) {
            formIsValid = false;
            errors['captcha'] = _error.incorrectCaptcha;
        }

        this.setState({errors}, () => {
            return callback(formIsValid)
        });

    }

    makeRequest(data) {
        verifyCaptcha(data)
            .then(captchaResponse => {
                if (captchaResponse.data.isSuccess) {
                    submitMobile(data)
                        .then(res => {
                            res = res.data;
                            if (res.isSuccess) {
                                setStore({step: 3, mobileSubmit: res});
                                let msg = _success.verifyCode.replace("%NOTIFI%", res.notifications.sms);
                                toast.success(msg);
                                this.props.history.replace('/verification');
                            } else {
                                let msg = res['exceptions'][0]['exception']['persianDescription'];
                                toast.error(msg);
                                this.props.history.replace('/register');
                            }
                        })
                        .catch(
                            err => {
                                console.log(err);
                                this.handleLoading(false);
                            }
                        )
                } else {
                    toast.error(_error.captchaError);
                    this.handleLoading(false);
                    this.reCaptcha();
                }
            })
            .catch(err => {
                console.log(err);
                this.handleLoading(false)
            });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.handleLoading();
        let {fields} = this.state;
        this.checkValidation(sts => {
            if (sts) {
                this.makeRequest(fields)
            } else {
                this.handleLoading(false);
            }
        });
    }

    render() {
        const {errors} = this.state;
        return (
            <div>
                <section className="rtl bg-theme-color-1 py-5" style={{height: 100 + "vh"}}>
                    <div className="container">
                        <div className="row">
                            <form data-aos="fade-right" onSubmit={this.handleSubmit.bind(this)}
                                  className="form-signin d-block m-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to="/">
                                            <img className="logo d-block m-auto mb-5" src="img/logo-wh.png"
                                                 alt="Etebar logo"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <img className="icon d-block m-auto mb-4 pb-4" src="img/reg-icon.svg"
                                             alt="Etebar logo"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <input type="text"
                                               className={["form-control ", errors['mobile'] ? 'is-invalid' : ''].join('')}
                                               name="mobile"
                                               value={this.state.fields.mobile}
                                               onChange={this.handleChange.bind(this)}
                                               placeholder="شماره موبایل"
                                               autoComplete="off"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <input type="text"
                                               className={["form-control captcha ", errors['captcha'] ? 'is-invalid' : ''].join('')}
                                               name="captcha"
                                               value={this.state.fields.captcha}
                                               onChange={this.handleChange.bind(this)}
                                               placeholder="کد امنیتی"
                                               autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-5 pr-0">
                                        <img className="img-fluid captcha" src={this.state.captchaURL} alt=""/>
                                    </div>
                                    <div className="col-2 p-2">
                                        <div>
                                            <img src={"img/refresh.svg"} className="refresh"
                                                 onClick={this.handleChangeCaptcha.bind(this)}
                                                 height="30" width="30" alt=""/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row pt-2" style={{minHeight: 80}}>
                                    <div className="col-12">
                                        <p className="error-area"
                                           style={{display: errors["mobile"] ? 'block' : 'none'}}>
                                            <i className="fa fa-times"></i>
                                            {errors["mobile"]}
                                        </p>
                                        <p className="error-area"
                                           style={{display: errors["captcha"] ? 'block' : 'none'}}>
                                            <i className="fa fa-times"></i>
                                            {errors["captcha"]}
                                        </p>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-2 w-100"></div>
                                    <div className="col-8">
                                        <button disabled={this.state.inProcess}
                                                className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                                            {this.state.inProcess ? 'لطفا صبر کنید...' : 'ادامه'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mstp = state => ({
    step: state.step,
    NationalCode: state.nationalCode,
    mobileSubmit: state.mobileSubmit
});

export default connect(mstp)(RegisterStep2);