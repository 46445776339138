import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import ModalLoading from "./uploadModal";
import _default from "../../../../assets/helper/default";
import {toast} from "react-toastify";
import {connect, setStore} from "trim-redux";
import Dropzone from "react-dropzone";
import {uploadFileDocument} from "../../../../services/dashboard";
import _success from "../../../../assets/helper/successMsgHelper";

class UploadFundBuyPayment extends Component {

    constructor(props) {
        super(props);
        this.valid = true;
        const acceptFile = _default.acceptFileType.split(',');
        this.onDrop = (files) => {
            const status = this.handleRejectedError(files);
            if (status) {
                files.map(file => {

                    if (acceptFile.includes(file.type)) {
                        Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        });
                    }

                });
                this.setState({files, haveContent: false});
            } else {
                this.setState({files: []});
            }
        };
        this.state = {
            files: [],
            target: {},
            inProcess: false,
            errors: {},
            content: "",
            haveContent: false,
            contentLoading: true,
            fileName: ""
        };
    }

    handleRejectedError = (files) => {
        let errors = {};
        let isValid = true;
        const acceptFile = _default.acceptFileType.split(',');
        files.map(file => {
            const sts = acceptFile.includes(file.type);
            if (!sts) {
                isValid = false;
                errors['type'] = "پسوند و فرمت فایل مجاز نیست";
                toast.error("پسوند و فرمت فایل مجاز نیست");
            }
            if (file.size > _default.maxSizeFileUpload) {
                isValid = false;
                errors['size'] = "حجم فایل نمی تواند بیشتر از یک مگابایت باشد";
                toast.error("حجم فایل نمی تواند بیشتر از یک مگابایت باشد");
            }
        });
        this.setState({errors});
        return isValid;

    };

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inProcess: true})
        } else {
            this.setState({inProcess: false})
        }
    }

    handleClose() {
        setStore({UploadFundBuyPaymentModal: false});
        this.resetUpload();
    }

    resetUpload = () => {
        this.setState({files: [], errors: {}, content: "", haveContent: false, fileName: ""});
    };

    handleSetPaymentDocument = () => {
        this.handleLoading();
        const {files} = this.state;
        const isValid = this.handleRejectedError(files);
        if (isValid) {
            // execute function move file to main modal
            this.props.setDocument(files);
            setStore({UploadFundBuyPaymentModal: false});
        } else {
            this.handleLoading(false);
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.haveContent) {
            this.setState({
                haveContent: nextProps.haveContent,
                files: nextProps.fileContent
            })
        }
    }

    render() {
        const img = _default.uploadImageStyle;
        const thumbs = this.state.files.map(file => {
            if (file.type.includes("image/")) {
                return (
                    <div key={file.name}>
                        <div className="action">
                            <img
                                src={file.preview}
                                style={img}
                            />
                            <div className="overly">
                                <p>برای تغییر،روی تصویر کلیک کنید</p>
                            </div>
                        </div>
                    </div>
                )
            } else if (file.type.includes("pdf")) {
                return (
                    <div key={file.name}>
                        <div className="action">
                            <p className="text-center">
                                {file.name}
                            </p>
                            <div className="overly">
                                <p>برای تغییر،روی تصویر کلیک کنید</p>
                            </div>
                        </div>
                    </div>
                )
            }
        });
        const {files, target, inProcess, errors, contentLoading} = this.state;
        const info = this.state.files[0];
        return (
            <Modal className="rtl modal-style" show={this.props.UploadFundBuyPaymentModal}
                   backdropClassName="multi-level-modal"
                   onHide={this.handleClose.bind(this)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>آپلود فیش واریزی</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalLoading show={inProcess || contentLoading}/>
                    <div className="text-center upload-area">
                        <p>{target.recordTypeTitle}</p>
                        <Dropzone
                            onDropAccepted={this.onDrop}
                            maxSize={_default.maxSizeFileUpload}
                            onDropRejected={(dd) => {
                                this.handleRejectedError(dd)
                            }}>
                            {({getRootProps, getInputProps}) => (
                                <section className="container">
                                    <div className="col-md-12">
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <div>
                                                {
                                                    this.state.haveContent ?
                                                        (
                                                            <div className="action">
                                                                <img
                                                                    src={info.preview}
                                                                    style={img}/>
                                                                <div className="overly">
                                                                    <p>برای تغییر،روی تصویر کلیک کنید</p>
                                                                </div>
                                                            </div>

                                                        )
                                                        :
                                                        (thumbs)
                                                }

                                            </div>
                                            <input {...getInputProps({accept: _default.acceptFileType})} />
                                            {files.length > 0 || this.state.haveContent ? "" :
                                                <p>فایل مورد نظر را برای آپلود در این کادر قرار دهید</p>}
                                        </div>
                                        {
                                            this.state.haveContent ? (<div className="">
                                                <div className="">
                                                    <div className="col-md-12 action">
                                                        <a className="download" download={info.name}
                                                           href={info.preview}>
                                                            <i className="fa fa-download px-1"/>
                                                            دانلود
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>) : null
                                        }
                                    </div>

                                    <aside>
                                        <p className={["preview", errors['type'] ? "is-invalid" : ""].join(" ")}>پسوندهای
                                            مجاز:
                                            <span>jpeg,jpg,png,pdf</span>
                                        </p>
                                        <p className={["preview", errors['size'] ? "is-invalid" : ""].join(" ")}
                                        >حداکثر اندازه فایل:
                                            <span>یک مگابایت</span>
                                        </p>

                                    </aside>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    <div className="text-right my-2">
                        <button type="button" className="btn btn-lg btn-primary px-5"
                                onClick={this.handleSetPaymentDocument.bind(this)}
                        >
                            ثبت
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mstp = state => ({
    UploadFundBuyPaymentModal: state.UploadFundBuyPaymentModal
});

export default connect(mstp)(UploadFundBuyPayment);