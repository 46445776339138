import React, {Component} from 'react';
import {Link} from "react-router-dom";

class TopFundBox extends Component {
    render() {
        return (
            <section className="rtl admin-theme-bg-color-1 fund-list border-bottom">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group list-group-horizontal bordr-bottom">
                                <li className="list-group-item active">
                                    <Link to="/dashboard">
                                        صندوق با درآمد ثابت
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TopFundBox;