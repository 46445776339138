import React, {Component} from 'react';
import {Redirect, Route} from "react-router-dom";
import {connect, setStore} from "trim-redux";
import _default from "../../assets/helper/default";
import {checkToken, clearUserInfoLoginAgain} from "../../services/login";
import AdminHeader from "./adminHeader";
import AdminSideBar from "./adminSideBar";
import AdminLeftSideBar from "./adminLeftSideBar";
import {checkTokenInfoType, getLogOut} from "../../services/dashboard";
import {toast} from "react-toastify";
import _success from "./../../assets/helper/successMsgHelper";


class PrivateRoute extends Component {
    handleLogout = () => {
        getLogOut()
            .then(res => {

                toast.success(_success.logout);
                setStore({auth: false});
                clearUserInfoLoginAgain();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        let currentAuth = this.props.auth;
        let apiToken = localStorage.getItem(_default.tokenName);
        const checkTokenType = checkTokenInfoType(apiToken);
        if (checkTokenType) {
            apiToken = JSON.parse(apiToken);
            if (apiToken !== null) {
                const userId = apiToken[_default.userInfoTokenName]['id'];
                if (!this.props.auth) {
                    checkToken(userId)
                        .then((res) => {
                            if (res.data.isSuccess) {
                                currentAuth = true;
                                setStore({auth: true});
                            } else {
                                // localStorage.removeItem(_default.tokenName);
                                currentAuth = false;
                            }
                        })
                        .catch(() => {
                            localStorage.removeItem(_default.tokenName);
                            currentAuth = false;
                        })
                } else {

                    currentAuth = true;
                }
            } else {
                currentAuth = false;
            }
        } else {
            currentAuth = false;
        }

        const {component: Component, ...restProps} = this.props;
        return <Route {...restProps} render={(props) =>
            (
                currentAuth ? (
                    <>
                        <AdminHeader handleLogout={this.handleLogout.bind(this)}/>
                        <AdminSideBar/>
                        <AdminLeftSideBar/>
                        <Component {...props} />
                        <footer/>
                    </>
                ) : (
                    <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                )
            )}/>
    }
}

const mstp = state => ({
    auth: state.auth
});

export default connect(mstp)(PrivateRoute);