import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {setStore, connect} from "trim-redux";
import _error from "../../../../assets/helper/errorMsgHelper";
import OnlinePaymentTabBuyFundModal from "./onlinePaymentTabBuyFundModal";
import AccountPaymentTabBuyFundModal from "./accountPaymentTabBuyFundModal";
import Loading from "./sellFundModal";

class BuyFundModal extends Component {

    state = {
        activeTab: "tab2",
    };

    targetTab() {
        switch (this.state.activeTab) {
            case("tab1"): {
                // return <OnlinePaymentTabBuyFundModal fundDetail={this.props.fundDetail}/>
            }
            case("tab2"): {

                return <AccountPaymentTabBuyFundModal fundDetail={this.props.fundDetail}
                                                      fundBankAccountList={this.props.fundBankAccountList}
                                                      inProcess={this.props.inProcess}
                />
            }
            default: {
                return <AccountPaymentTabBuyFundModal fundDetail={this.props.fundDetail}
                                                      fundBankAccountList={this.props.fundBankAccountList}
                                                      inProcess={this.props.inProcess}
                />
            }
        }
    }

    changeTab(index) {
        this.setState({activeTab: index});
    }

    resetForm() {
        this.setState({});
    }

    handleClose() {
        setStore({buyFundModal: false});
        this.resetForm();
    }


    render() {
        const {fundDetail} = this.props;
        const fundInfo = fundDetail.fund;
        return (
            <Modal className="rtl modal-style buy-fund" show={this.props.buyFundModal}
                   backdropClassName="multi-level-modal"
                   onHide={this.handleClose.bind(this)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>درخواست صدور</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-8">
                                <div className="text-dark pt-md-3 pl-md-3">
                                    <h5 className="title"> صندوق سرمایه گذاری {fundInfo.abbreviation}</h5>
                                    <p className="subtitle">
                                        <small className="bold">
                                            {fundInfo.fundTypeTitle}
                                        </small>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-4">
                                <img src="img/admin/logo-admin.svg" className="logo"
                                     alt="..."/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{display:"none"}}>
                                <p className={["pay-type ", this.state.activeTab === "tab1" ? "active" : ""].join(' ')}
                                   onClick={() => {
                                       this.changeTab("tab1")
                                   }}>
                                    <i className="fa fa-circle"/>
                                    پرداخت آنلاین
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p className={["pay-type ", this.state.activeTab === "tab2" ? "active" : ""].join(' ')}
                                   onClick={() => {
                                       this.changeTab("tab2")
                                   }}>
                                    <i className="fa fa-circle"/>
                                    پرداخت نقدی
                                </p>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    this.targetTab()
                                }

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }


}

const mstp = state => ({
    buyFundModal: state.buyFundModal
});

export default connect(mstp)(BuyFundModal);