import React, {Component} from 'react';
import _default from "../../assets/helper/default.json";

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="modal-tbl centered overflow-auto" style={{height: this.props.tblHeight,minWidth:this.props.minWidth}}>
                {
                    this.props.topHead ?
                        <div className="tbl-head back-sky padding4 b-b-sea">{this.props.topHead}</div> : null
                }
                <div className="tbl-head flexed b-b-sea back-silver" style={{top: this.props.top}}>

                    {
                        this.props.head.map((headItem, index) => {
                            return (
                                <span key={index}
                                      className={`padding4 ${this.props.cols ? 'flex' + this.props.cols[index] : 'flex1'}`}>{headItem}</span>
                            )
                        })
                    }
                </div>
                <div className="tbl-body flexed fdir-col">
                    {
                        //   rows
                        this.props.body.length ? this.props.body.map((row, index) => {
                            return (
                                <div key={index} className="flex1 tbl-row flexed width100 b-b-sea">
                                    {/* columns */}
                                    {
                                        row.data.map((rowItem, inde) => {
                                            return (
                                                <React.Fragment key={inde}>
                                                    {
                                                        (rowItem != null) ? (
                                                                !rowItem.icon ?
                                                                    <span key={inde}
                                                                          className={`padding4 ${this.props.cols ? 'flex' + this.props.cols[inde] : 'flex1'}`}>
                                    {rowItem}</span> :
                                                                    <span key={inde}
                                                                          className={`padding4 flex${this.props.cols[inde]}`}>
                                    {
                                        // icons
                                        rowItem.icon.map((icon, i) => {
                                            return (
                                                <i key={i}
                                                   className={icon.class + ` ${icon.default ? 'color-green' : null} padding4 pointer`}
                                                   onClick={() => icon.func(this.props.body[index].id, icon.type)}
                                                   title={icon.title}/>
                                            )
                                        })
                                    }
                              </span>) :
                                                            <span key={inde}
                                                                  className={`padding4 ${this.props.cols ? 'flex' + this.props.cols[inde] : 'flex1'}`}>
                                {rowItem}</span>

                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }) : (
                            <span className={`padding4 flex1`}>
                                {_default.noRecord}
                            </span>
                        )
                    }
                </div>
            </div>
        )
    }
}