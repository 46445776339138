import React from "react";


class Ticket extends React.Component {
    render() {
        return (
            <div className="row m-0 width100">
                {
                    this.props.dir === "outgo" ?
                        <div className="col-md-6"/> : null
                }
                <div
                    className={`the-message ${this.props.margined ? 'margintop64' : null} ${this.props.dir} col-md-6`}>
                    {!this.props.repeated ?
                        <div
                            className={`icon flexed circle ${this.props.dir === 'income' ? 'back-sky' : 'back-silver'}`}>
                            <i className="fas fa-user"/>
                        </div> : null
                    }


                    <p className={`text ${this.props.dir === 'income' ? 'back-sky' : 'back-silver'} padding8 radius8`}>
                        <span className="mb-2 d-block text-muted bold">{this.props.dir === "income" ? "پشتیبانی" : 'کاربر'}</span>
                        {this.props.children}
                        <span className="blocked date">{this.props.date}</span>
                    </p>
                </div>

                {
                    this.props.dir === "income" ?
                        <div className="col-md-6"/> : null
                }
            </div>
        );
    }
}


export default Ticket;