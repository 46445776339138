import React, {Component} from "react";
import Chart from "react-apexcharts";
import {getEfficentChart, prepareChartData} from "../../../services/fund";
import {connect} from "trim-redux";
import {toast} from "react-toastify";
import _error from "../../../assets/helper/errorMsgHelper";
import ChartLoading from "../../main/chartLoading";

class EfficentChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    id: "basic-line",
                    background: '#f3f3f3',
                    fontFamily: 'irsul',
                    toolbar: {
                        show: false
                    },
                    height: 120
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: false,
                        style: {
                            colors: [],
                            fontSize: '9pt',
                            cssClass: 'apexcharts-xaxis-label'
                        }
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                yaxis: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#e7e7e7',
                    padding: {
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 10
                    }

                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    width: 2,
                    dashArray: 0,
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '10pt'
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    followCursor: false,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    style: {
                        fontSize: '8pt'
                    },
                    y: {
                        formatter: (seriesName) => "%" + seriesName,
                    }

                },

            },
            series: [
                {
                    name: "بازده",
                    data: []
                }
            ],
            timeFrameList: [
                {id: 1, title: "روزانه"},
                {id: 3, title: "ماهانه"},
                {id: 4, title: "سه ماهه"},
                {id: 6, title: "سالانه"},

            ],
            inProcess: true,
            timeFrame: 1
        };
    }

    componentDidMount() {
        this.handleEfficientChartData(this.state.timeFrame);
    }

    handleEfficientChartData = (timeFrame) => {
        this.setState({timeFrame, inProcess: true});
        getEfficentChart(this.props.timeInfo, timeFrame)
            .then(res => {
                if (res.data.isSuccess) {
                    const chartData = prepareChartData(res.data.fundStatisticalInfos, timeFrame);
                    this.setState(prevState => ({
                        ...prevState,
                        series: [{data: chartData.series}],
                        options: {
                            ...prevState.options,
                            xaxis: {
                                categories: chartData.cat
                            }
                        },
                        inProcess: false
                    }));
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    // toast.error(msg);
                }
            })
            .catch(() => {
                toast.error(_error.SystemError);
            });
    };

    render() {
        return (

            <div className="bg-white chart-area">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="text-right title">
                            بازده
                            <img className="icon" width="20" src="img/admin/icon/efficient.svg" alt=""/>
                        </h6>
                    </div>
                    <div className="col-md-12">
                        <div className="mx-3">
                            <Chart
                                options={this.state.options}
                                series={this.state.series}
                                className="efficient-area"
                                type="line"
                                width="100%"
                                height="140px"
                            />
                            <ul className="list-group list-group-horizontal action-area rtl">
                                {
                                    this.state.timeFrameList.map(row => {
                                        return (
                                            <li key={row.id}
                                                className={["col list-group-item", this.state.timeFrame === row.id ? "active" : ""].join(' ')}>
                                    <span onClick={() => {
                                        this.handleEfficientChartData(row.id)
                                    }}>{row.title}</span>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <ChartLoading show={this.state.inProcess}/>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

const mstp = state => ({
    timeInfo: state.timeInfo
});

export default connect(mstp)(EfficentChart);
