import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {connect, setStore} from "trim-redux";
import _error from "../../../../assets/helper/errorMsgHelper";
import {Link} from "react-router-dom";

class LogoutModal extends Component {

    render() {
        return (
            <Modal className="rtl modal-style" show={this.props.userLogOutModal} backdrop="static">
                <Modal.Header>
                    <Modal.Title>حساب کاربری</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                               <div className="mb-3">
                                   <p className="mb-1">{_error.logOutMessage}</p>
                               </div>
                                <Link className="btn btn-secondary text-center" to="/login">ورود مجدد به سیستم</Link>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mstp = state => ({
    userLogOutModal: state.userLogOutModal
});

export default connect(mstp)(LogoutModal);