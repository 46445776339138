import {createStore} from 'trim-redux'

const state = {
    nationalCode: '',
    step: 1,
    mobileSubmit: '',
    auth: true,
    showLeftMenu: false,
    loading: true,
    userProfileModal: false,
    mainUserProfile: false,
    userLogOutModal: false,
    instantPasswordChange: false,
    addBankAccountModal: false,
    addPhoneModal: false,
    addElecAddressModal: false,
    addAddressModal: false,
    fundDetailModal: false,
    buyFundModal: false,
    sellFundModal: false,
    uploadModal: false,
    tmp: [],
    orders: [],
    orderUpdatedFlag: false,
    timeInfo: "",
    fundStatus: true,
    profileInfo: {},
    userProfilePhotoModal: false,
    UploadFundBuyPaymentModal: false,
    successFundBuyReceiptModal: false,
    successFundBuyReceiptPreviewModal: false,
    newOrder: {},
    orderHistory: [],
    orderHistoryModal: false,
    orderReceiptInfo: "",
    newMessageModal: false,


};
const store = createStore(state);

export default store;