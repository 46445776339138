import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {connect, setStore} from 'trim-redux';
import _error from "../../../../assets/helper/errorMsgHelper";
import {toast} from "react-toastify";
import Tab1UserProfileModal from "./tab1userProfileModal";
import Tab2UserProfileModal from "./tab2userProfileModal";
import Tab3UserProfileModal from "./tab3userProfileModal";
import Tab4UserProfileModal from "./Tab4UserProfileModal";
import {
    deleteLocal,
    getBaseListInfo,
    getUserProfileInfo,
    personalInfoStatus,
    personalprofileStatus
} from "../../../../services/dashboard";
import InlineSVG from 'svg-inline-react';
import './../../../../assets/css/loading.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class UserProfileModal extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        tabList: [
            {id: 1, key: "PersonalInformation", title: 'پروفایل', icon: 'fa-user', active: true, notComplete: false},
            {id: 2, key: "BankAccount", title: 'حساب بانکی', icon: 'fa-credit-card', active: false, notComplete: false},
            {id: 3, key: "Phones", title: 'اطلاعات تماس', icon: 'fa-phone', active: false, notComplete: false},
            {
                id: 4,
                key: "KnowYourCustomerByFund",
                title: 'مدارک',
                icon: 'fa-id-card',
                active: false,
                notComplete: false
            }
        ],
        activeTab: 1,
        listBasicInfo: [],
        profileInfo: [],
        inProcessStaticInfo: true,
        inProcessUserInfo: true,
        informationStatus: {}
    };

    componentDidMount() {
        const informationStatus = personalInfoStatus();
        this.setState({informationStatus});
    }

    updateInfo = (state, info, type = '') => {
        this.props.updateInfo(state, info, type);
    };

    targetComponent() {
        const {inProcessStaticInfo, inProcessUserInfo} = this.props;
        if (!inProcessStaticInfo && !inProcessUserInfo) {
            const {profileInfo, listBasicInfo} = this.props;
            switch (this.state.activeTab) {
                case (1): {
                    return <Tab1UserProfileModal
                        listBasicInfo={listBasicInfo}
                        personalInfo={profileInfo['personInfo']}
                        legalInfo={profileInfo['legalInfo']}
                        updateInfo={this.updateInfo}
                    />
                }
                case(2): {
                    return <Tab2UserProfileModal
                        listBasicInfo={listBasicInfo}
                        bankAccounts={profileInfo['bankAccounts']}
                        updateInfo={this.updateInfo}

                    />
                }
                case(3): {
                    return <Tab3UserProfileModal
                        listBasicInfo={listBasicInfo}
                        electronicAddresses={profileInfo['electronicAddresses']}
                        phones={profileInfo['phones']}
                        locationAddresses={profileInfo['locationAddresses']}
                        updateInfo={this.updateInfo}
                    />
                }
                case(4): {
                    return <Tab4UserProfileModal
                        listBasicInfo={listBasicInfo}
                        recordsInfo={profileInfo['personalityRecords']}
                        updateInfo={this.updateInfo}
                    />
                }
                default:
                    return <Tab1UserProfileModal listBasicInfo={listBasicInfo}/>

            }
        }

    }

    changeTab(index) {
        this.setState({activeTab: index});
    }

    handleClose() {
        setStore({mainUserProfile: false});
        this.resetInfo();
    }

    resetInfo() {
        this.setState({
            activeTab: 1,
            listBasicInfo: [],
            profileInfo: [],
            inProcessStaticInfo: true,
            inProcessUserInfo: true,
        })
    }

    render() {
        const svgSource = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="50px" height="50px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B69F33;}
</style>
<g>
	<g>
	<polygon class="tp-1 sp-1 st0" points="47.5,32 35.4,25.1 35.4,39 47.5,46 		"/>
	<polygon class="tp-2 sp-1 st0" points="64.5,39.1 64.5,25 52.5,32 52.4,46 		"/>
	<polygon class="tp-3 st0 sp-2" points="35.4,5.4 35.4,19.4 47.6,26.5 47.6,12.5 		"/>
	<polygon class="tp-4 st0 sp-2" points="64.5,5.4 52.3,12.5 52.3,26.6 64.5,19.5 		"/>
	<polygon class="tp-5 sp-1 st0" points="66.9,43.2 54.7,50.2 66.9,57.2 79,50.2 		"/>
	<polygon class="tp-6 st0 sp-2" points="81.4,15.2 69.2,22.2 69.2,36.4 81.4,29.4 		"/>
	<polygon class="tp-7 st0 sp-2" points="71.6,40.4 83.8,47.6 96,40.5 83.8,33.4 		"/>
	<polygon class="tp-8 sp-1 st0" points="64.5,75.3 64.5,61.4 52.4,54.4 52.4,68.3 		"/>
	<polygon class="tp-9 st0 sp-2" points="71.5,60 83.7,67.1 96,60.1 83.8,53 		"/>
	<polygon class="tp-10 st0 sp-2" points="69.2,78.3 81.3,85.3 81.3,71.1 69.2,64.1 		"/>
	<polygon class="tp-11 sp-1 st0" points="35.4,75.4 47.5,68.4 47.5,54.3 35.4,61.3 		"/>
	<polygon class="tp-12 st0 sp-2" points="52.3,73.8 52.3,87.9 64.5,94.9 64.5,81 		"/>
	<polygon class="tp-13 st0 sp-2" points="35.4,94.9 47.6,87.9 47.6,73.8 35.4,80.8 		"/>
	<polygon class="tp-14 sp-1 st0" points="33,43.2 20.8,50.1 33,57.2 45.1,50.2 		"/>
	<polygon class="tp-15 st0 sp-2" points="18.5,85.2 30.7,78.1 30.7,64 18.5,71 		"/>
	<polygon class="tp-16 st0 sp-2" points="19.4,54.7 16.1,52.8 3.9,59.9 16.1,67 28.4,59.9 		"/>
	<polygon class="tp-17 st0 sp-2" points="19.4,45.5 28.4,40.4 16.2,33.3 3.9,40.3 16.1,47.4 		"/>
	<polygon class="tp-18 st0 sp-2" points="30.6,22.1 18.5,15.1 18.5,29.2 30.6,36.3 		"/>
		
	</g>
</g>
</svg>`;
        const {inProcessStaticInfo, inProcessUserInfo} = this.props;
        if (inProcessStaticInfo || inProcessUserInfo) {
            return (
                <>
                    <Modal className="rtl modal-style" show={this.props.mainUserProfile}
                           size="lg"
                           onHide={this.handleClose.bind(this)} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>پروفایل</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-0 py-2">
                            <div className="tabBackLine"/>
                            <div className="container px-md-5">
                                <div className="row px-md-5">
                                    {
                                        this.state.tabList.map(info => {
                                            return (
                                                <div className="col-md-3 col-3" key={info.id}>
                                                    <a className={["tab-list", info.id === this.state.activeTab ? ' active' : ''].join(' ')}
                                                       onClick={() => this.changeTab(info.id)}>
                                                        <div className="text-center">
                                                            <i className={["fa", info.icon].join(' ')}></i>
                                                            <p className="text-center my-1">
                                                                <small className="modal-tab-label">{info.title}</small>
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="row px-4">
                                    <div className="col-md-12 profile-body border p-3 text-center">
                                        <InlineSVG src={svgSource}/>
                                        <p className="text-center mt-2">لطفا کمی صبر کنید...</p>
                                    </div>
                                </div>
                                <div className="col-md-12 text-right my-4 p-0"/>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            );
        } else {
            return (
                <>
                    <Modal className="rtl modal-style" show={this.props.mainUserProfile}
                           size="lg"
                           onHide={this.handleClose.bind(this)} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>پروفایل</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-0 py-2">
                            <div className="tabBackLine"/>
                            <div className="container px-md-5">
                                <div className="row px-md-5">
                                    {
                                        this.state.tabList.map(info => {
                                            return (
                                                <div className="col-md-3 col-3" key={info.id}>
                                                    <a className={["tab-list",
                                                        info.id === this.state.activeTab ? ' active' : '',
                                                        this.state.informationStatus[info.key] ? "" : "not-complete"
                                                    ].join(' ')}
                                                       onClick={() => this.changeTab(info.id)}>
                                                        <div className="text-center">
                                                            <i className={["fa", info.icon].join(' ')}></i>
                                                            <p className="text-center my-1">
                                                                <small className="modal-tab-label">{info.title}</small>
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="row px-md-4 px-3">
                                    {
                                        this.targetComponent()
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            );
        }
    }
}

const mstp = state => ({
    mainUserProfile: state.mainUserProfile
});

export default connect(mstp)(UserProfileModal);