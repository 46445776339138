import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import Dropzone from "react-dropzone";
import {connect, setStore} from "trim-redux";
import {updateTokenInfo, uploadFileDocument, uploadProfilePhoto} from "../../../../services/dashboard";
import {toast} from "react-toastify";
import _success from "../../../../assets/helper/successMsgHelper";
import _error from "../../../../assets/helper/errorMsgHelper";
import ModalLoading from "../../../main/modalLoading";
import _default from "../../../../assets/helper/default";

class UploadUserProfileModal extends Component {

    constructor(props) {
        super(props);
        this.valid = true;
        const acceptFile = _default.acceptFileType.split(',');
        this.onDrop = (files) => {
            const status = this.handleRejectedError(files);
            if (status) {
                files.map(file => {

                    if (acceptFile.includes(file.type)) {
                        Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        });
                    }

                });
                this.setState({files, haveContent: false});
            } else {
                this.setState({files: []});
            }
        };
        this.state = {
            files: [],
            target: {},
            inProcess: false,
            errors: {}
        };
    }

    handleRejectedError = (files) => {
        let errors = {};
        let isValid = true;
        const acceptFile = _default.acceptFileType.split(',');
        files.map(file => {
            const sts = acceptFile.includes(file.type);
            if (!sts) {
                isValid = false;
                errors['type'] = "پسوند و فرمت فایل مجاز نیست";
                toast.error("پسوند و فرمت فایل مجاز نیست");
            }
            if (file.size > _default.maxSizeFileUpload) {
                isValid = false;
                errors['size'] = "حجم فایل نمی تواند بیشتر از یک مگابایت باشد";
                toast.error("حجم فایل نمی تواند بیشتر از یک مگابایت باشد");
            }
        });
        this.setState({errors});
        return isValid;

    };

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inProcess: true})
        } else {
            this.setState({inProcess: false})
        }
    }

    handleClose() {
        setStore({userProfilePhotoModal: false});
        this.resetModalInfo();
    }

    resetModalInfo() {
        this.setState({files: [], target: {}});
    }

    handleUploadPhoto(e) {
        e.preventDefault();
        this.handleLoading();
        const {files} = this.state;
        if (files.length > 0) {
            uploadProfilePhoto(files[0])
                .then(res => {
                    if (res.data.isSuccess) {
                        this.props.updateProfilePhoto(res.data.value);
                        updateTokenInfo({"profileImage": res.data.value}, "profileImage");
                        toast.success(_success.profilePhotoChanged);
                        this.handleLoading(false);
                        this.handleClose();
                    } else {
                        res.data.exceptions.map(exp => {
                            let msg = exp.persianDescription;
                            toast.error(msg);
                        });
                        this.handleLoading(false);
                    }
                })
                .catch(() => {
                    toast.error(_error.SystemError);
                    this.handleLoading(false);
                })
        } else {
            this.handleLoading(false);
        }
    }

    render() {
        const img = _default.uploadImageStyle;
        const thumbs = this.state.files.map(file => {
            if (file.type.includes("image/")) {
                return (
                    <div key={file.name}>
                        <div className="action">
                            <img
                                src={file.preview}
                                style={img}
                            />
                            <div className="overly">
                                <p>برای تغییر،روی تصویر کلیک کنید</p>
                            </div>
                        </div>
                    </div>
                )
            }
        });
        const {files, target, inProcess,errors} = this.state;
        return (
            <Modal className="rtl modal-style" show={this.props.userProfilePhotoModal}
                   backdropClassName="multi-level-modal"
                   onHide={this.handleClose.bind(this)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>آپلود تصویر پروفایل</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center upload-area">
                        <p>{target.recordTypeTitle}</p>
                        <Dropzone onDrop={this.onDrop}>
                            {({getRootProps, getInputProps}) => (
                                <section className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <div>
                                            {
                                                thumbs
                                            }
                                        </div>
                                        <input {...getInputProps({accept: _default.acceptFileType})} />
                                        {files.length > 0 ? "" :
                                            <p>فایل مورد نظر را برای آپلود در این کادر قرار دهید</p>}
                                    </div>
                                    <aside>
                                        <p className={["preview", errors['type'] ? "is-invalid" : ""].join(" ")}>پسوندهای
                                            مجاز:
                                            <span>jpeg,jpg,png,pdf</span>
                                        </p>
                                        <p className={["preview", errors['size'] ? "is-invalid" : ""].join(" ")}
                                        >حداکثر اندازه فایل:
                                            <span>یک مگابایت</span>
                                        </p>

                                    </aside>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    <div className="text-right my-2">
                        <button type="button"
                                onClick={this.handleUploadPhoto.bind(this)}
                                className="btn btn-lg btn-primary px-5">
                            آپلود
                        </button>
                    </div>
                    <ModalLoading show={inProcess}/>
                </Modal.Body>
            </Modal>
        );
    }
}

const mstp = state => ({
    userProfilePhotoModal: state.userProfilePhotoModal
});

export default connect(mstp)(UploadUserProfileModal);