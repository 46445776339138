import React, {Component} from 'react';
import logo from "./../../assets/img/logo.png";
import {Link, NavLink} from "react-router-dom";

import {Link as SLink} from 'react-scroll';

class Header extends Component {
    state = {
        isToggle: false
    };

    handleMenu() {
        this.setState({isToggle: !this.state.isToggle});
    }

    render() {
        return (
            <nav className="navbar navbar-expand-md navbar-light bg-white rtl">
                <div className="container">
                    <Link className="navbar-brand ml-5" to="">
                        <img src={logo} alt="logo" height="55px"/>
                    </Link>
                    <button className="navbar-toggler collapsed" type="button"
                            onClick={this.handleMenu.bind(this)}
                            data-toggle="collapse"
                            data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>

                    <div className={this.state.isToggle ? "collapse navbar-collapse show" : "collapse navbar-collapse"}>
                        <ul className="navbar-nav header-menu px-4">
                            <li className="nav-item">
                                <SLink activeClass="active" className="nav-link" to="home" spy={true}
                                       smooth={true}
                                       duration={500}>صفحه اصلی
                                    <span className="sr-only">(current)</span>
                                </SLink>
                            </li>
                            <li className="nav-item">
                                <SLink activeClass="active" className="nav-link" to="slide3Point" spy={true}
                                       smooth={true}
                                       duration={500}>سرمایه گذاری کن!</SLink>
                            </li>
                            <li className="nav-item">
                                <SLink activeClass="active" className="nav-link" to="about" spy={true} smooth={true}
                                       duration={500}>درباره اعتبار</SLink>
                            </li>
                            <li className="nav-item">
                                <SLink activeClass="active" className="nav-link" to="tutorial" spy={true}
                                       smooth={true}
                                       duration={500}>راهنما</SLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="">تماس با ما</NavLink>
                            </li>
                        </ul>
                        <Link to="/login" className="btn btn-success text-white px-5" role="button">ورود</Link>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Header;