import React, {Component} from 'react';
import {
    setDefaultBankAccount,
    deleteBankAccount, deleteBankAccountLocal, changeDefaultLocal, deleteLocal, getBankBranchById, tableStirng
} from "../../../../services/dashboard";
import AddBankAccountModal from './addBankAccountModal';
import {setStore, connect} from "trim-redux";
import _success from "../../../../assets/helper/successMsgHelper";
import {toast} from "react-toastify";
import _error from "../../../../assets/helper/errorMsgHelper";
import _default from "../../../../assets/helper/default.json";
import {confirmAlert} from 'react-confirm-alert';
import Tbl from './../../../main/customModalTableBankAccount';


class Tab2UserProfileModal extends Component {
    state = {
        bankAccountList: [],
        edit: false,
        editInfo: {},
        bankBranchList: []
    };

    componentDidMount() {
        this.setState({bankAccountList: this.props.bankAccounts});
    }

    handleOpenAddBankAccountModal() {
        setStore({addBankAccountModal: true});
    }

    handleDefaultBankAccount=(id)=> {
        setDefaultBankAccount(id)
            .then(res => {
                if (res.data.isSuccess) {
                    const newList = changeDefaultLocal(this.state.bankAccountList, id);
                    this.setState({bankAccountList: newList});
                    this.props.updateInfo('bankAccounts', newList);
                    toast.success(_success.setDefaultBankAccount);
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(() => {
                toast.error(_error.SystemError);
            })
    }

    handleDeleteBankAccount=(id)=> {
        confirmAlert({
            title: '',
            message: _default.confirmModalText,
            buttons: [
                {
                    label: _default.confirmModalCancelLabel,
                    onClick: () => {
                    }
                },
                {
                    label: _default.confirmedDeleteLabel,
                    onClick: () => {
                        deleteBankAccount(id)
                            .then(res => {
                                if (res.data.isSuccess) {
                                    const newList = deleteLocal(this.state.bankAccountList, id);
                                    this.setState({bankAccountList: newList});
                                    this.props.updateInfo('bankAccounts', newList);
                                    toast.success(_success.deletedBankAccount);
                                } else {
                                    let msg = res.data.exceptions[0];
                                    msg = msg.exception.persianDescription;
                                    toast.error(msg);
                                }
                            })
                            .catch((err) => {
                                toast.error(_error.SystemError);
                                console.log(err);
                            })
                    }
                }
            ]
        });

    }

    handleOpenEditBankAccount=(id)=> {
        const target = this.state.bankAccountList.filter(row => row.id === id);
        this.setState({edit: true, editInfo: target[0]});
        this.handleOpenAddBankAccountModal();
    }

    updateBankAccountList = (info, type = 'add') => {
        const list = this.state.bankAccountList;
        if (type === 'update') {
            const newList = deleteLocal(list, info.id);
            newList.push(info);
            this.props.updateInfo('bankAccounts', newList);
            this.setState({bankAccountList: newList});
        } else if (type === 'add') {
            list.push(info);
            this.props.updateInfo('bankAccounts', list);
            this.setState({bankAccountList: list});
        }
    };

    handleResetEditState = () => {
        this.setState({edit: false, editInfo: {}});
    };

    render() {
        const {bankAccountList} = this.state;

        let addressCols = [1, 1, 1, 2, 1];
        let addressHead = ["پیشفرض", "نام بانک", "شماره حساب", "شماره شبا", "عملیات"];
        let addressBody = [];
        for (let i = 0; i < bankAccountList.length; i++) {
            addressBody.push(
                {
                    id: bankAccountList[i].id,
                    data: [
                        {
                            icon: [{
                                class: 'fa fa-star',
                                title: '',
                                func: this.handleDefaultBankAccount,
                                default: bankAccountList[i].default
                            }]
                        },
                        tableStirng(bankAccountList[i].bankTitle,12),
                        tableStirng(bankAccountList[i].accountNumber,15),
                        tableStirng(bankAccountList[i].shabaNumber,15),
                        {
                            icon: [{
                                class: 'fa fa-pen color-green',
                                title: 'ویرایش',
                                func: this.handleOpenEditBankAccount
                            }, {
                                class: 'fa fa-trash color-red',
                                title: 'حذف',
                                func: this.handleDeleteBankAccount
                            }]
                        }
                    ]
                }
            )
        }

        return (
            <>
                <div className="col-md-12 profile-body border p-1 p-md-3">
                    <div className="table-responsive">
                        <Tbl cols={addressCols}
                             head={addressHead}
                             body={addressBody}
                             top={0}
                             tblHeight={268}
                             minWidth={600}
                        />
                    </div>
                    <div className="text-right pt-1">
                        <button onClick={this.handleOpenAddBankAccountModal.bind(this)} title="افزودن شماره حساب"
                                className="btn btn-add btn-lg">+
                        </button>
                    </div>
                </div>
                <div className="col-md-12 text-right my-4 p-0"/>
                <AddBankAccountModal updateBankAccount={this.updateBankAccountList}
                                     listBasicInfo={this.props.listBasicInfo}
                                     edit={this.state.edit}
                                     editInfo={this.state.editInfo}
                                     editFunction={this.handleResetEditState}
                />
            </>
        )
    }
}

const mstp = state => ({
    tmp: state.tmp
});

export default connect(mstp)(Tab2UserProfileModal);