import React, {Component} from 'react';
import {Link} from "react-router-dom";
import * as Scroll from 'react-scroll';
import { Link as SLink, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

class TopSlide3 extends Component {

    render() {
        return (
            <Element name="slide3Point" className="element">
                <section className="py-5 rtl bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1 w-100"/>
                            <div className="col-md-4">
                                <h1 className="pt-5 display-5 theme-color-1 mb-5">
                                    با چند کلیک
                                    <br/>
                                    سرمایه گذاری کن!
                                </h1>
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/usel" className="btn btn-lg d-block btn-gold text-white px-5 py-3 "
                                              role="button">شروع
                                            سرمایه گذاری</Link>
                                        <i className="btn-shiny"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 w-100"/>
                            <div className="col-md-4">
                                <div className="mt-5 mt-md-0" data-aos="fade-up" data-aos-duration="1000">
                                    <img className="d-block m-auto mr-md-auto" src="/img/slide-3.svg" width="260"
                                         height="260" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Element>
        );
    }
}

export default TopSlide3;