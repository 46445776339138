import React, {Component} from 'react';
import {
    deleteDocumentRecord,
    getPersonalityRecordsContent,
    prepareDocumentList
} from "../../../../services/dashboard";
import {setStore} from "trim-redux";
import UploadModal from './uploadModal';
import {toast} from "react-toastify";
import _success from "../../../../assets/helper/successMsgHelper";
import {confirmAlert} from "react-confirm-alert";
import _default from "../../../../assets/helper/default.json";


class Tab4UserProfileModal extends Component {
    state = {
        personalityRecord: [],
        personalityRecordTypes: [],
        fileExtensionTypes: [],
        inProcess: true,
        upload: false,
        uploadInfo: {},
        haveContent: false,
        content: "",
        contentLoading: true,
        fileName: ""
    };

    componentDidMount() {
        this.setState({
            personalityRecord: this.props.recordsInfo,
            personalityRecordTypes: this.props.listBasicInfo.personalityRecordTypes,
            fileExtensionTypes: this.props.listBasicInfo.fileExtensionTypes,
            inProcess: false
        });
    }

    handleUploadModal = () => {
        setStore({uploadModal: true})
    };

    handleOpenUpload(id) {
        const target = this.state.personalityRecordTypes.filter(row => row.id === id);
        this.setState({upload: true, uploadInfo: target[0], content: "", haveContent: false});
        getPersonalityRecordsContent(target[0]['id'])
            .then(res => {
                if (res.data.isSuccess) {
                    if (res.data.id !== 0) {
                        this.setState({
                            haveContent: true,
                            content: res.data.value,
                            contentLoading: false,
                            fileName: res.data.fileName
                        })
                    } else {
                        this.setState({haveContent: "", contentLoading: false})
                    }
                }
            })
            .catch(err => console.log(err));
        this.handleUploadModal();
    }

    updateUploadInfo = (info) => {
        this.props.updateInfo('personalityRecords', info);
        this.setState({personalityRecord: info});
    };

    handleDeleteRecords = (id) => {
        confirmAlert({
            title: '',
            message: _default.confirmModalText,
            buttons: [
                {
                    label: _default.confirmModalCancelLabel,
                    onClick: () => {
                    }
                },
                {
                    label: _default.confirmedDeleteLabel,
                    onClick: () => deleteDocumentRecord(id)
                        .then(res => {
                            if (res.data.isSuccess) {
                                toast.success(_success.documentRecoredDeleted);
                                this.props.updateInfo('personalityRecords', res.data.personalityRecord);
                                this.setState({personalityRecord: res.data.personalityRecord});
                            } else {
                                res.data.exceptions.map(exp => {
                                    let msg = exp.persianDescription;
                                    toast.error(msg);
                                });
                            }
                        })
                        .catch(() => {

                        })
                }
            ]
        });

    };

    resetModalInfo = () => {
        this.setState({haveContent: false, content: "", contentLoading: true})
    };

    render() {
        const docList = prepareDocumentList(this.state.personalityRecordTypes, this.state.personalityRecord);
        return (
            <>
                <div className="col-md-12 profile-body border p-md-3 mb-md-3 p-2">
                    <div className="table-responsive">
                        <table className="table table-sm table-document">
                            <thead>
                            <tr>
                                <th className="text-left" scope="col">نوع مدرک</th>
                                <th className="text-left" scope="col">وضعیت</th>
                                <th className="text-left" scope="col">عملیات</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                docList.map(record => {
                                    return (
                                        <tr key={record.id}>
                                            <td>
                                                <span>{record.mandatory ? '*' : ''}</span>
                                                {record.recordTypeTitle}
                                            </td>
                                            <td>{record.exist ? "موجود" : "ناموجود"}</td>
                                            <td className="actions">
                                    <span className="upload px-2" title="آپلود"
                                          onClick={() => this.handleOpenUpload(record.id)}>
                                        <i className="fa fa-cloud"/>
                                    </span>
                                                {
                                                    record.exist ? (
                                                        <span className="delete px-2" title="حذف"
                                                              onClick={() => this.handleDeleteRecords(record.recordTypeId)}
                                                        >
                                                            <i className="fa fa-trash"/>
                                                        </span>
                                                    ) : (
                                                        <span/>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-12 text-right my-4 p-0"/>
                <UploadModal
                    target={this.state.uploadInfo}
                    upload={this.state.upload}
                    updateInfo={this.updateUploadInfo}
                    haveContent={this.state.haveContent}
                    content={this.state.content}
                    contentLoading={this.state.contentLoading}
                    fileName={this.state.fileName}
                />
            </>
        );
    }
}

export default Tab4UserProfileModal;

