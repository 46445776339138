import React, {Component} from 'react';
import {convertCurrencyTpye} from "../../../../services/fund";
import {toast} from "react-toastify";

class OnlinePaymentTabBuyFundModal extends Component {

    state = {
        fields: {
            unit: 0,
            amount: 0
        }
    };

    handleOnlinePayment = () => {
        toast.error("تنظیمات درگاه بانکی اعمال نشده است")
    };

    calcUnit2Amount = (event) => {
        let fields = this.state.fields;
        let target = event.target;
        const {fundDetail} = this.props;
        const sellPrice = parseInt(fundDetail.fund.navSale);
        const dailyConst = parseFloat(fundDetail.fund.statisticSimpleDaily);
        let finalPrice = 0;
        if (target.name === 'unit') {
            if (target.value === "") {
                fields['unit'] = target.value;
                fields['amount'] = finalPrice;
            } else {
                finalPrice = ((sellPrice * dailyConst) + sellPrice) * parseInt(target.value);
                fields['unit'] = target.value;
                fields['amount'] = Math.ceil(finalPrice);
            }

        } else if (target.name === 'amount') {
            if (target.value === "") {
                fields['amount'] = target.value;
                fields['unit'] = 0;
            } else {
                finalPrice = (parseInt(target.value) / (sellPrice + (sellPrice * dailyConst)));
                console.log(finalPrice);
                finalPrice = Math.floor(finalPrice);
                fields['amount'] = target.value;
                fields['unit'] = finalPrice;
            }

        }
        fields[target.name] = target.value;
        this.setState({fields});
    };

    render() {
        const {fundDetail} = this.props;
        const {fields} = this.state;
        return (
            <form className="w-100">
                <div className="">
                    <div className="form-row mt-4">
                        <div className="form-group col-md-6">
                            <input type="text" name="firstName" className="form-control"
                                   placeholder="معرف"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <input type="text" name="firstName" className="form-control"
                                   onChange={this.handleOnlinePayment.bind(this)}
                                   value={fields.amount}
                                   placeholder="مبلغ سرمایه گزاری (ريال)"
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <input type="text" name="lastName" id="lastName"
                                   className="form-control"
                                   onChange={this.handleOnlinePayment.bind(this)}
                                   value={fields.unit}
                                   placeholder="تعداد تقریبی صدور"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <textarea name="firstName" className="form-control" rows="4"
                                      placeholder="توضیحات"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <p className="rights">قوانین و مقررات را می پذیرم</p>
                            <p className="rights necessary">در صورت ثبت درخواست بعد از ساعت 16 درخواست در روز کاری بعد
                                بررسی خواهد
                                شد.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 w-100"/>
                    <div className="col-md-6 text-right my-3 action-area">
                        <button type="button" className="btn btn-buy btn-lg w-100" onClick={this.handleOnlinePayment}>
                            <i className="fa fa-upload"/>
                            صدور
                            <span>{convertCurrencyTpye(fundDetail.fund.navPurchase)}</span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default OnlinePaymentTabBuyFundModal;