import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {setBackgroundDefault} from "../../../services/login";


class Uselect extends Component {
    state = {
        count: 15,
        showRegenerateCode: false
    };

    componentDidMount() {
        setBackgroundDefault("bg1");
        let timer = setInterval(() => {
            if (this.state.count > 0) {
                this.setState({count: this.state.count - 1});
            } else {
                this.setState({showRegenerateCode: true});
                clearInterval(timer);
            }
        }, 1000);
    }


    render() {
        return (
            <section className="rtl py-5 bg-theme-color-1 uselect" style={{height: 100 + "vh"}}>
                <div className="container">
                    <div className="row">
                        <div data-aos="fade-right" className="form-signin d-block m-auto">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to="/">
                                        <img className="logo d-block m-auto mb-5" src="img/logo-wh.png"
                                             alt="Etebar logo"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 py-4 border-left">
                            <img className="icon d-block m-auto mb-4 pb-4" src="img/login-icon.svg"
                                 alt="Etebar logo"/>
                            <div className="text-white text-center">
                                <p className="mb-1">عضو اعتبار هستید؟</p>
                            </div>
                            <div className="text-center mt-5">
                                <Link to="/login" className="btn btn-success">ورود</Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 py-4">
                            <img className="icon d-block m-auto mb-4 pb-4" src="img/reg-icon.svg"
                                 alt="Etebar logo"/>
                            <div className="text-white text-center">
                                <p className="mb-1">می خواهید عضو اعتبار شوید؟</p>
                            </div>
                            <div className="text-center mt-5">
                                <Link to="/register" className="btn btn-success">ثبت نام</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}

export default Uselect;