import React, {Component} from 'react';
import NavChart from "./Navchart";
import InlineSVG from 'svg-inline-react';
import {setStore} from "trim-redux";
import FundDetailModal from './modal/fundDetailModal';
import BuyFundModal from './modal/buyFundModal';
import SellFundModal from './modal/sellFundModal';
import FundEficientTable from "./fundEficientTable";
import {
    convertCurrencyTpye,
    convertToPdf,
    getBankAccountList,
    getFundBankAccountList,
    licenseDownload
} from "../../../services/fund";
import {toast} from "react-toastify";
import _error from "../../../assets/helper/errorMsgHelper";
import _default from "../../../assets/helper/default.json";
import CustomBarChart from "./CustomBarChart";
import EfficentChart from "./efficentChart"
import SuccessFundBuyModal from "./modal/successFundBuyModal";
import OrderHistoryModal from "./modal/orderHistoryModal";
import FundBuyReceiptModal from "./modal/fundBuyReceiptModal";
import {getTimeInfo} from "../../../services/dashboard";
import fileSaver, {saveAs} from 'file-saver';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

class FundDetail extends Component {

    state = {
        fundBankAccountList: [],
        inProcessFundBankAccountList: true,
        bankAccountList: [],
        inProcessBankAccountList: true,

    };

    handleOpenFundDetailModal() {
        setStore({fundDetailModal: true});
    };

    getMarketStatus = () => {
        getTimeInfo()
            .then(res => {
                if (res.data.isSuccess) {
                    let status = false;
                    if (res.data.marketStatus.marketStatusId === _default.activeFundStatusId) {
                        status = true
                    }
                    setStore({timeInfo: res.data.serverCurrentTime, fundStatus: status});
                } else {
                    toast.error(_error.SystemError);
                }
            })
            .catch(err => {
                toast.error(_error.SystemError);
            });
    };

    handleOpenBuyFundModal = () => {

        getFundBankAccountList()
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({
                        fundBankAccountList: res.data.fundBankAccounts,
                        inProcessFundBankAccountList: false
                    });
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    // toast.error(msg);
                }
            })
            .catch(() => {
                toast.error(_error.SystemError)
            });
        this.getMarketStatus();
        setStore({buyFundModal: true});
    };

    handleOpenSellFundModal = () => {
        getBankAccountList()
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({bankAccountList: res.data.bankAccounts, inProcessBankAccountList: false});
                }
            })
            .catch(() => {
                toast.error(_error.SystemError)
            });
        this.getMarketStatus();
        setStore({sellFundModal: true});
    };

    fundQtyInfo = (fund, customerAssetInfo) => {
        let targetFundCustomerAssets = null;
        if (customerAssetInfo.count > 0) {
            targetFundCustomerAssets = customerAssetInfo.fundLicenseAsset.filter(row => row.fundRegistrationId === fund.registerNumber);
            targetFundCustomerAssets = targetFundCustomerAssets[0]
        }
        return targetFundCustomerAssets;
    };

    HandleGetLicense = (fundId) => {
        console.log(fundId);
        // licenseDownload(fundId)
        //     .then(res => {
        //         const okok=html2canvas(res.data.htmlBody);
        //         let doc = new jsPDF('p','pt','a4');
        //         doc.addHTML(okok,function() {
        //             doc.save('html.pdf');
        //         });
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     });
    };

    render() {
        const {fundBankAccountList, inProcessFundBankAccountList, bankAccountList, inProcessBankAccountList} = this.state;
        const {inProcess, fundDetail, customerAssetInfo} = this.props;
        const fundInfo = fundDetail.fund;
        const svgSource = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="50px" height="50px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B69F33;}
</style>
<g>
	<g>
	<polygon class="tp-1 sp-1 st0" points="47.5,32 35.4,25.1 35.4,39 47.5,46 		"/>
	<polygon class="tp-2 sp-1 st0" points="64.5,39.1 64.5,25 52.5,32 52.4,46 		"/>
	<polygon class="tp-3 st0 sp-2" points="35.4,5.4 35.4,19.4 47.6,26.5 47.6,12.5 		"/>
	<polygon class="tp-4 st0 sp-2" points="64.5,5.4 52.3,12.5 52.3,26.6 64.5,19.5 		"/>
	<polygon class="tp-5 sp-1 st0" points="66.9,43.2 54.7,50.2 66.9,57.2 79,50.2 		"/>
	<polygon class="tp-6 st0 sp-2" points="81.4,15.2 69.2,22.2 69.2,36.4 81.4,29.4 		"/>
	<polygon class="tp-7 st0 sp-2" points="71.6,40.4 83.8,47.6 96,40.5 83.8,33.4 		"/>
	<polygon class="tp-8 sp-1 st0" points="64.5,75.3 64.5,61.4 52.4,54.4 52.4,68.3 		"/>
	<polygon class="tp-9 st0 sp-2" points="71.5,60 83.7,67.1 96,60.1 83.8,53 		"/>
	<polygon class="tp-10 st0 sp-2" points="69.2,78.3 81.3,85.3 81.3,71.1 69.2,64.1 		"/>
	<polygon class="tp-11 sp-1 st0" points="35.4,75.4 47.5,68.4 47.5,54.3 35.4,61.3 		"/>
	<polygon class="tp-12 st0 sp-2" points="52.3,73.8 52.3,87.9 64.5,94.9 64.5,81 		"/>
	<polygon class="tp-13 st0 sp-2" points="35.4,94.9 47.6,87.9 47.6,73.8 35.4,80.8 		"/>
	<polygon class="tp-14 sp-1 st0" points="33,43.2 20.8,50.1 33,57.2 45.1,50.2 		"/>
	<polygon class="tp-15 st0 sp-2" points="18.5,85.2 30.7,78.1 30.7,64 18.5,71 		"/>
	<polygon class="tp-16 st0 sp-2" points="19.4,54.7 16.1,52.8 3.9,59.9 16.1,67 28.4,59.9 		"/>
	<polygon class="tp-17 st0 sp-2" points="19.4,45.5 28.4,40.4 16.2,33.3 3.9,40.3 16.1,47.4 		"/>
	<polygon class="tp-18 st0 sp-2" points="30.6,22.1 18.5,15.1 18.5,29.2 30.6,36.3 		"/>
		
	</g>
</g>
</svg>`;
        if (inProcess) {
            return (
                <section className="my-2 fund-detail-area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 px-2">
                                <div className="fund-detail">
                                    <div className="row">
                                        <div className="col-md-12 p-4 text-center">
                                            <InlineSVG src={svgSource}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        } else {
            const assets = this.fundQtyInfo(fundInfo, customerAssetInfo);
            return (
                <section className="my-2 fund-detail-area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 px-2">
                                <div className="fund-detail">
                                    <div className="row">
                                        <div className="col-md-4 px-2">
                                            <div className="card description rtl mb-3">
                                                <div className="row no-gutters">
                                                    <div className="col-md-8 col-7">
                                                        <div className="card-body">
                                                            <h5 className="card-title">{fundInfo.legalTitle}</h5>
                                                            <p className="card-text">
                                                                <small className="bold">{fundInfo.fundTypeTitle}
                                                                </small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-5">
                                                        <img src="/img/admin/logo-admin.svg" className="card-img"
                                                             alt="..."/>
                                                    </div>
                                                </div>
                                                <p className="about">

                                                </p>
                                                <div className="px-3 text-right">
                                                    <button type="button"
                                                            className="btn btn-primary btn-sm px-3 btn-more"
                                                            onClick={this.handleOpenFundDetailModal.bind(this)}
                                                    >
                                                        جزئیات بیشتر
                                                    </button>
                                                </div>
                                                <div className="row my-2 px-2">
                                                    <div className="col-md-4 w-100"/>
                                                    <div className="col-md-8">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <tbody>
                                                                <tr>
                                                                    <td>واحد های شما</td>
                                                                    <td>{assets ? convertCurrencyTpye(assets.quantity) : "0"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>ارزش دارایی شما</td>
                                                                    <td>{assets ? convertCurrencyTpye(assets.assetValue) : "0"}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <p className="cert">
                                                            {
                                                                <span
                                                                    onClick={() => this.HandleGetLicense(fundInfo.legalRegistrationId)}>
                                                                <img className="icon" width="20"
                                                                     src="img/admin/icon/cert-dl.svg" alt=""/>
                                                            دریافت گواهی
                                                            </span>

                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="container-fluid">
                                                    <div className="row px-2">
                                                        <div className="col-md-6 p-0 py-1">
                                                            <button type="button" className="btn btn-buy btn-lg w-100"
                                                                    onClick={this.handleOpenBuyFundModal}
                                                            >
                                                                <img className="icon" src="img/admin/icon/fund-buy.svg"
                                                                     alt=""/>
                                                                صدور
                                                                <span>{convertCurrencyTpye(fundInfo.navPurchase)}</span>
                                                            </button>
                                                        </div>
                                                        <div className="col-md-6 p-0 py-1">
                                                            <button type="button" className="btn btn-sell btn-lg w-100"
                                                                    onClick={this.handleOpenSellFundModal}
                                                            >
                                                                <img className="icon" src="img/admin/icon/fund-sell.svg"
                                                                     alt=""/>
                                                                ابطال
                                                                <span>{convertCurrencyTpye(fundInfo.navSale)}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-4 px-2">
                                            <EfficentChart/>
                                            <div className="bg-white eficient-area">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h6 className="text-right title">
                                                            بازده
                                                            <img className="icon" width="20"
                                                                 src="img/admin/icon/efficient.svg" alt=""/>
                                                        </h6>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="px-3">
                                                            <FundEficientTable fundDetail={fundDetail}/>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-4 px-2">
                                            <NavChart/>
                                            <div className="bg-white complex-chart mr-md-2">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h6 className="text-right title">
                                                            ترکیب دارایی
                                                            <img className="icon" width="20"
                                                                 src="img/admin/icon/complex-assets.svg" alt=""/>
                                                        </h6>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="px-3">
                                                            <CustomBarChart/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FundDetailModal fundDetail={fundDetail}/>

                    <BuyFundModal fundDetail={fundDetail}
                                  inProcess={inProcessFundBankAccountList}
                                  assets={assets}
                                  fundBankAccountList={fundBankAccountList}
                    />


                    <SellFundModal fundDetail={fundDetail}
                                   assets={assets}
                                   bankAccountList={bankAccountList}
                                   inProcess={inProcessBankAccountList}
                    />

                    <SuccessFundBuyModal/>
                    <OrderHistoryModal/>
                    <FundBuyReceiptModal/>
                </section>
            );
        }

    }

}

export default FundDetail;