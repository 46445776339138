import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Element} from "react-scroll/modules";

class About extends Component {
    render() {
        return (
            <Element name="about" className="element">
                <section className="rtl py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1 w-100"></div>
                            <div className="col-12 col-md-4">
                                <div className="py-3 mt-5 mt-md-0">
                                    <img className="mx-auto d-block img-fluid" src="/img/tab1.png"
                                         width="220px"
                                         height="220px" alt=""/>
                                </div>
                            </div>
                            <div className="col-12 col-md-7">
                                <h1 className="px-3 pt-4 mb-0 theme-color-1 font-weight-bold">اعتبار سرمایه شماست</h1>
                                <h4 className="p-3 mb-3">
                                    روش نوین بازار سرمایه
                                </h4>
                                <p className="text-justify px-3">
                                    هدف از تشکیل صندوق، جمع‌آوری سرمایه از سرمایه‌گذاران و تشکیل سبدی از دارایی‌ها و
                                    مدیریت این سبد است؛ با توجه به پذیرش ریسک مورد قبول، تلاش می‌شود، بیشترین بازدهی
                                    ممکن نصیب سرمایه‌گذاران گردد. انباشته‌شدن سرمایه در صندوق، مزیت‌های متعددی نسبت به
                                    سرمایه‌گذاری انفرادی سرمایه‌گذاران دارد: اولاً هزینه بکارگیری نیروهای متخصص، گردآوری
                                    و تحليل اطلاعات و گزينش سبد بهينه اوراق بهادار بین همه سرمایه‌گذاران تقسيم می ‌شود و
                                    سرانه هزینه هر سرمایه ‌گذ‌ار کاهش می‌یابد.
                                </p>
                                <div className="text-right pr-3">
                                    <Link to="/" className="btn btn-theme-3 font-weight-light btn-lg text-white px-5 py-3">بیشتر</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </Element>
        );
    }
}

export default About;