import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {connect, setStore} from "trim-redux";
import Select from "react-virtualized-select";
import {prepareTagsOptions, sendNewMessage} from "../../../../services/fund";
import {checkValidation} from "../../../../services/dashboard";
import _error from "../../../../assets/helper/errorMsgHelper";

class NewMessageModal extends Component {

    state = {
        fields: {
            subject: "",
            category: "",
            msg: ""
        },
        reqiredField: {
            subject: {},
            category: {},
            msg: {}
        },
        errors: {}
    };

    handleClose() {
        setStore({newMessageModal: false});
        this.resetFormInfo();
    }

    checkValidationForm(callback) {
        let {fields, reqiredField} = this.state;
        let errors = {};
        let formIsValid = true;

        for (let fieldIndex in reqiredField) {
            const validUnit = checkValidation(fields[fieldIndex] === null ? '' : fields[fieldIndex], reqiredField[fieldIndex]);
            if (!validUnit) {
                errors[fieldIndex] = _error.isRequire;
            }
            formIsValid = formIsValid && validUnit;
        }
        this.setState({errors}, () => {
            return callback(formIsValid);
        });

    }

    resetFormInfo() {
        this.setState({
            fields: {
                subject: "",
                category: "",
                msg: ""
            },
            errors: {}
        })
    }

    handleChangeSelect = (info, name, multi = false) => {
        let val = null;
        let fields = this.state.fields;
        if (info !== null) {
            fields[name] = "";
            if (multi) {
                info.map(row => {
                    fields[name] = fields[name] + "," + row.value;
                    while (fields[name].charAt(0) === ',') {
                        fields[name] = fields[name].substr(1);
                    }
                })
            } else {
                val = info.value;
                fields[name] = val;
            }
        }
        this.setState({fields});
    };

    handleChangeInput = (event) => {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    };

    handleSendMsg(e) {
        e.preventDefault();
        let {fields} = this.state;
        this.checkValidationForm(sts => {
            if (sts) {
                sendNewMessage(fields)
                    .then(res => {
                        if (res.data.isSuccess) {
                            this.props.getAllMsg();
                            this.handleClose();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        })

    }

    render() {
        const {fields, errors} = this.state;
        const {category} = this.state.fields;
        const tagsOptions = prepareTagsOptions(this.props.tags);
        return (
            <Modal className="rtl modal-style buy-fund" show={this.props.newMessageModal}
                   backdropClassName="multi-level-modal"
                   onHide={this.handleClose.bind(this)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>ارسال پیام جدید</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row px-4">
                            <div className="col-md-12">
                                <form action="" className="w-100" onSubmit={this.handleSendMsg.bind(this)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6 mb-1">
                                            <label htmlFor="bankAccount" className="account-label">
                                                *عنوان
                                            </label>
                                            <input type="text"
                                                   name="subject"
                                                   onChange={this.handleChangeInput.bind(this)}
                                                   className={["form-control ", errors['subject'] ? 'is-invalid' : ''].join(' ')}
                                                   value={fields.subject}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="bankAccount" className="account-label">
                                                موضوع
                                            </label>
                                            <Select
                                                className={["select-search ", errors['category'] ? 'is-invalid' : ''].join(' ')}
                                                value={category}
                                                onChange={(info) => this.handleChangeSelect(info, 'category', true)}
                                                options={tagsOptions}
                                                name="category"
                                                multi={true}
                                                backspaceRemoves={false}
                                                isSearchable
                                                placeholder="موضوع پیام را انتخاب کنید"
                                            />

                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <textarea name="msg" rows="7"
                                                      className={["form-control ", errors['msg'] ? 'is-invalid' : ''].join(' ')}
                                                      onChange={this.handleChangeInput.bind(this)}
                                                      placeholder="متن پیام"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6 w-100"/>
                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-primary w-100">
                                                ارسال
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mstp = state => ({
    newMessageModal: state.newMessageModal
});


export default connect(mstp)(NewMessageModal);