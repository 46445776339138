import React, {Component} from 'react';
import validator from 'validator';
import {getCaptchaUrl, verifyCaptcha, verifyUser, setAuth, randomKeyBoard, checkToken} from "../../../services/login";
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import AOS from 'aos';
import {toast} from "react-toastify";
import _error from '../../../assets/helper/errorMsgHelper.json'
import {setStore, connect} from "trim-redux";
import {checkTokenInfoType, clearUserInfoLoginAgain, setBackgroundDefault} from "../../../services/dashboard";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import _default from "../../../assets/helper/default";


AOS.init();

class LoginForm extends Component {
    constructor(props) {
        super(props);
        let apiToken = localStorage.getItem(_default.tokenName);
        const checkTokenType = checkTokenInfoType(apiToken);
        if (checkTokenType) {
            apiToken = JSON.parse(apiToken);
            if (apiToken !== null && this.props.auth) {
                this.props.history.replace("/dashboard");
            } else {
                clearUserInfoLoginAgain();
            }
        } else {
            clearUserInfoLoginAgain();
        }
    }

    state = {
        fields: {
            username: '',
            password: '',
            captcha: '',
            captchaKey: ''
        },
        errors: {},
        captchaURL: '',
        inProcess: false,
        options: [],
        CustomMapping: [],
        input: "",
        layoutName: "default",
        layout: {},
        showPassKeyboard: false,
        showUserKeyboard: false,
        loginRights: false
    };

    componentDidMount() {
        setBackgroundDefault("bg1");
        this.reCaptcha();
        setStore({userLogOutModal: false});

    }

    componentWillMount() {
        const layout = randomKeyBoard();
        this.setState({layout});

    }

    reCaptcha() {
        const captchaAddress = getCaptchaUrl();
        this.setState({captchaURL: captchaAddress.address});
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                captcha: '',
                captchaKey: captchaAddress.captchaKey
            }
        }));
    };

    handleChangeCaptcha(e) {
        e.preventDefault();
        this.reCaptcha();
    }

    handleChange(event) {
        let fields = this.state.fields;
        let target = event.target;
        if (target.type !== "checkbox") {
            if (target.name === "password") {
                if (this.state.loginRights) {
                    fields[target.name] = target.value;
                    this.setState({fields});
                }
            } else {
                fields[target.name] = target.value;
                this.setState({fields});
            }
        } else {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            this.setState({loginRights: value});
        }


    }

    checkValidation(callback) {
        let {fields} = this.state;
        let errors = {};
        let formIsValid = true;

        // validate username
        if (validator.isEmpty(fields.username)) {
            formIsValid = false;
            errors['username'] = _error.emptyUsername;
        } else if (!validator.isLength(fields.username, {min: 10, max: 10})) {
            formIsValid = false;
            errors['username'] = _error.incorrectUsername;
        }

        // validate password
        if (validator.isEmpty(fields.password)) {
            formIsValid = false;
            errors['password'] = _error.emptyPassword;
        } else if (!validator.isLength(fields.password, {min: 8, max: undefined})) {
            formIsValid = false;
            errors['password'] = _error.incorrectPassword;
        }

        // validate captcha
        if (validator.isEmpty(fields.captcha)) {
            formIsValid = false;
            errors['captcha'] = _error.emptyCaptcha;
        } else if (validator.isLength(fields.captcha, {min: 1, max: 4})) {
            formIsValid = false;
            errors['captcha'] = _error.incorrectCaptcha;
        }
        this.setState({errors}, () => {
            return callback(formIsValid)
        });

    }

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inProcess: true})
        } else {
            this.setState({inProcess: false})
        }
    }

    makeRequest(data) {
        verifyCaptcha(data)
            .then(captchaResponse => {
                if (captchaResponse.data.isSuccess) {
                    verifyUser(data)
                        .then(userInfo => {
                            setAuth(userInfo.data, data)
                                .then(() => {
                                    setStore({auth: true});
                                    this.props.history.replace('/dashboard');
                                })
                                .catch(err => {
                                    toast.error(_error.SystemError);
                                    this.handleLoading(false);
                                });
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                toast.error(_error.SystemError);
                            }
                            this.reCaptcha();
                            this.handleLoading(false);
                        })
                } else {
                    const tmp = captchaResponse.data.exceptions[0];
                    let msg = tmp.exception.persianDescription;
                    toast.error(msg);
                    this.reCaptcha();
                    this.handleLoading(false);
                }
            })
            .catch(err => console.log(err));
    }

    handleSubmit(e) {
        e.preventDefault();
        this.handleLoading();
        let {fields} = this.state;
        this.checkValidation(sts => {
            if (sts) this.makeRequest(fields);
            else this.handleLoading(false);
        });

    };


    onChangePassword = input => {
        let fields = this.state.fields;
        fields["password"] = input;
        this.setState({fields});
    };

    onKeyPressPassword = button => {
        if (button === "{shift}" || button === "{lock}") this.handleShift();
        this.setState({onKeyPressPassword: true});
    };

    handleShift = () => {
        let layoutName = this.state.layoutName;

        this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
        });
    };

    handleToggleShow = () => {
        this.setState(prevState => ({showPassKeyboard: !prevState.showPassKeyboard}))
    };

    handleHideKeyboard = () => {
        if (this.state.showPassKeyboard) {
            this.setState({showPassKeyboard: false});
        }
    };

    render() {
        const {errors} = this.state;
        return (
            <section className="rtl bg-theme-color-1 pb-2 full-height" onClick={this.handleHideKeyboard.bind(this)}>
                <div className="container">
                    <div className="row">
                        <form onSubmit={this.handleSubmit.bind(this)} data-aos="fade-right"
                              className="form-signin d-block m-auto">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to="/">
                                        <img className="logo d-block m-auto mb-1" src="img/logo-wh.png"
                                             alt="Etebar logo"/>
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <img className="icon d-block m-auto mb-4 pb-2" src="img/login-icon.svg"
                                         alt="Etebar logo"/>
                                </div>
                            </div>
                            <div className="row login-rights">
                                <div className="col-md-12">
                                    <div className="form-check">

                                        <OverlayTrigger placement={"left"} overlay={<Tooltip id="loginDescription">
                                            برنامه‌هایی تحت عنوان Key Logger وجود دارند که می‌توانند ضربات صفحه کلید را
                                            ضبط نمایند و بدین طریق به اطلاعات امنیتی کاربر دست یابند. یکی از روش‌های
                                            جلوگیری از ضبط ضربات کلید، استفاده از یک صفحه کلید مجازی جهت وارد نمودن
                                            اطلاعات امنیتی است.در صفحه کلید مجازی، مکان حروف هر بار تغییر می‌کند و مانع
                                            از ضبط اطلاعات امنیتی کاربر می‌شود.
                                        </Tooltip>}>
                                            <span className="d-inline-block">
                                               <input className="form-check-input" type="checkbox"
                                                      value={this.state.loginRights}
                                                      onChange={this.handleChange.bind(this)}
                                                      name="loginRights"
                                                      id="loginRights"/>
                                        <label className="form-check-label" htmlFor="loginRights">
                                            مایل به استفاده از صفحه کلید امن نیستم و خطرات ناشی از آن را می‌پذیرم
                                        </label>
                                            </span>
                                        </OverlayTrigger>;
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <input type="text"
                                           className={["form-control ", errors['username'] ? 'is-invalid' : ''].join('')}
                                           name="username"
                                           value={this.state.fields.username}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder="نام کاربری"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <input type="password"
                                           disabled={!this.state.loginRights}
                                           className={["form-control ", errors['password'] ? 'is-invalid' : ''].join('')}
                                           name="password"
                                           value={this.state.fields.password}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder="کلمه عبور"
                                    />

                                    {
                                        this.state.showPassKeyboard ? (
                                            <Keyboard
                                                // baseClass={[this.state.showPassKeyboard ? "simple-keyboard hg-theme-default hg-layout-default show" : "hidden"]}
                                                ref={f => (this.keyboardRef = f)}
                                                layoutName={this.state.layoutName}
                                                onChange={input => this.onChangePassword(input)}
                                                onKeyPress={button => this.onKeyPressPassword(button)}
                                                layout={this.state.layout}
                                                display={{
                                                    '{bksp}': 'حذف',
                                                }}
                                            />
                                        ) : null
                                    }
                                    <div className="vkey" onClick={() => this.handleToggleShow()}>
                                        <i className="fa fa-keyboard"/>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <input type="text"
                                           className={["form-control captcha ", errors['captcha'] ? 'is-invalid' : ''].join('')}
                                           name="captcha"
                                           value={this.state.fields.captcha}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder="کد امنیتی"
                                           autoComplete="off"
                                           required=""/>
                                </div>
                                <div className="col-5 pr-0">
                                    <img className="img-fluid captcha" src={this.state.captchaURL} alt=""/>
                                </div>
                                <div className="col-2 p-2">
                                    <div>
                                        <img className="refresh" src="img/refresh.svg" height="30" width="30"
                                             onClick={this.handleChangeCaptcha.bind(this)}
                                             alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-12">
                                    <p className="error-area" style={{display: errors["username"] ? 'block' : 'none'}}>
                                        <i className="fa fa-times"/>
                                        {errors["username"]}
                                    </p>
                                    <p className="error-area" style={{display: errors["password"] ? 'block' : 'none'}}>
                                        <i className="fa fa-times"/>
                                        {errors["password"]}
                                    </p>
                                    <p className="error-area" style={{display: errors["captcha"] ? 'block' : 'none'}}>
                                        <i className="fa fa-times"/>
                                        {errors["captcha"]}
                                    </p>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2 w-100"/>
                                <div className="col-8">
                                    <button disabled={this.state.inProcess} className="btn btn-primary btn-block"
                                            type="submit">
                                        {this.state.inProcess ? 'لطفا صبر کنید...' : 'ورود'}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 forget-area">
                                    <p className="my-2">
                                        رمز عبور خود را فراموش کرده اید؟ |
                                        <Link to="forgetPassword">
                                            <span className="text-white"> بازیابی رمز عبور </span>
                                        </Link>
                                    </p>
                                    <p className="m2-2">
                                        ثبت نام نکرده اید؟ |

                                        <Link to="register">
                                            <span className="text-white"> ثبت نام </span>
                                        </Link>
                                    </p>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <hr className="border-white my-2"/>
                                    <div className="row">
                                        <div className="col-12 ">
                                            <h6 className="text-white px-2">نکات امنیتی</h6>
                                            <p className="description text-white px-2">
                                                لطفا بررسی نمایید آدرس سایت صندوق امین سامان برابر باشد با:
                                                <br/>
                                                <a href="http://www.etebar.com">http://www.etebar.com</a>
                                            </p>
                                            <p className="sub-description px-2">
                                                <i className="fa fa-circle"></i>
                                                برای ورود به صندوق، حتی الامکان از صفحه کلید امن استفاده نمایید.
                                            </p>
                                            <p className="sub-description px-2">
                                                <i className="fa fa-circle"></i>
                                                هرگز نام کاربری و رمزعبور خود را در اختیار دیگران قرار ندهید.
                                            </p>
                                            <p className="sub-description px-2">
                                                <i className="fa fa-circle"></i>
                                                پس از اتمام کار بر روی دکمه خروج کلیک نمایید.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="row">

                    </div>
                </div>
            </section>
        );
    }
}

const mstp = state => ({
    auth: state.auth,
    loading: state.loading
});

export default connect(mstp)(LoginForm);
