import React, {Component} from 'react';
import {Element} from "react-scroll/modules";


class TopSlide1 extends Component {

    render() {
        return (
            <Element name="home" className="element">
                <section className="py-5 rtl bg-light" role="main">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1 w-100"/>
                            <div className="col-md-4">
                                <h1 className="py-5 display-5 theme-color-1">
                                    شعار ورودی
                                    <br/>
                                    در برگیرنده سود
                                </h1>
                            </div>
                            <div className="col-md-2 w-100"/>
                            <div className="col-md-4">
                                <div className="pl-md-4">
                                    <div data-aos="fade-up" data-aos-once="true" data-aos-anchor=".trg">
                                        <img className="d-block m-auto mr-sm-auto" src="/img/slide-1.svg" width="200"
                                             height="200"
                                             alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1 w-100"/>
                        </div>
                    </div>
                    <div className="trg"/>
                </section>
            </Element>
        );
    }
}

export default TopSlide1;