import React, {Component} from 'react';
import {
    setDefaultPhone,
    setDefaultElecAddress,
    deleteContactInfo, changeDefaultLocal, deleteElecAddress, deleteLocal, deleteAddress, tableStirng
} from "../../../../services/dashboard";
import AddPhoneModal from './addPhoneModal';
import AddElecAddressModal from './addElecAddressModal';
import AddAddressModal from './addAddressModal';
import {setStore, connect} from "trim-redux";
import {toast} from "react-toastify";
import _success from "../../../../assets/helper/successMsgHelper";
import _error from "../../../../assets/helper/errorMsgHelper";
import _default from "../../../../assets/helper/default";
import {confirmAlert} from "react-confirm-alert";

import Tbl from './../../../main/ModalCustomTbl';

class Tab3UserProfileModal extends Component {

    state = {
        phoneList: [],
        electronicAddressesList: [],
        locationAddressesList: [],
        edit: false,
        editInfo: {},
        editType: '',
        editAddress: false,
        editAddressInfo: {}
    };

    componentDidMount() {
        this.setState({
            phoneList: this.props.phones,
            electronicAddressesList: this.props.electronicAddresses,
            locationAddressesList: this.props.locationAddresses
        });
    }

    handleDefaultPhone = (id) => {
        setDefaultPhone(id)
            .then(res => {
                if (res.data.isSuccess) {
                    const newList = changeDefaultLocal(this.state.phoneList, id);
                    this.setState({phoneList: newList});
                    this.props.updateInfo('phones', newList);
                    toast.success(_success.setDefaultPhoneNumber);
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(err => {
                toast.error(_error.SystemError);
            })
    };

    handleDefaultElecAddress = (id) => {
        setDefaultElecAddress(id)
            .then(res => {
                if (res.data.isSuccess) {
                    const newList = changeDefaultLocal(this.state.electronicAddressesList, id);
                    this.setState({electronicAddressesList: newList});
                    this.props.updateInfo('electronicAddresses', newList);
                    toast.success(_success.setDefaultElecAddress);
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(err => {
                toast.error(_error.SystemError);
            })
    };

    updateContactList = (info, type = 'add') => {
        const list = this.state.phoneList;
        if (type === 'update') {
            const newList = deleteLocal(list, info.id);
            newList.push(info);
            this.props.updateInfo('phones', newList);
            this.setState({phoneList: newList});
        } else if (type === 'add') {
            list.push(info);
            this.props.updateInfo('phones', list);
            this.setState({phoneList: list});
        }
    };

    updateElecAddressList = (info, type = 'add') => {
        const list = this.state.electronicAddressesList;
        if (type === 'update') {
            const newList = deleteLocal(list, info.id);
            newList.push(info);
            this.props.updateInfo('electronicAddresses', newList);
            this.setState({electronicAddressesList: newList});
        } else if (type === 'add') {
            list.push(info);
            this.props.updateInfo('electronicAddresses', list);
            this.setState({electronicAddressesList: list});
        }
    };

    updateAddressList = (info, type = 'add') => {
        const list = this.state.locationAddressesList;
        if (type === 'update') {
            const newList = deleteLocal(list, info.id);
            newList.push(info);
            this.props.updateInfo('locationAddresses', newList);
            this.setState({locationAddressesList: newList});
        } else if (type === 'add') {
            list.push(info);
            this.props.updateInfo('locationAddresses', list);
            this.setState({locationAddressesList: list});
        }
    };

    handleResetEditState = () => {
        this.setState({edit: false, editInfo: {}, editType: ''});
    };

    handleOpenEdit = (id, type) => {

        if (type === 'phone') {
            const target = this.state.phoneList.filter(row => row.id === id);
            this.setState({edit: true, editInfo: target[0], editType: 'phone'});
            this.handleAddPhoneModal();
        } else if (type === 'elecAddress') {
            const target = this.state.electronicAddressesList.filter(row => row.id === id);
            this.setState({edit: true, editInfo: target[0], editType: 'elecAddress'});
            this.handleAddElecAddressModal();
        } else if (type === 'location') {
            const target = this.state.locationAddressesList.filter(row => row.id === id);
            this.setState({edit: true, editInfo: target[0], editType: 'address'});
            this.handleAddAddressModal();
        }

    };

    handleAddPhoneModal() {
        setStore({addPhoneModal: true});
    }

    handleAddElecAddressModal() {
        setStore({addElecAddressModal: true});
    }

    handleAddAddressModal() {
        setStore({addAddressModal: true});
    }


    handleDeletePhoneNumber = (id) => {
        confirmAlert({
            title: '',
            message: _default.confirmModalText,
            buttons: [
                {
                    label: _default.confirmModalCancelLabel,
                    onClick: () => {
                    }
                },
                {
                    label: _default.confirmedDeleteLabel,
                    onClick: () => {
                        deleteContactInfo(id)
                            .then(res => {
                                if (res.data.isSuccess) {
                                    const newList = deleteLocal(this.state.phoneList, id);
                                    this.setState({phoneList: newList});
                                    this.props.updateInfo('phones', newList);
                                    toast.success(_success.deletedPhoneNumber);
                                } else {
                                    let msg = res.data.exceptions[0];
                                    msg = msg.exception.persianDescription;
                                    toast.error(msg);
                                }
                            })
                            .catch(() => toast.error(_error.SystemError))
                    }
                }
            ]
        });

    };

    handleDeleteElecAddress = (id) => {
        confirmAlert({
            title: '',
            message: _default.confirmModalText,
            buttons: [
                {
                    label: _default.confirmModalCancelLabel,
                    onClick: () => {
                    }
                },
                {
                    label: _default.confirmedDeleteLabel,
                    onClick: () => {
                        deleteElecAddress(id)
                            .then(res => {
                                if (res.data.isSuccess) {
                                    const newList = deleteLocal(this.state.electronicAddressesList, id);
                                    this.setState({electronicAddressesList: newList});
                                    this.props.updateInfo('electronicAddresses', newList);
                                    toast.success(_success.deletedElecAddress);
                                } else {
                                    let msg = res.data.exceptions[0];
                                    msg = msg.exception.persianDescription;
                                    toast.error(msg);
                                }
                            })
                            .catch(() => toast.error(_error.SystemError))
                    }
                }
            ]
        });
    };

    handleDeleteAddress = (id) => {
        confirmAlert({
            title: '',
            message: _default.confirmModalText,
            buttons: [
                {
                    label: _default.confirmModalCancelLabel,
                    onClick: () => {
                    }
                },
                {
                    label: _default.confirmedDeleteLabel,
                    onClick: () => {
                        deleteAddress(id)
                            .then(res => {
                                if (res.data.isSuccess) {
                                    const newList = deleteLocal(this.state.locationAddressesList, id);
                                    this.setState({locationAddressesList: newList});
                                    this.props.updateInfo('locationAddresses', newList);
                                    toast.success(_success.deletedAddress);
                                } else {
                                    let msg = res.data.exceptions[0];
                                    msg = msg.exception.persianDescription;
                                    toast.error(msg);
                                }
                            })
                            .catch(() => toast.error(_error.SystemError))
                    }
                }
            ]
        });

    };

    render() {
        const {electronicAddressesList, phoneList, locationAddressesList} = this.state;
        let numBody = [];
        for (let i = 0; i < phoneList.length; i++) {
            numBody.push(
                {
                    id: phoneList[i].id,
                    data: [
                        {
                            icon: [{
                                class: 'fa fa-star',
                                title: '',
                                func: this.handleDefaultPhone,
                                default: phoneList[i].default
                            }]
                        },
                        tableStirng(phoneList[i].phoneTypeTitle,10),
                        tableStirng(phoneList[i].value,11),
                        {
                            icon: [{
                                class: 'fa fa-pen color-green',
                                title: 'ویرایش',
                                func: this.handleOpenEdit,
                                type: 'phone'
                            }, {
                                class: 'fa fa-trash color-red',
                                title: 'حذف',
                                func: this.handleDeletePhoneNumber
                            }]
                        }
                    ]
                }
            )
        }
        let numCols = [1, 1, 2, 1];
        let numHead = ["پیشفرض", "نوع", "شماره", "عملیات"];
        let numTopHead = "شماره تماس ها";

        let mailCols = [1, 1, 2, 1];
        let mailHead = ["پیشفرض", "نوع", "آدرس", "عملیات"];
        let mailTopHead = "آدرس الکترونیکی";
        let mailBody = [];
        for (let i = 0; i < electronicAddressesList.length; i++) {
            mailBody.push(
                {
                    id: electronicAddressesList[i].id,
                    data: [
                        {
                            icon: [{
                                class: 'fa fa-star',
                                title: '',
                                func: this.handleDefaultElecAddress,
                                default: electronicAddressesList[i].default
                            }]
                        },
                        tableStirng(electronicAddressesList[i].electronicAddressTypeTitle, 7),
                        tableStirng(electronicAddressesList[i].value, 13),
                        {
                            icon: [{
                                class: 'fa fa-pen color-green',
                                title: 'ویرایش',
                                func: this.handleOpenEdit,
                                type: 'elecAddress'
                            }, {
                                class: 'fa fa-trash color-red',
                                title: 'حذف',
                                func: this.handleDeleteElecAddress
                            }]
                        }
                    ]
                }
            )
        }

        let addressCols = [1, 2, 1, 1];
        let addressHead = ["نوع", "آدرس", "کدپستی", "عملیات"];
        let addressTopHead = "آدرس";
        let addressBody = [];
        for (let i = 0; i < locationAddressesList.length; i++) {
            addressBody.push(
                {
                    id: locationAddressesList[i].id,
                    data: [
                        tableStirng(locationAddressesList[i].locationAddressTypeTitle, 10),
                        tableStirng(locationAddressesList[i].value, 40),
                        tableStirng(locationAddressesList[i].postalCode, 10),
                        {
                            icon: [{
                                class: 'fa fa-pen color-green',
                                title: 'ویرایش',
                                func: this.handleOpenEdit,
                                type: 'location'
                            }, {
                                class: 'fa fa-trash color-red',
                                title: 'حذف',
                                func: this.handleDeleteAddress
                            }]
                        }
                    ]
                }
            )
        }
        return (
            <>
                <div className="col-md-12 profile-body border px-3">
                    <div className="row">
                        <div className="col-md-6 px-1">
                            <Tbl cols={numCols}
                                 head={numHead}
                                 topHead={numTopHead}
                                 body={numBody}
                                 tblHeight={113}
                            />
                            <div className="text-right pt-1">
                                <button onClick={this.handleAddPhoneModal.bind(this)} title="افزودن شماره تماس"
                                        className="btn btn-add btn-lg">+
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 px-1">
                            <Tbl cols={mailCols}
                                 head={mailHead}
                                 topHead={mailTopHead}
                                 body={mailBody}
                                 tblHeight={113}
                            />
                            <div className="text-right pt-1">
                                <button onClick={this.handleAddElecAddressModal.bind(this)}
                                        title="افزودن آدرس الکترونیکی"
                                        className="btn btn-add btn-lg">+
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12 px-1">
                            <Tbl cols={addressCols}
                                 head={addressHead}
                                 topHead={addressTopHead}
                                 body={addressBody}
                                 tblHeight={113}
                                 // minWidth={700}
                            />
                            <div className="text-right pt-1">
                                <button onClick={this.handleAddAddressModal.bind(this)} title="افزودن آدرس"
                                        className="btn btn-add btn-lg">+
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 text-right my-4 p-0"/>
                <AddPhoneModal updateContactList={this.updateContactList}
                               listBasicInfo={this.props.listBasicInfo}
                               edit={this.state.edit}
                               editInfo={this.state.editInfo}
                               editType={this.state.editType}
                               editFunction={this.handleResetEditState}
                />
                <AddElecAddressModal updateElecAddressList={this.updateElecAddressList}
                                     listBasicInfo={this.props.listBasicInfo}
                                     edit={this.state.edit}
                                     editInfo={this.state.editInfo}
                                     editType={this.state.editType}
                                     editFunction={this.handleResetEditState}
                />
                <AddAddressModal listBasicInfo={this.props.listBasicInfo}
                                 updateAddressList={this.updateAddressList}
                                 edit={this.state.edit}
                                 editInfo={this.state.editInfo}
                                 editType={this.state.editType}
                                 editFunction={this.handleResetEditState}
                />
            </>
        );
    }
}

const mstp = state => ({
    tmp: state.tmp
});

export default connect(mstp)(Tab3UserProfileModal);