import React, {Component} from 'react';
import {checkValidation, persianDigit, prepareSelectData} from "../../../../services/dashboard";
import Select from "react-virtualized-select";
import moment from 'moment-jalaali';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';
import {connect, setStore} from 'trim-redux';
import {
    checkTimeStatus,
    convertCurrencyTpye, personalInfoStatusForIssuing, personalprofileStatus,
    setBuyFundWithBankAccount,
    uploadBuyPaymentDocument
} from "../../../../services/fund";
import {toast} from "react-toastify";
import _success from "../../../../assets/helper/successMsgHelper";
import _error from "../../../../assets/helper/errorMsgHelper";
import InlineSVG from "svg-inline-react";
import UploadFundBuyPayment from "./uploadFundBuyPayment";
import FundBuyReceiptModal from "./fundBuyReceiptModal";
import CurrencyInput from 'react-currency-input';
import remSep from "string-remove-thousand-separators";
import {Overlay, OverlayTrigger, Tooltip} from "react-bootstrap";


moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: false
});

class AccountPaymentTabBuyFundModal extends Component {

    state = {
        fields: {
            bankAccount: '',
            reagent: '',
            amount: "",
            unit: "",
            trackingNumber: '',
            payDate: moment().format(),
            description: '',
            rights: null
        },
        mask: {
            amount: "",
            unit: ""
        },
        reqiredField: {
            bankAccount: {numeric: true},
            trackingNumber: {},
            payDate: {},
            amount: {},
            unit: {},
            rights: {}
        },
        _moment: moment(),
        isNull: false,
        errors: {},
        inPrecessBuy: false,
        files: [],
        haveContent: false
    };


    handleChangeDate = (rawMoment) => {
        const newMoment = moment(rawMoment).format();
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                payDate: newMoment
            },
            _moment: rawMoment,
            isNull: false
        }));
    };

    handleChangeSelect = (info, name) => {
        let val = null;
        let fields = this.state.fields;
        if (info !== null) {
            val = info.value
        }
        fields[name] = val;
        this.setState({fields});
    };

    handleChangeInput = (event) => {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    };

    calcUnit2Amount = (value, name) => {
        if (value === "0" || value === 0 || value === "0 واحد") {
            value = "";
        }
        let fields = this.state.fields;
        let mask = this.state.mask;
        const {fundDetail} = this.props;
        const sellPrice = parseInt(fundDetail.fund.navSale);
        const dailyConst = parseFloat(fundDetail.fund.statisticSimpleDaily);
        let finalPrice = 0;
        if (name === 'unit') {
            if (value === "") {
                fields['unit'] = "";
                fields['amount'] = "";
                mask['amount'] = "";
                mask['unit'] = "";
            } else {
                value = value.replace(" واحد", "");
                value = remSep(value);
                finalPrice = ((sellPrice * dailyConst) + sellPrice) * parseInt(value);
                fields['unit'] = value;
                fields['amount'] = Math.ceil(finalPrice).toString();
                mask['amount'] = Math.ceil(finalPrice);
            }

        } else if (name === 'amount') {
            if (value === "") {
                fields['amount'] = "";
                fields['unit'] = "";
                mask['amount'] = "";
                mask['unit'] = "";
            } else {
                value = value.replace(" ریال", "");
                let pureVal = remSep(value);
                finalPrice = (parseInt(remSep(value)) / (sellPrice + (sellPrice * dailyConst)));
                finalPrice = Math.floor(finalPrice);
                fields['amount'] = pureVal;
                fields['unit'] = finalPrice.toString();
                mask['unit'] = finalPrice;
            }

        }
        fields[name] = remSep(value);
        mask[name] = value;
        this.setState({fields, mask});
    };

    handleRightsAccept = () => {
        let fields = this.state.fields;
        fields['rights'] = fields['rights'] === null ? "check" : null;
        this.setState({
            fields
        })
    };

    checkValidationForm(callback) {
        let {fields, reqiredField} = this.state;
        let errors = {};
        let formIsValid = true;

        for (let fieldIndex in reqiredField) {
            const validUnit = checkValidation(fields[fieldIndex] === null ? '' : fields[fieldIndex], reqiredField[fieldIndex]);
            if (!validUnit) {
                errors[fieldIndex] = _error.isRequire;
            }
            formIsValid = formIsValid && validUnit;
        }
        this.setState({errors}, () => {
            return callback(formIsValid);
        });

    }

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inPrecessBuy: true})
        } else {
            this.setState({inPrecessBuy: false})
        }
    }

    handleSubmitBuyUnit(e) {
        e.preventDefault();
        this.handleLoading();
        let {fields, haveContent, files} = this.state;
        const {fundDetail} = this.props;
        const profileStatus = personalInfoStatusForIssuing();
        this.checkValidationForm(sts => {
            if (sts) {
                if (profileStatus.status) {
                    setBuyFundWithBankAccount(fields, fundDetail.fund.registerNumber)
                        .then(res => {
                            console.log(res);
                            if (res.data.isSuccess) {
                                let currentOrders = this.props.orders;
                                currentOrders.push(res.data.order);
                                if (haveContent) {
                                    uploadBuyPaymentDocument(files, res.data.order.id)
                                        .then(attach => {
                                            if (!attach.data.isSuccess) {
                                                res.data.exceptions.map(exp => {
                                                    let msg = exp.persianDescription;
                                                    toast.error(msg);
                                                });
                                            }
                                        })
                                        .catch(err => {
                                            console.log(err);
                                        })
                                }
                                toast.success(_success.successSetOrder);
                                setStore({
                                    orders: currentOrders,
                                    orderUpdatedFlag: true,
                                    buyFundModal: false,
                                    successFundBuyReceiptModal: true,
                                    newOrder: res.data.order
                                });

                            } else {
                                let msg = res.data.exceptions[0];
                                msg = msg.exception.persianDescription;
                                toast.error(msg);
                            }
                            this.handleLoading(false);
                        })
                        .catch(err => {
                            console.log(err);
                            toast.error(_error.SystemError + "در صدور");
                            this.handleLoading(false);
                        })
                } else {
                    toast.error(profileStatus.errors[0]);
                }
            } else {
                this.handleLoading(false);
            }
        });
    };

    handleUploadModal = () => {
        setStore({UploadFundBuyPaymentModal: true});
    };

    handleSetPaymentDocumentMain = (files) => {
        this.setState({files: files, haveContent: true});
    };


    render() {
        const {fundBankAccountList, fundDetail, inProcess, fundStatus} = this.props;
        const {bankAccount, payDate} = this.state.fields;
        const {errors, _moment, isNull, fields, mask} = this.state;
        const value = !isNull && _moment ? persianDigit(_moment.format("jYYYY/jMM/jDD")) : "";
        const timeStatus = checkTimeStatus(this.props.timeInfo);
        const svgSource = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="70px" height="70px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B69F33;}
</style>
<g>
	<g>
	<polygon class="tp-1 sp-1 st0" points="47.5,32 35.4,25.1 35.4,39 47.5,46 		"/>
	<polygon class="tp-2 sp-1 st0" points="64.5,39.1 64.5,25 52.5,32 52.4,46 		"/>
	<polygon class="tp-3 st0 sp-2" points="35.4,5.4 35.4,19.4 47.6,26.5 47.6,12.5 		"/>
	<polygon class="tp-4 st0 sp-2" points="64.5,5.4 52.3,12.5 52.3,26.6 64.5,19.5 		"/>
	<polygon class="tp-5 sp-1 st0" points="66.9,43.2 54.7,50.2 66.9,57.2 79,50.2 		"/>
	<polygon class="tp-6 st0 sp-2" points="81.4,15.2 69.2,22.2 69.2,36.4 81.4,29.4 		"/>
	<polygon class="tp-7 st0 sp-2" points="71.6,40.4 83.8,47.6 96,40.5 83.8,33.4 		"/>
	<polygon class="tp-8 sp-1 st0" points="64.5,75.3 64.5,61.4 52.4,54.4 52.4,68.3 		"/>
	<polygon class="tp-9 st0 sp-2" points="71.5,60 83.7,67.1 96,60.1 83.8,53 		"/>
	<polygon class="tp-10 st0 sp-2" points="69.2,78.3 81.3,85.3 81.3,71.1 69.2,64.1 		"/>
	<polygon class="tp-11 sp-1 st0" points="35.4,75.4 47.5,68.4 47.5,54.3 35.4,61.3 		"/>
	<polygon class="tp-12 st0 sp-2" points="52.3,73.8 52.3,87.9 64.5,94.9 64.5,81 		"/>
	<polygon class="tp-13 st0 sp-2" points="35.4,94.9 47.6,87.9 47.6,73.8 35.4,80.8 		"/>
	<polygon class="tp-14 sp-1 st0" points="33,43.2 20.8,50.1 33,57.2 45.1,50.2 		"/>
	<polygon class="tp-15 st0 sp-2" points="18.5,85.2 30.7,78.1 30.7,64 18.5,71 		"/>
	<polygon class="tp-16 st0 sp-2" points="19.4,54.7 16.1,52.8 3.9,59.9 16.1,67 28.4,59.9 		"/>
	<polygon class="tp-17 st0 sp-2" points="19.4,45.5 28.4,40.4 16.2,33.3 3.9,40.3 16.1,47.4 		"/>
	<polygon class="tp-18 st0 sp-2" points="30.6,22.1 18.5,15.1 18.5,29.2 30.6,36.3 		"/>
		
	</g>
</g>
</svg>`;

        if (inProcess) {
            return (
                <div className="row px-4">
                    <div className="col-md-12 p-3 text-center">
                        <InlineSVG src={svgSource}/>
                        <p className="text-center mt-2">لطفا کمی صبر کنید...</p>
                    </div>
                </div>
            )
        } else {
            const bankAccountList = prepareSelectData('fundBankAccountsList', fundBankAccountList);
            return (
                <form className="w-100" onSubmit={this.handleSubmitBuyUnit.bind(this)}>
                    <div className="fund-buy-account">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="bankAccount" className="account-label">شماره حساب بانکی صندوق</label>
                                <Select
                                    className={["select-search ", errors['bankAccount'] ? 'is-invalid' : ''].join('')}
                                    value={bankAccount}
                                    onChange={(info) => this.handleChangeSelect(info, 'bankAccount')}
                                    options={bankAccountList}
                                    name="bankAccount"
                                    isSearchable
                                    placeholder="شماره حساب واریزی"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="reagent"></label>
                                <input type="text" name="reagent" className="form-control"
                                       onChange={this.handleChangeInput.bind(this)}
                                       placeholder="معرف"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <CurrencyInput
                                    className={["form-control ", errors['amount'] ? 'is-invalid' : ''].join('')}
                                    value={mask.amount}
                                    name="amount"
                                    inputType="text"
                                    onChange={(input) => {
                                        this.calcUnit2Amount(input, "amount");
                                    }}
                                    placeholder="مبلغ معادل (ريال)"
                                    suffix=" ریال"
                                    precision="0"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="loginDescription">
                                    با توجه به بروز نبودن قیمت صدور تعداد واحد های شما برابر یا کمتر از این تعداد خواهد
                                    بود
                                </Tooltip>}>
                                    <CurrencyInput
                                        className={["form-control ", errors['unit'] ? 'is-invalid' : ''].join('')}
                                        inputType="text"
                                        value={mask.unit}
                                        allowEmpty={false}
                                        name="unit"
                                        onChange={(input) => {
                                            this.calcUnit2Amount(input, "unit");
                                        }}
                                        placeholder="تعداد ابطال"
                                        suffix=" واحد"
                                        precision="0"
                                    />
                                </OverlayTrigger>

                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <input type="text" name="trackingNumber"
                                       className={["form-control ", errors['trackingNumber'] ? 'is-invalid' : ''].join('')}
                                       onChange={this.handleChangeInput.bind(this)}
                                       placeholder="شماره فیش واریزی"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <DatetimePickerTrigger
                                    moment={_moment}
                                    onChange={this.handleChangeDate}
                                    isSolar={true}
                                    lang="fa"
                                    showTimePicker={false}>
                                    <input
                                        id="payDate"
                                        className={["form-control date-input ", errors['payDate'] ? 'is-invalid' : ''].join('')}
                                        type="text"
                                        name="payDate"
                                        placeholder="تاریخ واریز"
                                        value={value} readOnly/>
                                </DatetimePickerTrigger>

                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                            <textarea name="description" className="form-control" rows="4"
                                      onChange={this.handleChangeInput.bind(this)}
                                      placeholder="توضیحات"
                            />
                            </div>
                            <div className="form-group col-md-6">
                                <button type="button" className="btn btn-upload w-100" onClick={this.handleUploadModal}>
                                    تصویر فیش واریزی
                                </button>
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <p
                                    className={["rights", fields.rights === null ? "" : "active", errors['rights'] && fields.rights === null ? "invalid" : ""].join(' ')}
                                >
                                    <span className="choose" onClick={this.handleRightsAccept}>
                                        <i className="fa fa-circle"/>
                                        قوانین و مقررات را می پذیرم
                                    </span>


                                </p>
                                {
                                    fundStatus ? (
                                        timeStatus ? (
                                            <p className="rights necessary">در صورت ثبت درخواست بعد از
                                                ساعت 16
                                                درخواست در روز کاری بعد بررسی خواهد
                                                شد.</p>
                                        ) : (
                                            <p className="rights necessary"/>
                                        )
                                    ) : (
                                        <p className="rights necessary">
                                            وضعیت بازار فعال نمی باشد!
                                        </p>
                                    )

                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 w-100"/>
                        <div className="col-md-6 text-right my-3 action-area">
                            <button type="submit" className="btn btn-buy btn-lg w-100"
                                    disabled={this.state.inPrecessBuy || !fundStatus}
                            >
                                <img className="icon" src="img/admin/icon/fund-buy.svg"
                                     alt=""/>
                                صدور
                                <span>{convertCurrencyTpye(fundDetail.fund.navPurchase)}</span>
                            </button>
                        </div>
                    </div>

                    <UploadFundBuyPayment
                        setDocument={this.handleSetPaymentDocumentMain.bind(this)}
                        haveContent={this.state.haveContent}
                        fileContent={this.state.files}
                    />
                    {/*<SuccessFundBuyModal order={this.state}/>*/}
                    <FundBuyReceiptModal/>
                </form>
            );
        }
    }
}

const mstp = state => ({
    orders: state.orders,
    timeInfo: state.timeInfo,
    fundStatus: state.fundStatus,
    UploadFundBuyPaymentModal: state.UploadFundBuyPaymentModal
});

export default connect(mstp)(AccountPaymentTabBuyFundModal);