import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {reGenerate, setBackgroundDefault, verificationMobile} from "../../../services/login";
import {connect, setStore} from 'trim-redux';
import validator from "validator";
import _error from "../../../assets/helper/errorMsgHelper";
import {toast} from "react-toastify";

class RegisterStep3 extends Component {

    constructor(props) {
        super(props);
        if (this.props.step !== 3 || this.props.mobileSubmit === '') {
            this.props.history.replace('/register');
        }
    }

    state = {
        fields: {
            code: '',
            expirationDate: '',
            verificationId: ''

        },
        count: 0,
        errors: {},
        captchaURL: '',
        showRegenerateCode: false,
        inProcess: false
    };

    componentDidMount() {
        setBackgroundDefault("bg1");
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                verificationId: this.props.mobileSubmit.id,
                expirationDate: this.props.mobileSubmit.expirationDate
            },
            count: this.props.mobileSubmit.lifeSpanSeconds
        }));
        this.handleCountDown();
    }

    handleCountDown() {
        let timer = setInterval(() => {
            if (this.state.count > 0) {
                this.setState({count: this.state.count - 1});
            } else {
                this.setState({showRegenerateCode: true});
                clearInterval(timer);
            }
        }, 1000);
    }

    handleCountDownStop() {
        const {count} = this.state;
    }

    handleChange(event) {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    }

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inProcess: true})
        } else {
            this.setState({inProcess: false})
        }
    }

    checkValidation(callback) {
        let {fields} = this.state;
        let errors = {};
        let formIsValid = true;

        // username validate
        if (validator.isEmpty(fields.code)) {
            formIsValid = false;
            errors['code'] = _error.emptyCode;
        } else if (!validator.isLength(fields.code, {min: 5, ma: 5})) {
            formIsValid = false;
            errors['code'] = _error.incorrectCode;
        }

        this.setState({errors}, () => {
            return callback(formIsValid)
        });

    }

    makeRequest(data) {
        verificationMobile(data)
            .then(res => {
                if (res.success) {
                    toast.success(res.msg);
                    this.props.history.replace('/login');
                } else {
                    this.handleLoading(false);
                    toast.error(res.msg);
                }
            })
            .catch(() => {
                this.handleLoading(false);
                toast.error(_error.requestError);
            });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.handleLoading();
        let {fields} = this.state;
        this.checkValidation(sts => {
            if (sts) {
                this.makeRequest(fields)
            } else {
                this.handleLoading(false);
            }

        });
    }

    handlereGenerate(e) {
        e.preventDefault();
        reGenerate(this.state.fields)
            .then(response => {
                if (response.newCode) {
                    setStore({mobileSubmit: response.data});
                    toast.success(response.msg);
                    this.setState(prevState => ({
                        fields: {
                            ...prevState.fields,
                            verificationId: response.data.id,
                            expirationDate: response.data.expirationDate
                        },
                        count: response.data.lifeSpanSeconds,
                        showRegenerateCode: false
                    }));
                    this.handleCountDown();
                } else {
                    toast.error(response.msg);
                    this.setState(prevState => ({
                        fields: {
                            ...prevState.fields,
                            code: ''
                        }
                    }));
                }
            })
            .catch(() => {
                toast.error(_error.SystemError);
            })
    }


    render() {
        const {errors} = this.state;
        return (
            <div>
                <section className="rtl bg-theme-color-1 py-5" style={{height: 100 + "vh"}}>
                    <div className="container">
                        <div className="row">
                            <form data-aos="fade-right" onSubmit={this.handleSubmit.bind(this)}
                                  className="form-signin d-block m-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to="/">
                                            <img className="logo d-block m-auto mb-5" src="img/logo-wh.png"
                                                 alt="Etebar logo"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <img className="icon d-block m-auto mb-4 pb-4" src="img/reg-icon.svg"
                                             alt="Etebar logo"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <input type="text"
                                               className={["form-control ", errors['code'] ? 'is-invalid' : ''].join('')}
                                               name="code"
                                               value={this.state.fields.code}
                                               onChange={this.handleChange.bind(this)}
                                               placeholder="کد فعال سازی"
                                               autoComplete="off"/>
                                    </div>
                                </div>

                                <div className="row pt-2" style={{minHeight: 80}}>
                                    <div className="col-12">
                                        <p className="error-area"
                                           style={{display: errors["code"] ? 'block' : 'none'}}>
                                            <i className="fa fa-times"></i>
                                            {errors["code"]}
                                        </p>
                                    </div>
                                </div>

                                <div className="row"
                                     style={{display: this.state.showRegenerateCode ? "none" : "block"}}>
                                    <div className="col-md-12 forget-area">
                                        <p className="text-white">
                                            کد فعال سازی تا
                                            <span> {this.state.count} </span>
                                            ثانیه معتبر می باشد
                                        </p>
                                    </div>
                                </div>
                                <div className="row"
                                     style={{display: this.state.showRegenerateCode ? "block" : "none"}}>
                                    <div className="col-md-12 forget-area recaptcha">
                                        <p className="mt-4">
                                            کد فعال سازی دریافت نکرده اید؟ |

                                            <span onClick={this.handlereGenerate.bind(this)}>
                                                <span className="text-white bold"> ارسال مجدد کد فعال سازی </span>
                                            </span>
                                        </p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-2 w-100"></div>
                                    <div className="col-8">
                                        <button disabled={this.state.inProcess}
                                                className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                                            {this.state.inProcess ? 'لطفا صبر کنید...' : 'ادامه'}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="row">

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mstp = state => ({
    step: state.step,
    NationalCode: state.nationalCode,
    mobileSubmit: state.mobileSubmit
});


export default connect(mstp)(RegisterStep3);