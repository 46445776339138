import React, {Component} from 'react';

class TopSlide2 extends Component {

    render() {
        return (
            <section className="py-5 rtl bg-theme-color-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 w-100"></div>
                        <div className="col-md-4 col-xs-12">
                            <div data-aos="fade-up" data-aos-duration="1000">
                                <img className="d-block m-auto" src="img/slide-2.svg" width="200" height="200" alt=""/>
                            </div>
                        </div>
                        <div className="col-md-2 w-100"></div>
                        <div className="col-md-5 col-xs-12">
                            <div className="slideProperty mt-4 pt-2">
                                <ul className="list-group list-group-flush text-white pl-0">
                                    <li>
                                        <h5>
                                            <i className="fa fa-circle"></i>
                                            متن اعتماد ساز برای کاربران 1
                                        </h5>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="fa fa-circle"></i>
                                            متن اعتماد ساز برای کاربران 2
                                        </h5>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="fa fa-circle"></i>
                                            متن اعتماد ساز برای کاربران 3
                                        </h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-1 w-100"></div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TopSlide2;