import React, {Component} from 'react';

class FundEficientTable extends Component {
    render() {
        const {fundDetail} = this.props;
        const fundInfo = fundDetail.fund;
        return (
            <div>
                <table className="table table-bordered rtl">
                    <thead>
                    <tr>
                        <th>روزانه</th>
                        <th>ماهانه</th>
                        <th>سه ماهه</th>
                        <th>سالانه</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <div className="inner">
                                %{fundInfo.statisticSimpleDaily}
                            </div>
                        </td>
                        <td>%{fundInfo.statisticSimpleMonthly}</td>
                        <td>%{fundInfo.statisticSimpleMonthly3}</td>
                        <td>%{fundInfo.statisticSimpleYearly}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        );
    }
}

export default FundEficientTable;