import React, {Component} from 'react';
import {Dropdown} from "react-bootstrap";
import {setStore, connect} from "trim-redux";
import {Link} from "react-router-dom";
import {
    checkInstantPasswordChange, getBaseListInfo,
    getLocalStorage,
    getTimeInfo, getUserProfileInfo,
    persianDigit,
    setBackgroundDefault,
    newMsgCount, getLogOut, personalInfoStatus, personalprofileStatus
} from "../../services/dashboard";
import ChangePasswordModal from "../page/admin/modal/changePasswordModal";
import UserProfileModal from "../page/admin/modal/userProfileModal";
import LogoutModal from "../page/admin/modal/logoutModal";
import _error from './../../assets/helper/errorMsgHelper.json'
import moment from 'moment-jalaali';
import Clock from 'react-live-clock';
import {toast} from "react-toastify";
import {getMessages, getMessagesCount} from "../../services/fund";
import _success from "../../assets/helper/successMsgHelper";
import {clearUserInfoLoginAgain} from "../../services/login";

class AdminHeader extends Component {

    state = {
        dropdownToggle: false,
        showUserProfile: false,
        currentDate: '',
        resiveDate: false,
        listBasicInfo: [],
        profileInfo: [],
        inProcessStaticInfo: true,
        inProcessUserInfo: true,
        newMsgCount: 0,
        informationStatus: {},
        userProfileStatus: false
    };

    handleLeftMenu(e) {
        e.preventDefault();
        const {showLeftMenu} = this.props;
        setStore({showLeftMenu: !showLeftMenu});
    }

    static handleChangePasswordModal(e) {
        e.preventDefault();
        setStore({userProfileModal: true});
    }

    static handleProfileModal(e) {
        e.preventDefault();
        getBaseListInfo()
            .then(response => {
                if (response.data.isSuccess) {
                    delete response.data.isSuccess;
                    delete response.data.id;
                    delete response.data.exceptions;
                    this.setState({listBasicInfo: response.data, inProcessStaticInfo: false});
                } else {
                    toast.error(_error.SystemError);
                }
            })
            .catch(() => {
                toast.error(_error.requestError);
            });
        getUserProfileInfo()
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({profileInfo: res.data, inProcessUserInfo: false});
                } else {
                    toast.error(_error.SystemError)
                }
            })
            .catch(() => {
                toast.error(_error.SystemError)
            });
        setStore({mainUserProfile: true});
    }

    handleLogout = () => {
        getLogOut()
            .then(res => {
                toast.success(_success.logout);
                setStore({auth: false});
                clearUserInfoLoginAgain();
            })
            .catch((err) => {
                console.log(err);
            });
    };


    componentDidMount() {
        setBackgroundDefault();
        getTimeInfo()
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({currentDate: res.data.serverCurrentTime, ResiveDate: true});
                    setStore({timeInfo: res.data.serverCurrentTime});
                } else {
                    toast.error(_error.SystemError);
                }
            })
            .catch(err => {
                toast.error(_error.SystemError);
            });
        const instPass = checkInstantPasswordChange();
        if (instPass) {
            setStore({userProfileModal: true, instantPasswordChange: true});
        } else {
            setStore({userProfileModal: false, instantPasswordChange: false});
        }
        this.getMsgStatus();
        const userProfileStatus = personalprofileStatus();
        this.setState({userProfileStatus});
    }

    getMsgStatus = () => {
        getMessagesCount().then(res => {
            if (res.data.isSuccess) {
                this.setState({newMsgCount: res.data.unreadCount});
            }
        }).catch(err => {
            console.log(err)
        });
    };

    updateInfo = (state, info, type = '') => {
        let profileInfo = this.state.profileInfo;
        if (type === 'push') {
            profileInfo[state].push(info);
        } else if (type === 'append') {
            for (let row in info) {
                profileInfo[state][row] = info[row];
            }
        } else {
            profileInfo[state] = info;
        }
        this.setState({profileInfo});
    };

    render() {
        const localProfileInfo = getLocalStorage("user");
        const {listBasicInfo, profileInfo, inProcessStaticInfo, inProcessUserInfo} = this.state;
        return (
            <section className="rtl top-menu admin-theme-bg-color-1 border-bottom fixed-top p-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            <nav className="navbar flex-md-nowrap py-0 pr-0">

                                <div className="col-sm-3 px-3 col-3">
                                    <a className="navbar-brand mr-0">
                                        <img src="/img/admin/logo-admin.svg" width="35" height="auto" alt=""/>
                                    </a>
                                </div>
                                <div className="col-md-5 col-9 px-1">
                                    <ul className="list-group list-group-horizontal notifi-bar p-0">
                                        <li className="list-group-item p-0 d-none d-sm-block">
                                            <p className="clock">
                                                <img className="icon" src="/img/admin/icon/clock.svg" alt=""/>
                                                {

                                                    this.state.ResiveDate ? (
                                                        <Clock format={'hh:mm:ss'} date={(this.state.currentDate)}
                                                               ticking={true}/>) : '-'
                                                }
                                            </p>
                                        </li>
                                        <li className="list-group-item p-0 d-none d-sm-block">
                                            <p className="calender">
                                                <img className="icon" src="/img/admin/icon/date.svg" alt=""/>
                                                {
                                                    this.state.ResiveDate ? (moment(this.state.currentDate).format('jYY/jM/jD')) : '-'
                                                }
                                            </p>
                                        </li>
                                        {/*<li className="list-group-item p-0 notification">*/}
                                        {/*<Dropdown>*/}
                                        {/*<Dropdown.Toggle variant="" id="dropdown-basic" className="">*/}
                                        {/*<img src="/img/admin/icon/notifi.svg"*/}
                                        {/*width="23" height="auto" alt="user"/>*/}
                                        {/*<span className="badge badge-danger">{persianDigit("2")}</span>*/}
                                        {/*</Dropdown.Toggle>*/}

                                        {/*<Dropdown.Menu className="p-0">*/}
                                        {/*<Dropdown.Item href="#" className="active">*/}
                                        {/*احراز هویت*/}
                                        {/*<span className="info">*/}
                                        {/*<span className="date">دوشنبه 23 اسفند</span>*/}
                                        {/*<br/>*/}
                                        {/*<span className="time">22:43</span>*/}
                                        {/*</span>*/}
                                        {/*</Dropdown.Item>*/}
                                        {/*<Dropdown.Item href="#" className="">*/}
                                        {/*تماس با کاربر*/}
                                        {/*<span className="info">*/}
                                        {/*<span className="date">دوشنبه 23 اسفند</span>*/}
                                        {/*<br/>*/}
                                        {/*<span className="time">22:43</span>*/}
                                        {/*</span>*/}
                                        {/*</Dropdown.Item>*/}
                                        {/*<Dropdown.Item href="#" className="">*/}
                                        {/*تماس با کاربر*/}
                                        {/*<span className="info">*/}
                                        {/*<span className="date">چهارشنبه 23 اردیبهشت</span>*/}
                                        {/*<br/>*/}
                                        {/*<span className="time">21:00</span>*/}
                                        {/*</span>*/}
                                        {/*</Dropdown.Item>*/}
                                        {/*<Dropdown.Item href="#" className="">*/}
                                        {/*تماس با کاربر*/}
                                        {/*<span className="info">*/}
                                        {/*<span className="date">چهارشنبه 23 اردیبهشت</span>*/}
                                        {/*<br/>*/}
                                        {/*<span className="time">21:00</span>*/}
                                        {/*</span>*/}
                                        {/*</Dropdown.Item>*/}
                                        {/*<a className="text-left more px-2 py-1" href="#">*/}
                                        {/*<span className="text-muted">بیشتر</span>*/}
                                        {/*</a>*/}
                                        {/*</Dropdown.Menu>*/}
                                        {/*</Dropdown>*/}
                                        {/*</li>*/}
                                        <li className="list-group-item p-0">
                                            <Link to="/message"/>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic" className="">
                                                    <Link to="/message">
                                                        <img src="/img/admin/icon/envlop.svg"
                                                             width="25" height="auto" alt="user"/>
                                                        {
                                                            this.state.newMsgCount !== 0 ? (
                                                                <span
                                                                    className="badge badge-danger">{persianDigit((this.state.newMsgCount).toString())}</span>
                                                            ) : ""
                                                        }
                                                    </Link>
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </li>
                                        <li className="list-group-item p-0">
                                            <div className="media">
                                                <div className="media-body">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="" id="dropdown-basic"
                                                                         className="btn-header-user">
                                                            {
                                                                localProfileInfo.userTitle !== "-" ? (localProfileInfo.userTitle) : "کاربر عزیز"
                                                            }
                                                            <img
                                                                className={["user-circle-img", this.state.userProfileStatus ? "active" : "not-complete"].join(" ")}
                                                                src={localProfileInfo.profileImage !== "" ? localProfileInfo.profileImage : "/img/admin/icon/user.svg"}
                                                                width="27" height="auto" alt="user"/>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="p-0">
                                                            <Dropdown.Item
                                                                onClick={AdminHeader.handleProfileModal.bind(this)}>
                                                                <img className="icon" src="/img/admin/icon/profile.svg"
                                                                     alt=""/>
                                                                پروفایل من
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={AdminHeader.handleChangePasswordModal.bind(this)}>
                                                                <img className="icon" src="/img/admin/icon/account.svg"
                                                                     alt=""/>
                                                                حساب کاربری
                                                            </Dropdown.Item>
                                                            <Link className="dropdown-item" role="button" to="/login"
                                                                  onClick={() => {
                                                                      this.handleLogout()
                                                                  }}>
                                                                <img className="icon" src="/img/admin/icon/logout.svg"
                                                                     alt=""/>
                                                                خروج
                                                            </Link>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item p-0 px-1">
                                            <a className="navbar-brand mr-0 p-2 text-right d-block"
                                               onClick={this.handleLeftMenu.bind(this)}>
                                                <img src="/img/admin/icon/menu-report.svg" width="25" height="auto"
                                                     alt=""/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>

                    </div>
                </div>
                <UserProfileModal
                    listBasicInfo={listBasicInfo}
                    profileInfo={profileInfo}
                    inProcessStaticInfo={inProcessStaticInfo}
                    inProcessUserInfo={inProcessUserInfo}
                    updateInfo={this.updateInfo}
                />
                <ChangePasswordModal/>
                <LogoutModal/>
            </section>

        );
    }
}


const mstp = state => ({
    showLeftMenu: state.showLeftMenu,
    mainUserProfile: state.mainUserProfile,
    userProfileModal: state.userProfileModal,
    instantPasswordChange: state.instantPasswordChange,
    systemDate: state.systemDate,
    profileInfo: state.profileInfo
});
export default connect(mstp)(AdminHeader);