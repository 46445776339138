import React, {Component} from 'react';
import {Element} from "react-scroll/modules";

class Property extends Component {
    render() {
        return (
            <section className="property pt-5 pb-4 bg-theme-color-2 text-white">
                <div className="rtl container">
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="card">
                                <img className="card-img-top px-5 d-block m-auto" src="/img/pro-1.svg"
                                     alt="property"/>
                                <div className="card-body pt-2">
                                    <p className="card-text text-center">
                                        تحت نظارت سازمان
                                        <br/>
                                        بورس و اوراق بهـادار
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="card">
                                <img className="card-img-top px-5 d-block m-auto" src="/img/pro-2.svg"
                                     alt="property"/>
                                <div className="card-body pt-2">
                                    <p className="card-text text-center">
                                        تحت نظر بانک سامان
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="card">
                                <img className="card-img-top px-5 d-block m-auto" src="/img/pro-3.svg"
                                     alt="property"/>
                                <div className="card-body pt-2">
                                    <p className="card-text text-center">
                                        نرخ رشد منظم
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="card">
                                <img className="card-img-top px-5 d-block m-auto" src="/img/pro-4.svg"
                                     alt="property"/>
                                <div className="card-body pt-2">
                                    <p className="card-text text-center">
                                        واریز سود بصورت آنلاین
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        );
    }
}

export default Property;