import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Element} from "react-scroll/modules";

class VideoCenter extends Component {
    state = {
        video: [
            {id: 1, img: "/img/video1.jpg", title: "معرفی صندوق اعتبار", tag: "آموزشی", link: "video"},
            {id: 2, img: "/img/video2.jpg", title: "نحوه ی ثبت نام در صندوق", tag: "آموزشی", link: "video"},
            {id: 3, img: "/img/video3.jpg", title: "معرفی صندوق اعتبار", tag: "برند", link: "video"}
        ]
    };

    render() {
        return (
            <Element name="tutorial" className="element">
                <section className="rtl mt-5 py-5">
                    <div className="container">
                        <div className="row">
                            {
                                this.state.video.map(videoUnit => {
                                    return (
                                        <div className="col-md-4" key={videoUnit.id}>
                                            <div className="video-unit card border-0 mb-4">
                                                <Link to="/">
                                                    <div className="overlay">
                                                        <div className="obj-overlay">
                                                            <img src="img/icon/play.svg" alt=""/>
                                                        </div>
                                                    </div>
                                                    <img src={videoUnit.img} className="card-img-top" alt="video"/>
                                                </Link>
                                                <div className="card-body px-0 pl-2 theme-color-1">
                                                    <h5 className="card-title">
                                                        <Link className="theme-color-1" to="/">{videoUnit.title}</Link>
                                                    </h5>
                                                    <p className="card-text">
                                                        <small className="text-muted">#{videoUnit.tag}</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <div className="col-md-4">

                            </div>

                        </div>
                    </div>
                </section>
            </Element>
        );
    }
}

export default VideoCenter;