import React, {Component} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Orders from './reports/orders';
import Exports from './reports/exports';
import Logs from './reports/logs';
import Transactions from './reports/transactions';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {type: this.props.match.params.type}
    }


    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({type: nextProps.match.params.type});
    }

    componentDidMount() {
    }

    render() {
        let {type} = this.state;
        return (
            <section className="user-panel main-body height100vh">
                {
                    (type === 'orders') ? <Orders/> : (type === 'certs') ? <Exports/> : (type === 'logs') ?
                        <Logs/> : (type === 'transactions') ? <Transactions/> : ''
                }
            </section>
        );
    }
}

export default App;
