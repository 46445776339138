import React, {Component} from 'react';
import {convertCurrencyTpye, convertTimeFrameToDate, getOrdersList, pagingHandler} from "./../../../../services/fund";
import moment from 'moment-jalaali';
import msgs from './../../../../assets/helper/errorMsgHelper.json';
import {toast} from 'react-toastify';

import MyTbl from './../../../main/CustomTbl';
import {getTimeInfo, persianDigit} from "../../../../services/dashboard";
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';
import _default from "../../../../assets/helper/default";
import Pagination from "react-js-pagination";
import {connect, setStore} from "trim-redux";
import {licenseIssuanceHistories} from "../../../../services/fund";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logData: [],
            rows: [],
            fields: {
                fromDate: "",
                toDate: "",
                startId: 0,
                endId: 0,
                page: 1
            },
            date: {
                fromDate: "",
                toDate: ""
            },
            isNull: {
                fromDate: true,
                toDate: true
            },
            _moment: {
                fromDate: moment(),
                toDate: moment()
            },
            haveFilter: false,
            loadMore: false,
            currentPage: 1,
            init: true,
            count: 0
        }
    }

    componentDidMount() {
        const {fields} = this.state;
        if (this.props.timeInfo === "") {
            getTimeInfo()
                .then(res => {
                    if (res.data.isSuccess) {
                        setStore({timeInfo: res.data.serverCurrentTime});
                        this.componentInitialInfo(res.data.serverCurrentTime);
                        this.getAllCertList(fields);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            this.componentInitialInfo(this.props.timeInfo);
            this.getAllCertList(fields);
        }
    }

    getAllCertList = (info) => {
        const {currentPage} = this.state;
        let {fields, init} = this.state;

        licenseIssuanceHistories(info).then(res => {
            if (res.data.isSuccess) {
                this.setState({logData: res.data.orders});
                if (init) {
                    const newPageInfo = pagingHandler(res.data.count, currentPage);
                    fields['startId'] = newPageInfo.start;
                    fields['endId'] = newPageInfo.end;
                }
                let rows = [];
                let counter = fields.startId + 1;
                const info = res.data.orders;
                for (let i = 0; i < res.data.orders.length; i++, counter++) {
                    let x = [
                        persianDigit(counter.toString()),
                        persianDigit(info[i].id.toString()),
                        info[i].fundAbbreviation,
                        "در سهام",
                        persianDigit(moment(info[i].entryDate).format('jYYYY/jM/jD - HH:mm')),
                        convertCurrencyTpye(info[i].issuingRequestAmount),
                        convertCurrencyTpye(info[i].quantity),
                        convertCurrencyTpye(info[i].price),
                        info[i].orderStatusTitle
                    ];
                    rows.push(x);
                }
                this.setState({
                    rows,
                    fields,
                    haveFilter: false,
                    count: res.data.count,
                    init: false
                });
            }

        }).catch(err => {
            console.log(err)
        });
    };

    handleChange = (rawMoment, name) => {
        const newMoment = moment(rawMoment).format();
        let fields = this.state.fields;
        let date = this.state.date;
        let isNull = this.state.isNull;
        let _moment = this.state._moment;

        date[name] = newMoment;
        isNull[name] = false;
        _moment[name] = rawMoment;
        this.setState({
            date,
            isNull,
            _moment
        });
    };

    componentInitialInfo = (date) => {
        let fields = this.state.fields;
        let filterDate = this.state.date;
        let _moment = this.state._moment;
        let isNull = this.state.isNull;
        fields['fromDate'] = convertTimeFrameToDate(date, 1);
        fields['toDate'] = date;
        filterDate['fromDate'] = convertTimeFrameToDate(date, 1);
        filterDate['toDate'] = date;
        _moment['fromDate'] = moment(fields['fromDate']);
        _moment['toDate'] = moment(date);
        isNull['fromDate'] = false;
        isNull['toDate'] = false;
        this.setState({fields, haveFilter: true, date: filterDate, _moment, isNull});
    };

    handleFilter = () => {
        this.handlePageReset();
        this.setState({haveFilter: true, init: true});
        this.getAllCertList(this.state.fields);
    };

    handlePageChange = (pageNumber) => {
        const paginationInfo = pagingHandler(this.state.count, pageNumber);
        let fields = this.state.fields;
        fields['startId'] = paginationInfo.start;
        fields['endId'] = paginationInfo.end;
        if (paginationInfo.loadMore) {
            fields['page'] = paginationInfo.currentPage
        }
        this.setState({fields, currentPage: pageNumber});
        this.getAllCertList(fields);
    };

    handlePageReset = () => {
        let fields = this.state.fields;
        let date = this.state.date;
        fields['fromDate'] = date['fromDate'];
        fields['toDate'] = date['toDate'];
        fields['startId'] = 0;
        fields['endId'] = 0;
        fields['page'] = 1;
        this.setState({fields, currentPage: 1, count: 0});
    };

    render() {
        let cols = [1, 2, 2, 2, 2, 2, 2, 2, 2];
        let head = ['ردیف', 'شماره سفارش', 'نام صندوق', 'نوع صندوق', 'زمان', 'ارزش', 'تعداد واحد', 'قیمت', 'وضعیت'];
        let body = this.state.rows;
        const {isNull, _moment} = this.state;
        const fromDate = !isNull.fromDate && _moment.fromDate ? persianDigit(_moment.fromDate.format("jYYYY/jMM/jDD")) : "";
        const toDate = !isNull.toDate && _moment.toDate ? persianDigit(_moment.toDate.format("jYYYY/jMM/jDD")) : "";

        return (
            <div className="table-wrapper rtl container-fluid height100 p-2">
                <div className="border-sea radius16 height100 padding8 overflow-hidden">
                    <div>
                        <i className="far fa-copy padding8"/>
                        <h3 className="inline-blocked text-small">لیست سفارشات</h3>
                    </div>
                    <div className="inputs padding8 filter-bar">
                        <div>
                            <input type="text" placeholder="نام صندوق"
                                   className="form-control"/>
                        </div>
                        <div>
                            <input type="text" placeholder="نوع صندوق"
                                   className="form-control"/>
                        </div>
                        <div>
                            <input type="text" placeholder="وضعیت سفارش"
                                   className="form-control"/>
                        </div>

                        <div>
                            <DatetimePickerTrigger
                                moment={_moment.fromDate}
                                onChange={(info) => {
                                    this.handleChange(info, "fromDate")
                                }}
                                isSolar={true}
                                lang="fa"
                                position="bottom"
                                closeOnSelectDay={true}
                                showTimePicker={false}>
                                <input
                                    name="fromDate"
                                    className="form-control p-1"
                                    type="text"
                                    value={fromDate}
                                    placeholder="از تاریخ"
                                    readOnly
                                />
                            </DatetimePickerTrigger>
                        </div>
                        <div>
                            <DatetimePickerTrigger
                                moment={_moment.toDate}
                                onChange={(info) => {
                                    this.handleChange(info, "toDate")
                                }}
                                isSolar={true}
                                lang="fa"
                                minDate={_moment.fromDate}
                                position="bottom"
                                closeOnSelectDay={true}
                                showTimePicker={false}>
                                <input
                                    name="toDate"
                                    className="form-control p-1"
                                    type="text"
                                    value={toDate}
                                    placeholder="تا تاریخ"
                                    readOnly
                                />
                            </DatetimePickerTrigger>
                        </div>
                        <div className="">
                            <a className="btn btn-primary btn-filter px-4"
                               onClick={this.handleFilter.bind(this)}
                            >اعمال</a>
                        </div>

                    </div>
                    <div className="col-md-12 report-area overflow-auto">
                        <MyTbl cols={cols} head={head} class={"bg-white"}>
                            {body}
                        </MyTbl>
                        <Pagination
                            prevPageText="قبلی"
                            nextPageText="بعدی"
                            hideFirstLastPages={true}
                            innerClass="flexed tbl-bottom-paging pagination bg-white mb-0"
                            activePage={this.state.currentPage}
                            itemsCountPerPage={_default.pagingSize}
                            totalItemsCount={this.state.count}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = state => ({
    timeInfo: state.timeInfo,
});

export default connect(mstp)(App);
