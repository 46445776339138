import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {connect, setStore} from "trim-redux";

class FundBuyReceiptModal extends Component {

    handleClose() {
        setStore({successFundBuyReceiptPreviewModal: false, orderReceiptInfo: ""});
    }

    render() {
        return (
            <>
                <Modal className="rtl modal-style" show={this.props.successFundBuyReceiptPreviewModal}
                       size="lg"
                       backdropClassName="multi-level-modal"
                       onHide={this.handleClose.bind(this)} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>رسید صدور</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-0 py-2">
                        <div className="container">
                            <div className="row px-5">
                                <div className="col-md-12">
                                    <div dangerouslySetInnerHTML={{__html: this.props.orderReceiptInfo}}/>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mstp = state => ({
    successFundBuyReceiptPreviewModal: state.successFundBuyReceiptPreviewModal,
    orderReceiptInfo: state.orderReceiptInfo
});
export default connect(mstp)(FundBuyReceiptModal);