export function makeAuthHeader(type = '') {
    let headerOption = {}
    if (type === '') {
        headerOption = {headers: {"needAuth": true}};
    } else if (type === 'ff') {
        headerOption = {headers: {"needAuth": true}}
    } else if (type === 'xlsx') {
        headerOption = {
            headers: {"needAuth": true},
            "responseType": "blob",
            'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    }

    return headerOption;
}

