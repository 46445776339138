import React, {Component} from 'react';
import {GetPersonalityTodayOrders} from "../../../services/dashboard";
import {
    cancelOrder,
    cancelOrderLocal,
    convertCurrencyTpye,
    getCustomerAssets, GetOrderReceiptByOrderId,
    orderHistory,
    SortInfo
} from "../../../services/fund";
import {toast} from "react-toastify";
import _error from "../../../assets/helper/errorMsgHelper";
import _default from './../../../assets/helper/default';
import {setStore, connect} from "trim-redux";
import {Link} from "react-router-dom";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class OrderBar extends Component {

    state = {
        inProcessDailyOrder: true,
        dailyOrder: [],
        inProcessCustomerAssets: true,
        assetsInfo: [],
        sumAssets: "0",
        hoverAction: false,
        hoverId: ''
    };

    componentDidMount() {
        GetPersonalityTodayOrders()
            .then(res => {
                if (res.data.isSuccess) {
                    setStore({orders: res.data.orders});
                    this.setState({dailyOrder: res.data.orders})
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    // toast.error(msg);
                }
            })
            .catch(err => {
                toast.error(_error.SystemError)
            });
        this.setState({
            assetsInfo: this.props.customerAssetInfo,
            inProcessCustomerAssets: this.props.inProcessCustomerAssets
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let info = nextProps.orders;
        if (nextProps.orderUpdatedFlag) {
            info = SortInfo(info);
            this.setState({
                dailyOrder: info
            });
            setStore({dailyOrder: false});
        }

    }

    handleSumAssets = (qty, unitPrice) => {
        let sum = parseInt(this.state.sumAssets);
        if (sum === "" || sum === 0) {
            sum = 0
        }
        sum = sum + parseInt(qty) * parseInt(unitPrice);
        return sum;

    };

    handleShowHoverAction = (id) => {
        this.setState({hoverAction: true, hoverId: id});
    };

    handleHideHoverAction = () => {
        this.setState({hoverAction: false, hoverId: ''});
    };

    handleCancelOrder = (orderId) => {
        confirmAlert({
            title: '',
            message: _default.confirmModalText,
            buttons: [
                {
                    label: _default.confirmModalCancelLabel,
                    onClick: () => {
                    }
                },
                {
                    label: _default.confirmedCancelOrder,
                    onClick: () => cancelOrder(orderId)
                        .then(res => {
                            if (res.data.isSuccess) {
                                const newList = cancelOrderLocal(this.props.orders, orderId);
                                setStore({orders: newList, orderUpdatedFlag: true});
                            } else {
                                let msg = res.data.exceptions[0];
                                msg = msg.exception.persianDescription;
                                toast.error(msg);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            ]
        });

    };

    handleOrderHistory = (orderId) => {
        let target = this.props.orders.filter(row => row.id === orderId);
        target = target[0];
        orderHistory(orderId)
            .then(res => {
                if (res.data.isSuccess) {
                    setStore({orderHistory: res.data.orderHistories, newOrder: target, orderHistoryModal: true});
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(err => console.log(err))
    };

    handleOrderReceiptInfo = (orderId) => {
        GetOrderReceiptByOrderId(orderId)
            .then(res => {
                if (res.data.isSuccess) {
                    setStore({orderReceiptInfo: res.data.htmlBody, successFundBuyReceiptPreviewModal: true})
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(err => console.log(err))
    };


    render() {
        const {dailyOrder} = this.state;
        const {customerAssetInfo, inProcessCustomerAssets} = this.props;
        this.sum = 0;

        return (
            <section className="rtl footer-order fixed-bottom order-bar px-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 px-2 d-none d-sm-block">
                            <div className="orders bg-white">
                                <h3>
                                    <img className="icon" width="20" src="img/admin/icon/order-report.svg" alt=""/>

                                    سفارش های امروز
                                    <Link to="/report/orders">
                                        <img className="icon left" width="20" src="img/admin/icon/history.svg" alt=""/>
                                    </Link>
                                </h3>
                                <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                    <table className="table table-striped order-table">
                                        <thead>
                                        <tr>
                                            <th scope="col">شماره سفارش</th>
                                            <th scope="col">نام صندوق</th>
                                            <th scope="col">ارزش</th>
                                            <th scope="col">تعداد واحد</th>
                                            <th scope="col">قیمت</th>
                                            <th scope="col">وضعیت</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {

                                            dailyOrder.length > 0 ?
                                                (
                                                    dailyOrder.map(ord => {
                                                        return (
                                                            <tr key={ord.id}
                                                                onMouseEnter={() => {
                                                                    this.handleShowHoverAction(ord.id)
                                                                }}
                                                                onMouseLeave={this.handleHideHoverAction}>
                                                                <td className={["bold", _default.requestType[ord.orderTypeId]].join(" ")}>
                                                                    {
                                                                        this.state.hoverAction && this.state.hoverId === ord.id ?
                                                                            (
                                                                                <div
                                                                                    className="order-actions text-center">
                                                                                    <a className="btn btn-secondary"
                                                                                       onClick={() => {
                                                                                           this.handleOrderHistory(ord.id)
                                                                                       }}
                                                                                    >
                                                                                        <i className="fa fa-info"/>
                                                                                    </a>
                                                                                    <a className="btn btn-primary"
                                                                                       onClick={() => this.handleOrderReceiptInfo(ord.id)}
                                                                                    >رسید</a>
                                                                                    {
                                                                                        ord.orderStatusId === 0 ? (
                                                                                            <a onClick={() => this.handleCancelOrder(ord.id)}
                                                                                               className="btn btn-danger">لغو</a>
                                                                                        ):""
                                                                                    }
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (
                                                                                ord.id
                                                                            )
                                                                    }
                                                                </td>
                                                                <td>{ord.fundAbbreviation}</td>
                                                                <td>{convertCurrencyTpye(parseInt(ord.quantity) * parseInt(ord.price))}</td>
                                                                <td>{convertCurrencyTpye(ord.quantity)}</td>
                                                                <td>{convertCurrencyTpye(ord.price)}</td>
                                                                <td>{ord.orderStatusTitle}</td>

                                                            </tr>
                                                        )
                                                    })
                                                )
                                                :
                                                (
                                                    <tr>
                                                        <td colSpan="7">{_default.noRecord}</td>
                                                    </tr>
                                                )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pl-1 pr-2 d-none d-sm-block">
                            <div className="assets bg-white">
                                <div className="row">
                                    <div className="col-md-9 pr-0 pl-3">
                                        <h3>
                                            <img className="icon left" width="19" src="img/admin/icon/fund.svg" alt=""/>
                                            دارائی ها
                                        </h3>
                                        <table className="table table-striped order-table">
                                            <thead>
                                            <tr>
                                                <th scope="col">نام صندوق</th>
                                                <th scope="col">نوع صندوق</th>
                                                <th scope="col">تعداد واحد</th>
                                                <th scope="col">ارزش</th>

                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                inProcessCustomerAssets ? (
                                                    <tr>
                                                        <td colSpan="4">لطفا کمی صبر کنید</td>
                                                    </tr>
                                                ) : (
                                                    customerAssetInfo.count > 0 ? (
                                                        customerAssetInfo.fundLicenseAsset.map(activeOrder => {
                                                            this.sum = this.handleSumAssets(activeOrder.quantity, activeOrder.navSale);
                                                            return (
                                                                <tr key={activeOrder}>
                                                                    <td>{activeOrder.fundAbbreviation}</td>
                                                                    <td>{activeOrder.fundTypeTitle}</td>
                                                                    <td>{convertCurrencyTpye(activeOrder.quantity)}</td>
                                                                    <td>{convertCurrencyTpye(parseInt(activeOrder.navSale) * parseInt(activeOrder.quantity))}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4">{_default.noRecord}</td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-3">
                                        <ul className="list-group p-0 sum">
                                            <li className="list-group-item">
                                                <span>ارزش کل دارایی</span>
                                            </li>
                                            <li className="list-group-item">
                                                <span>{convertCurrencyTpye(this.sum)}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mstp = state => ({
    orders: state.orders,
    orderUpdatedFlag: state.orderUpdatedFlag
});

export default connect(mstp)(OrderBar);