import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css'
import './assets/css/style.css'
import './assets/css/responsive.css';
import './assets/css/loading.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'trim-redux';
import store from './store';


ReactDOM.render(

    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
