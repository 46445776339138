import http from './apiServices';
import {makeAuthHeader} from './auth';
import _config from '../assets/helper/apiHelper.json';
import _success from '../assets/helper/successMsgHelper.json';
import _default from '../assets/helper/default';
import * as _ from "lodash";
import validator from "validator";


export function setBackgroundDefault(color = "") {
    let colorCode = "#fff";
    if (color !== "") {
        if (color === "bg1") {
            colorCode = "#262261";
        } else {
            colorCode = color;
        }
    }
    document.body.style.background = colorCode;
}

export function prepareSelectData(type, info) {
    let exportInformation = [];
    if (type === 'city') {
        for (let row of info) {
            exportInformation.push({
                value: row.id,
                label: row.provinceTitle + " - " + row.cityTitle,
                provineId: row.provinceId
            });
        }
        exportInformation = _.orderBy(exportInformation, ['label', 'provinceId'], ['asc', 'asc']);
    } else if (type === 'national') {
        info = _.orderBy(info, ['title'], ['asc']);
        for (let row of info) {
            exportInformation.push({
                value: row.id,
                label: row.title
            });
        }
        exportInformation = _.orderBy(exportInformation, ['label'], ['asc']);
    } else if (type === "gender") {
        for (let row of info) {
            exportInformation.push({
                value: row.id,
                label: row.title
            });
        }
        exportInformation = _.orderBy(exportInformation, ['label'], ['asc']);
    } else if (type === 'married') {
        info = _default.married;
        for (let row of info) {
            exportInformation.push({
                value: row.value,
                label: row.label
            });
        }
    } else if (type === 'bankName') {
        for (let row of info) {
            exportInformation.push({
                value: row.id,
                label: row.title
            });
        }
    } else if (type === 'bankAccountTypes') {
        for (let row of info) {
            exportInformation.push({
                value: row.id,
                label: row.title
            });
        }
    } else if (type === 'fundBankAccountsList') {
        for (let row of info) {
            exportInformation.push({
                value: row.id,
                label: row.bankTypeTitle + " " + row.bankTitle + " " + row.accountNumber
            });
        }

    } else if (type === 'bankList') {
        for (let row of info) {
            exportInformation.push({
                value: row.id,
                label: row.bankTitle
            });
        }

    }

    return exportInformation;
}

export function changeNullValue(info) {
    for (let row of info) {
        console.log(row);
    }
}

export function checkValidation(data, condition = {}) {
    let isValid = true;
    let finalCondition = {req: true, numeric: false, min: 1, max: undefined};
    if (!_.isEmpty(condition)) {
        for (let index in condition) {
            finalCondition[index] = condition[index];
        }
    }

    if (!finalCondition.numeric) {
        finalCondition.max = 900;
        if (finalCondition.req === true) {
            if (validator.isEmpty(data)) {
                isValid = isValid && false;
            }
        }
        if (!validator.isLength(data, {min: finalCondition.min, max: finalCondition.max})) {
            isValid = isValid && false;
        }
    } else {
        if (!_.isNumber(data)) {
            isValid = isValid && false
        }
    }

    return isValid;
}

export function getLocalStorage(type = "") {
    let localInfo = {};
    if (type === "") {
        localInfo = JSON.parse(localStorage.getItem(_default.tokenName));
    } else if ("user") {
        localInfo = JSON.parse(localStorage.getItem(_default.tokenName));
        localInfo = localInfo[_default.userInfoTokenName];
    }

    return localInfo;
};

export async function changePassword(info) {
    const userInfo = getLocalStorage();
    info['userName'] = userInfo[_default.userInfoTokenName]['userName'];
    delete info.confirmPassword;
    const opt = makeAuthHeader();
    let exportinfo = {isSuccess: false, type: "error", msg: "خطا"};
    await http.post(_config.changePassword, info, opt)
        .then(response => {
            let responseBody = response.data;
            if (response.data.isSuccess) {
                exportinfo = {isSuccess: true, type: "success", msg: _success.passwordChanged};
            } else {
                let msg = responseBody['exceptions'][0]['exception']['persianDescription'];
                exportinfo = {isSuccess: false, type: "error", msg: msg};
            }
        });

    return exportinfo;
}

export async function getTimeInfo() {
    return await http.get(_config.GetFundMarketStatus);
}

export function checkInstantPasswordChange() {
    const userInfo = getLocalStorage();
    const instantPasswordChange = userInfo[_default.userInfoTokenName]['instantPasswordChange'];
    return instantPasswordChange;
}

export async function getBaseListInfo() {
    return await http.get(_config.getBaseListInfo);
}

export async function getUserProfileInfo() {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.getUserProfileInfo, requestData, opt);
}

export async function updatePersonInformation(info) {

    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    let requestData = {
        "personalityId": personalityId,
        "firstName": info.firstName,
        "lastName": info.lastName,
        "fatherName": info.fatherName,
        "birthDate": info.birthDate,
        "identityNo": info.identityNo,
        "identitySerial": info.identitySerial,
        "genderType": info.genderTypeId,
        "creedType": "",
        "married": info.married,
        "nationalityId": info.nationalityId,
        "citizenUniqueCode": info.citizenUniqueCode,
        "passportExpirDate": info.passportExpirationDate,
        "passportNo": info.passportNo,
        "placeOfBirthId": info.placeOfBirthId,
        "placeOfIssueId": info.placeOfIssueId
    };

    const opt = makeAuthHeader();
    return await http.post(_config.UpdatePerson, requestData, opt)

}

export function clearUserInfoLoginAgain() {
    localStorage.removeItem(_default.tokenName);
}

export function setInfoInUserProfile() {
    let info = getLocalStorage();
    // console.log(info[_default.userInfoTokenName]);
}

export async function getBankAccountsList() {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.getBankAccountsList, requestData, opt);
}

export async function setDefaultBankAccount(bankId) {
    const requestData = {
        "bankAccountId": bankId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.setDefaultBankAccount, requestData, opt);
}

export function changeDefaultLocal(list, id) {
    let finalList = [];
    for (let row of list) {
        let status = false;
        if (row['id'] === id) {
            status = true
        }
        row['default'] = status;
        finalList.push(row);
    }
    return finalList;
}

export function deleteLocal(list, id) {
    let finalList = [];
    for (let row of list) {
        if (row['id'] !== id) {
            finalList.push(row);
        }
    }
    return finalList;
}

export async function addBankAccount(info) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId,
        "bankAccountNumber": info.accountCode,
        "bankAccountTypeId": info.accountType,
        "bankId": info.bankName,
        "bankBranchId": info.bankBranch,
        "shabaNumber": info.shabaCode
    };
    const opt = makeAuthHeader();
    return await http.post(_config.addBankAccount, requestData, opt)
}

export async function updateBankAccount(info, id) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId,
        "bankAccountId": id,
        "bankAccountNumber": info.accountCode,
        "bankAccountTypeId": info.accountType,
        "bankId": info.bankName,
        "bankBranchId": info.bankBranch,
        "shabaNumber": info.shabaCode
    };
    const opt = makeAuthHeader();
    return await http.post(_config.updateBankAccount, requestData, opt)
}

export async function deleteBankAccount(accountId) {
    const requestData = {
        "bankAccountId": accountId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.deleteBankAccount, requestData, opt);
}

export async function getContactInfo() {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.getUserPhonesList, requestData, opt);
}

export async function setDefaultPhone(phoneId) {
    const requestData = {
        "phoneId": phoneId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.setDefaultUserPhone, requestData, opt);
}

export async function addContactInfo(info) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId,
        "phoneNumber": info.phoneNumber,
        "phoneTypeId": info.phoneType
    };
    const opt = makeAuthHeader();
    return await http.post(_config.addUserPhone, requestData, opt);
}

export async function updateContactInfo(info, id) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId,
        "phoneId": id,
        "phoneNumber": info.phoneNumber,
        "phoneTypeId": info.phoneType
    };
    const opt = makeAuthHeader();
    return await http.post(_config.updateUserPhone, requestData, opt);
}

export async function deleteContactInfo(phoneId) {
    const requestData = {"phoneId": phoneId};
    const opt = makeAuthHeader();
    return await http.post(_config.deleteUserPhone, requestData, opt);
}

export async function addElecAddress(info) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId,
        "electronicAddressValue": info.elecAddress,
        "electronicAddressTypeId": info.elecAddressType
    };
    const opt = makeAuthHeader();
    return await http.post(_config.addElecAddress, requestData, opt);
}

export async function editElecAddress(info, id) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityId,
        "electronicAddressId": id,
        "electronicAddressValue": info.elecAddress,
        "electronicAddressTypeId": info.elecAddressType
    };
    const opt = makeAuthHeader();
    return await http.post(_config.updateElecAddress, requestData, opt);
}

export async function deleteElecAddress(electronicAddressId) {
    const requestData = {"electronicAddressId": electronicAddressId};
    const opt = makeAuthHeader();
    return await http.post(_config.deleteElecAddress, requestData, opt);
}

export async function setDefaultElecAddress(electronicAddressId) {
    const requestData = {
        "electronicAddressId": electronicAddressId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.setDefaultElecAddress, requestData, opt);
}

export async function addAddress(info) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];

    const requestData = {
        "personalityId": personalityId,
        "locationAddressValue": info.address,
        "locationAddressTypeId": info.addressType,
        "locationAddressLatitude": null,
        "locationAddressLongitude": null,
        "locationAddressCityId": null,
        "locationAddressPostalCode": info.postCode
    };
    const opt = makeAuthHeader();
    return await http.post(_config.addAddress, requestData, opt);
}

export async function editAddress(info, id) {
    const userInfo = getLocalStorage();
    const personalityId = userInfo[_default.userInfoTokenName]['personalityId'];

    const requestData = {
        "personalityId": personalityId,
        "personalityLocationAddressId": id,
        "locationAddressValue": info.address,
        "locationAddressTypeId": info.addressType,
        "locationAddressLatitude": null,
        "locationAddressLongitude": null,
        "locationAddressCityId": null,
        "locationAddressPostalCode": info.postCode
    };
    const opt = makeAuthHeader();
    return await http.post(_config.updateAddress, requestData, opt);
}

export async function deleteAddress(AddressId) {
    const requestData = {
        "personalityLocationAddressId": AddressId
    };
    const opt = makeAuthHeader();
    return await http.post(_config.deleteAddress, requestData, opt);
}

export function prepareDocumentList(list, exist) {
    const userInfo = getLocalStorage();
    const personalityTypeId = userInfo[_default.userInfoTokenName]['personalityTypeId'];
    let final = [];
    list = _.filter(list, row => row.personalityTypeId === personalityTypeId);
    list = _.orderBy(list, ['mandatory'], ['desc']);
    exist = _.split(exist.hasRecordTypeIds, ",");
    for (let row of list) {
        row['exist'] = exist.includes(row.id.toString());
        final.push(row);
    }
    return final;
}

export function uploadFileDocument(data, recordType) {
    const userInfo = getLocalStorage();
    const personalityTypeId = userInfo[_default.userInfoTokenName]['personalityId'];
    const user_Id = userInfo[_default.userInfoTokenName]['user_Id'];

    // personalityRecordFile
    // entryUserId: 6205
    // personalityId: 8933
    // recordTypeId: 3
    // clientIp: 5.121.123.0

    const info = new FormData();
    info.append("personalityRecordFile", data);
    info.append("entryUserId", user_Id);
    info.append("personalityId", personalityTypeId);
    info.append("recordTypeId", recordType);


    const opt = makeAuthHeader('ff');
    return http.post(_config.fileUpload, info, opt);
}

export async function deleteDocumentRecord(id) {
    const userInfo = getLocalStorage();
    const personalityTypeId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityTypeId,
        "recordTypeId": id
    };
    const opt = makeAuthHeader();
    return await http.post(_config.deleteDocumentRecord, requestData, opt);
}

export async function getPersonalityRecordsContent(recordId) {
    const userInfo = getLocalStorage();
    const personalityTypeId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestdata = {
        "personalityId": personalityTypeId,
        "recordTypeId": recordId,
        "encodeType": "base64"
    };
    const opt = makeAuthHeader();
    return await http.post(_config.getPersonalityRecordsContent, requestdata, opt)

}

export async function GetPersonalityTodayOrders() {
    const userInfo = getLocalStorage();
    const personalityTypeId = userInfo[_default.userInfoTokenName]['personalityId'];
    const requestData = {
        "personalityId": personalityTypeId,
    };
    const opt = makeAuthHeader();
    return await http.post(_config.getPersonalityTodayOrders, requestData, opt);
}

export function checkTokenInfoType(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function updateTokenInfo(personalInfo, indexInToken) {
    let localStorageInfo = getLocalStorage();
    let sampleInfoUserInLocal = localStorageInfo[_default.userInfoTokenName];
    sampleInfoUserInLocal[indexInToken] = personalInfo[indexInToken];
    localStorageInfo[_default.userInfoTokenName] = sampleInfoUserInLocal;
    localStorage.setItem(_default.tokenName, localStorageInfo);

    let finalSaved = {};
    finalSaved[_default.authTokenName] = localStorageInfo[_default.authTokenName];
    finalSaved[_default.userInfoTokenName] = sampleInfoUserInLocal;
    localStorage.setItem(_default.tokenName, JSON.stringify(finalSaved));

}

export function uploadProfilePhoto(data) {
    const userInfo = getLocalStorage();
    const personalityTypeId = userInfo[_default.userInfoTokenName]['personalityId'];

    // personalityProfileFile
    // personalityId

    const info = new FormData();
    info.append("personalityProfileFile", data);
    info.append("personalityId", personalityTypeId);


    const opt = makeAuthHeader('ff');
    return http.post(_config.profilePhotoUpload, info, opt);
}

export function checkDataIsImageSrc(data) {
    if (data === '' || data.trim() === '') {
        return false;
    }
    try {
        return btoa(atob(data)) == data;
    } catch (err) {
        return false;
    }
}

export async function getBankBranchById(bankId) {
    const requestUrl = _config.getFundBankBranchById + bankId;
    return await http.get(requestUrl);

}

export async function getLogOut() {
    const opt = makeAuthHeader();
    return await http.post(_config.logout, {}, opt);
}

export function persianDigit(string) {
    const symbolMap = {
        '1': '۱',
        '2': '۲',
        '3': '۳',
        '4': '۴',
        '5': '۵',
        '6': '۶',
        '7': '۷',
        '8': '۸',
        '9': '۹',
        '0': '۰'
    };

    return string.replace(/\d/g, function (match) {
        return symbolMap[match]
    }).replace(/,/g, '،');
}

export function newMsgCount(list) {
    let count = 0;
    if (list.count !== 0) {
        list.contactUsMessages.map(row => {
            if (row.lastChangeByAdmin) {
                count++;
            }
        });
    }
    return count
}

export function tableStirng(str = "", len = 0) {
    if (str != null) {
        str = str.toString();
        if (str.length > len && len !== 0) {
            str = str.substr(0, len) + "...";
        }
    }
    return str;
}

export function personalInfoStatus() {
    const userInfo = getLocalStorage();
    const bineryUserInfo = userInfo[_default.userInfoTokenName]['informationCompletionStatusId'];
    const checkInfoId = _default.informationCheckArray;
    let finalStatus = {};
    checkInfoId.map(row => {
        const status = (bineryUserInfo & row.value);
        finalStatus[row.name] = status === row.value;
    });
    return finalStatus;
}

export function personalprofileStatus() {
    const info=personalInfoStatus();
    let requireInfo=["PersonalInformation","BankAccount","Phones","KnowYourCustomerByFund"];

    let finalStatus=true;
    requireInfo.map(row=>{
         finalStatus=info[row]&&finalStatus;
    });
    return finalStatus;
}