import React, {Component} from 'react';
import {setStore, connect} from "trim-redux";
import {Modal} from "react-bootstrap";
import moment from "moment-jalaali"
import {persianDigit} from "../../../../services/dashboard";
import {convertCurrencyTpye} from "../../../../services/fund";

class OrderHistoryModal extends Component {

    handleClose() {
        setStore({orderHistoryModal: false, newOrder: {},orderHistory:[]});
    }

    render() {
        const {newOrder, orderHistory} = this.props;
        return (
            <>
                <Modal className="rtl modal-style order-history" show={this.props.orderHistoryModal}
                       backdropClassName="multi-level-modal"
                       onHide={this.handleClose.bind(this)} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>تاریخچه سفارش</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-0 pt-2 pb-4">
                        <div className="container">
                            <div className="row px-3 header">
                                <div className="col-md-4">
                                    <div className="text-center pb-2">
                                        <span className="title">شماره سفارش: </span>
                                        <span className="value">{newOrder.id}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center pb-2">
                                        <span className="title">نام: </span>
                                        <span className="value">{newOrder.personalityTitle}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center pb-2">
                                        <span className="title">کد ملی: </span>
                                        <span className="value">{newOrder.personalityNationalCode}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row px-3 header">
                                <div className="col-md-4">
                                    <div className="text-center pb-2">
                                        <span className="title">زمان: </span>
                                        <span className="value">{moment(newOrder.entryDate).format('HH:MM')}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center pb-2">
                                        <span className="title">ارزش: </span>
                                        <span className="value">{convertCurrencyTpye(newOrder.issuingRequestAmount)}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center pb-2">
                                        <span className="title">تعداد واحد: </span>
                                        <span className="value">{convertCurrencyTpye(newOrder.quantity)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row px-3 header">
                                <div className="col-md-4">
                                    <div className="text-center pb-2">
                                        <span className="title">قیمت: </span>
                                        <span className="value">{convertCurrencyTpye(newOrder.price)}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center pb-2">
                                        <span className="title">وضعیت: </span>
                                        <span className="value">{newOrder.orderStatusTitle}</span>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped history">
                                <thead>
                                <tr>
                                    <th>تاریخ</th>
                                    <th>توضیحات</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orderHistory.map(history => {
                                        return (
                                            <tr key={history.id}>
                                                <td>{persianDigit(moment(history.entryDate).format('jYYYY/jMM/jDD'))}</td>
                                                <td>{history.description}</td>
                                            </tr>
                                        );
                                    })
                                }

                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mstp = state => ({
    orderHistoryModal: state.orderHistoryModal,
    newOrder: state.newOrder,
    orderHistory: state.orderHistory
});

export default connect(mstp)(OrderHistoryModal);