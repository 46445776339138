import React, {Component} from 'react';
import {Alert, Modal} from "react-bootstrap";
import {connect, setStore} from 'trim-redux';
import validator from "validator";
import _error from "../../../../assets/helper/errorMsgHelper";
import userHolderImage from "./../../../../assets/admin/img/user-profile.svg";
import {
    changePassword,
    checkDataIsImageSrc,
    clearUserInfoLoginAgain,
    getLocalStorage
} from "../../../../services/dashboard";
import {toast} from "react-toastify";
import UploadUserProfileModal from './uploadUserProfileModal';
import ModalLoading from "../../../main/modalLoading";

class ChangePasswordModal extends Component {
    state = {
        fields: {
            password: '',
            newPassword: '',
            confirmPassword: ''
        },
        errors: {},
        instantPasswordChange: false,
        profileImage: "",
        inProcess: false
    };

    componentDidMount() {
        // this.setState({instantPasswordChange: this.props.instantPasswordChange});
        const profileInfo = getLocalStorage("user");
        this.setState({profileImage: profileInfo.profileImage});
    }

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inProcess: true})
        } else {
            this.setState({inProcess: false})
        }
    }

    handleClose() {
        setStore({userProfileModal: false});
        this.resetChangePasswordForm();
    }

    handleChange(event) {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    }

    handleUserProfilePhotoModal = () => {
        setStore({userProfilePhotoModal: true});
    };

    checkValidation(callback) {
        let {fields} = this.state;
        let errors = {};
        let formIsValid = true;

        // validate oldPass
        if (validator.isEmpty(fields.password)) {
            formIsValid = false;
            errors['password'] = _error.emptyOldPassword;
        } else if (!validator.isLength(fields.password, {min: 8, max: undefined})) {
            formIsValid = false;
            errors['password'] = _error.incorrectOldPassword;
        }

        // validate newPass
        if (validator.isEmpty(fields.newPassword)) {
            formIsValid = false;
            errors['newPassword'] = _error.emptyNewPassword;
        } else if (!validator.isLength(fields.newPassword, {min: 8, max: undefined})) {
            formIsValid = false;
            errors['newPassword'] = _error.incorrectNewPassword;
        }

        // validate confirmPass
        if (validator.isEmpty(fields.confirmPassword)) {
            formIsValid = false;
            errors['confirmPassword'] = _error.emptyConfirmPassword;
        } else if (fields.newPassword !== fields.confirmPassword) {
            formIsValid = false;
            errors['confirmPassword'] = _error.incorrectConfirmPassword;
        }

        this.setState({errors}, () => {
            return callback(formIsValid)
        });

    }

    resetChangePasswordForm = () => {
        this.setState({
            fields: {
                password: '',
                newPassword: '',
                confirmPassword: ''
            },
            errors: {}
        });
    };

    handleSubmitChangePassword(e) {
        e.preventDefault();
        this.handleLoading();
        this.checkValidation(valid => {
            if (valid) {
                changePassword(this.state.fields)
                    .then(res => {
                        if (res.isSuccess) {
                            this.resetChangePasswordForm();
                            toast.success(res.msg);
                            this.handleClose();
                            setStore({userLogOutModal: true, instantPasswordChange: false});
                            this.handleLoading(false);
                            clearUserInfoLoginAgain();
                        } else {
                            toast.error(res.msg);
                            this.resetChangePasswordForm();
                            this.handleLoading(false);
                        }
                    })
                    .catch(err => {
                        this.handleLoading(false);
                        console.log(err);
                    })
            } else {
                this.handleLoading(false);
            }
        });
    }

    updateProfilePhoto = (info) => {
        this.setState({profileImage: info});
    };


    render() {
        const {errors} = this.state;
        const {instantPasswordChange} = this.props;
        let {profileImage, inProcess} = this.state;
        this.profileImageStatus = false;
        if (checkDataIsImageSrc(profileImage) && profileImage !== "") {
            this.profileImageStatus = true;
        }
        return (
            <>
                <Modal className="rtl modal-style" show={this.props.userProfileModal}
                       size={instantPasswordChange?"":"lg"}
                       onHide={this.handleClose.bind(this)} backdrop="static" keyboard={!instantPasswordChange}>
                    <Modal.Header closeButton={!instantPasswordChange}>
                        <Modal.Title>حساب کاربری</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            {
                                instantPasswordChange ?
                                    (<div className="row">
                                        <div className="col-md-12">
                                            <Alert variant="warning">
                                                {_error.firstLogin}
                                            </Alert>
                                        </div>
                                    </div>) : ("")
                            }
                            <div className="row">
                                <div className="col-md-6" style={{display:instantPasswordChange?"none":""}}>
                                    <div className="p-5">
                                        <div className="profile-circle-img">
                                            <img className="d-block m-auto img-fluid" height="150" width="auto"
                                                 src={profileImage !== "" ? this.state.profileImage : userHolderImage}
                                                 alt=""
                                                 onClick={this.handleUserProfilePhotoModal}
                                            />
                                            <p className="text-center pt-3">
                                                <small onClick={this.handleUserProfilePhotoModal}>تغییر عکس کاربری
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    instantPasswordChange?(<div className="col-md-2 w-100"></div>):("")
                                }
                                <div className={[instantPasswordChange?"col-md-8":"col-md-4"]}>
                                    <form onSubmit={this.handleSubmitChangePassword.bind(this)}>
                                        <div className="form-group">
                                            <label htmlFor="oldPassword">رمز عبور فعلی</label>
                                            <input name="password" type="password"
                                                   className={["form-control ", errors['password'] ? 'is-invalid' : ''].join('')}
                                                   onChange={this.handleChange.bind(this)}
                                                   value={this.state.fields.password}
                                                   id="oldPassword"/>
                                            <small className="form-text error">
                                                {errors['password'] ? errors['password'] : ''}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="newPassword">رمز عبور جدید</label>
                                            <input name="newPassword" type="password"
                                                   className={["form-control ", errors['newPassword'] ? 'is-invalid' : ''].join('')}
                                                   onChange={this.handleChange.bind(this)}
                                                   value={this.state.fields.newPassword}
                                                   id="newPassword"/>
                                            <small className="form-text error">
                                                {errors['newPassword'] ? errors['newPassword'] : ''}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="confirmPassword">تکرار رمز عبور</label>
                                            <input name="confirmPassword" type="password"
                                                   className={["form-control ", errors['confirmPassword'] ? 'is-invalid' : ''].join('')}
                                                   onChange={this.handleChange.bind(this)}
                                                   value={this.state.fields.confirmPassword}
                                                   id="confirmPassword"/>
                                            <small className="form-text error">
                                                {errors['confirmPassword'] ? errors['confirmPassword'] : ''}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-lg w-100">ثبت</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-2 w-100"></div>
                            </div>
                        </div>
                        <ModalLoading show={inProcess}/>
                    </Modal.Body>
                </Modal>
                <UploadUserProfileModal updateProfilePhoto={this.updateProfilePhoto}/>
            </>
        );
    }
}

const mstp = state => ({
    userProfileModal: state.userProfileModal,
    instantPasswordChange: state.instantPasswordChange
});
export default connect(mstp)(ChangePasswordModal);