import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {getCaptchaUrl, checkNationalCode, verifyCaptcha, forgetPassword} from "../../../services/login";
import validator from 'validator';
import _error from '../../../assets/helper/errorMsgHelper.json'
import {toast} from "react-toastify";
import {connect, setStore} from 'trim-redux';
import {setBackgroundDefault} from "../../../services/dashboard";

class ForgetPassword extends Component {
    state = {
        fields: {
            username: '',
            captcha: '',
            captchaKey: ''
        },
        errors: {},
        captchaURL: '',
        inProcess: false
    };

    componentDidMount() {
        setBackgroundDefault("bg1");
        this.reCaptcha();
    }

    reCaptcha() {
        const captchaAddress = getCaptchaUrl();
        this.setState({captchaURL: captchaAddress.address});
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                captcha: '',
                captchaKey: captchaAddress.captchaKey
            }
        }));
    };

    handleChangeCaptcha(e) {
        e.preventDefault();
        this.reCaptcha();
    }

    handleChange(event) {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    }

    checkValidation(callback) {
        let {fields} = this.state;
        let errors = {};
        let formIsValid = true;

        // username validate
        if (validator.isEmpty(fields.username)) {
            formIsValid = false;
            errors['username'] = _error.emptyNationalCode;
        } else if (!validator.isLength(fields.username, {min: 10, ma: 10})) {
            formIsValid = false;
            errors['username'] = _error.incorrectNationalCode;
        }

        // captcha validate
        if (validator.isEmpty(fields.captcha)) {
            formIsValid = false;
            errors['captcha'] = _error.emptyCaptcha;
        } else if (validator.isLength(fields.captcha, {min: 1, max: 4})) {
            formIsValid = false;
            errors['captcha'] = _error.incorrectCaptcha;
        }

        this.setState({errors}, () => {
            return callback(formIsValid)
        });

    }

    makeRequest(data) {
        verifyCaptcha(data)
            .then(captchaResponse => {
                if (captchaResponse.data.isSuccess) {
                    forgetPassword(data)
                        .then(res => {
                                if (res.status) {
                                    toast.success(res.msg);
                                    this.props.history.replace('/login');
                                } else {
                                    this.reCaptcha();
                                    toast.error(res.msg);
                                }
                                this.handleLoading(false)
                            }
                        )
                    ;
                } else {
                    toast.error(_error.captchaError);
                    this.handleLoading(false);
                    this.reCaptcha();
                }
            })
            .catch(err => console.log(err));
    }

    handleLoading(sts = true) {
        if (sts) {
            this.setState({inProcess: true})
        } else {
            this.setState({inProcess: false})
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.handleLoading();
        let {fields} = this.state;
        this.checkValidation(sts => {
            if (sts) {
                this.makeRequest(fields)
            } else {
                this.handleLoading(false)
            }
        });
    }

    render() {
        const {errors} = this.state;
        return (
            <React.Fragment>
                <section className="rtl bg-theme-color-1 py-5" style={{height: 100 + "vh"}}>
                    <div className="container">
                        <div className="row">
                            <form onSubmit={this.handleSubmit.bind(this)} data-aos="fade-right"
                                  className="form-signin d-block m-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to="/">
                                            <img className="logo d-block m-auto mb-5" src="img/logo-wh.png"
                                                 alt="Etebar logo"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <img className="icon d-block m-auto mb-4 pb-4" src="img/reg-icon.svg"
                                             alt="Etebar logo"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <input type="text"
                                               className={["form-control ", errors['username'] ? 'is-invalid' : ''].join('')}
                                               name="username"
                                               value={this.state.fields.username}
                                               onChange={this.handleChange.bind(this)}
                                               placeholder="کد ملی"
                                               autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <input type="text"
                                               className={["form-control captcha ", errors['captcha'] ? 'is-invalid' : ''].join('')}
                                               name="captcha"
                                               value={this.state.fields.captcha}
                                               onChange={this.handleChange.bind(this)}
                                               placeholder="کد امنیتی"
                                               autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-5 pr-0">
                                        <img className="img-fluid captcha" src={this.state.captchaURL} alt=""/>
                                    </div>
                                    <div className="col-2 p-2">
                                        <div>
                                            <img src={"img/refresh.svg"} className="refresh"
                                                 onClick={this.handleChangeCaptcha.bind(this)}
                                                 height="30" width="30" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-2" style={{minHeight: 80}}>
                                    <div className="col-12">
                                        <p className="error-area"
                                           style={{display: errors["username"] ? 'block' : 'none'}}>
                                            <i className="fa fa-times"/>
                                            {errors["username"]}
                                        </p>
                                        <p className="error-area"
                                           style={{display: errors["captcha"] ? 'block' : 'none'}}>
                                            <i className="fa fa-times"/>
                                            {errors["captcha"]}
                                        </p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-2 w-100"/>
                                    <div className="col-8">
                                        <button disabled={this.state.inProcess}
                                                className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                                            {this.state.inProcess ? 'لطفا صبر کنید...' : 'ادامه'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mstp = state => ({
    step: state.step,
    NationalCode: state.nationalCode,
    loading: state.loading
});

export default connect(mstp)(ForgetPassword);
