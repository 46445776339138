import React, {Component} from "react";
import Chart from "react-apexcharts";
import {convertCurrencyTpye, getNavChart, prepareNavChartData} from "../../../services/fund";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import _error from "../../../assets/helper/errorMsgHelper";
import NavChartLoading from "../../main/navChartLoading";

class NavChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    id: "basic-line",
                    background: '#f3f3f3',
                    fontFamily: 'irsul',
                    toolbar: {
                        show: false
                    },
                    height: 120
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: false,
                        style: {
                            colors: [],
                            fontSize: '9pt',
                            cssClass: 'apexcharts-xaxis-label'
                        }
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                yaxis: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#e7e7e7',
                    padding: {
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 10
                    }

                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    width: 2,
                    dashArray: 0,
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '10pt'
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    followCursor: false,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    style: {
                        fontSize: '9pt'
                    },
                    y: {
                        formatter: (seriesName) => convertCurrencyTpye(seriesName),
                    }
                },
                colors: ['#38b998', '#90278e', '#ff4968']
            },
            series: [],
            inProcess: true
        };
    }

    componentDidMount() {
        getNavChart(this.props.timeInfo)
            .then(res => {
                if (res.data.isSuccess) {
                    const chartData = prepareNavChartData(res.data.navStatistics);
                    this.setState(prevState => ({
                        ...prevState,
                        series: chartData.series,
                        options: {
                            ...prevState.options,
                            xaxis: {
                                categories: chartData.cat
                            }
                        },
                        inProcess: false
                    }));
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    // toast.error(msg);
                }
            })
            .catch(err => {
                toast.error(_error.SystemError);
            });
    }

    render() {
        return (
            <div className="bg-white chart-area mr-md-2">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="text-right title">
                            ارزش خالص دارایی
                            <img className="icon" width="20" src="img/admin/icon/assets.svg" alt=""/>
                        </h6>
                    </div>
                    <div className="col-md-12">
                        <div className="mx-3">
                            <Chart
                                options={this.state.options}
                                series={this.state.series}
                                type="line"
                                width="100%"
                                height="168px"
                            />
                            <NavChartLoading show={this.state.inProcess}/>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}


const mstp = state => ({
    timeInfo: state.timeInfo
});
export default connect(mstp)(NavChart);
