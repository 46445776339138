import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {connect, setStore} from 'trim-redux';
import {convertCurrencyTpye, groupByData, prepareFundGrid} from "../../../../services/fund";
import moment from "moment-jalaali";
import _default from "./../../../../assets/helper/default.json"
import {persianDigit} from "../../../../services/dashboard";

class FundDetailModal extends Component {

    state = {
        fundProperty: [
            {
                id: 1,
                title: 'زمان بروز رسانی اطلاعات',
                icon: 'img/admin/icon/property/pro1.svg',
                index: 'navCalcDate',
            },
            {
                id: 2,
                title: 'کل خالص ارزش دارائی ها',
                icon: 'img/admin/icon/property/pro2.svg',
                index: '',
                value: ''
            },
            {
                id: 3,
                title: 'تعداد واحدهای سرمایه‌گذاری',
                icon: 'img/admin/icon/property/pro3.svg',
                index: 'navFundCapital',
                value: ''
            },
            {
                id: 4,
                title: 'قیمت آماری هر  واحد سرمایه‌گذاری',
                icon: 'img/admin/icon/property/pro4.svg',
                index: 'navStatistic',
                value: ''
            },
            {
                id: 5,
                title: 'قیمت صدور هر واحد سرمایه‌گذاری',
                icon: 'img/admin/icon/property/pro5.svg',
                index: 'navPurchase',
                value: ''
            },
            {
                id: 6,
                title: 'قیمت ابطال هر واحد سرمایه‌گذاری',
                icon: 'img/admin/icon/property/pro6.svg',
                index: 'navSale',
                value: ''
            }

        ]
    };

    handleClose = () => {
        setStore({fundDetailModal: false});
    };


    render() {
        const {fundDetail} = this.props;
        const {fundProperty} = this.state;
        // console.log(fundDetail);
        const fundInfo = fundDetail.fund;
        const fundGuarantorInfo = fundDetail.guarantors;
        const fundManagersInfo = fundDetail.managers;

        return (
            <>
                <Modal className="rtl modal-style fund-detail-modal" show={this.props.fundDetailModal}
                       size="lg"
                       onHide={this.handleClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>اطلاعات صندوق</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-0 py-2">
                        <div className="container px-5">
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="text-dark pt-4">
                                        <h6>{fundInfo.legalTitle}</h6>
                                        <p className="mb-2">
                                            <small className="bold">
                                                {fundInfo.fundTypeTitle}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <img src="img/admin/logo-admin.svg" className="card-img" height="75"
                                         alt="..."/>
                                </div>
                            </div>
                            {/*<div className="row">*/}
                            {/*<div className="col-md-12">*/}
                            {/*<p className="text-justify description pt-4">*/}

                            {/*</p>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            <hr className="my-3"/>
                            <div className="row">
                                <div className="col-md-6">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td>تاریخ آغاز فعالیت</td>
                                            <td>
                                                {persianDigit(moment(fundInfo.legalRegistrationDate).format('jYYYY/jM/jD'))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>متولی صندوق</td>
                                            <td>{fundInfo.auditorName}</td>
                                        </tr>
                                        <tr>
                                            <td>مدیر ثبت</td>
                                            <td>شرکت تامین سرمایه اعتبار</td>
                                        </tr>
                                        <tr>
                                            <td>مدیران سرمایه گذاری</td>
                                            <td>میثم خدایاری،محمد فتحی</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td>مدیر صندوق</td>
                                            <td>شرکت تامین سرمایه اعتبار</td>
                                        </tr>
                                        <tr>
                                            <td>ضامن نقد شوندگی</td>
                                            <td>{fundGuarantorInfo[0]['guarantorName']}</td>
                                        </tr>
                                        <tr>
                                            <td>حسابرس</td>
                                            <td>شرکت حسابرسی فیدار</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr className="my-2"/>
                            <div className="row property-grid">
                                <div className="col-md-4">
                                    <div className="card">
                                        <img className="card-img-top px-5 d-block m-auto"
                                             src="img/admin/icon/property/pro1.svg"
                                             alt="property"/>
                                        <div className="card-body pt-2">
                                            <p className="card-text text-center title">
                                                زمان بروز رسانی اطلاعات
                                            </p>
                                            <p className="text-center card-text data">
                                                {
                                                    persianDigit(moment(fundInfo['navCalcDate']).format('jYY/jM/jD'))
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <img className="card-img-top px-5 d-block m-auto"
                                             src="img/admin/icon/property/pro2.svg"
                                             alt="property"/>
                                        <div className="card-body pt-2">
                                            <p className="card-text text-center title">
                                                کل خالص ارزش دارائی ها
                                            </p>
                                            <p className="text-center card-text data">
                                                {convertCurrencyTpye(parseInt(fundInfo['navFundCapital']) * parseInt(fundInfo['navSale']))}
                                                ريال
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <img className="card-img-top px-5 d-block m-auto"
                                             src="img/admin/icon/property/pro3.svg"
                                             alt="property"/>
                                        <div className="card-body pt-2">
                                            <p className="card-text text-center title">
                                                تعداد واحدهای سرمایه‌گذاری
                                            </p>
                                            <p className="text-center card-text data">
                                                {convertCurrencyTpye(fundInfo['navFundCapital'])}
                                                واحد
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <img className="card-img-top px-5 d-block m-auto"
                                             src="img/admin/icon/property/pro4.svg"
                                             alt="property"/>
                                        <div className="card-body pt-2">
                                            <p className="card-text text-center title">
                                                قیمت آماری هر واحد سرمایه‌گذاری
                                            </p>
                                            <p className="text-center card-text data">
                                                {convertCurrencyTpye(fundInfo['navStatistic'])}
                                                ریال
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <img className="card-img-top px-5 d-block m-auto"
                                             src="img/admin/icon/property/pro5.svg"
                                             alt="property"/>
                                        <div className="card-body pt-2">
                                            <p className="card-text text-center title">
                                                قیمت صدور هر واحد سرمایه‌گذاری
                                            </p>
                                            <p className="text-center card-text data">
                                                {convertCurrencyTpye(fundInfo['navPurchase'])}
                                                ریال
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <img className="card-img-top px-5 d-block m-auto"
                                             src="img/admin/icon/property/pro6.svg"
                                             alt="property"/>
                                        <div className="card-body pt-2">
                                            <p className="card-text text-center title">
                                                قیمت ابطال هر واحد سرمایه‌گذاری
                                            </p>
                                            <p className="text-center card-text data">
                                                {convertCurrencyTpye(fundInfo['navSale'])}
                                                ریال
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-2"/>
                            <div className="row">
                                <div className="col-md-4 w-100"/>
                                <div className="col-md-4">
                                    <ul className="list-group list-group-horizontal download-document">
                                        <li className="col list-group-item">
                                            <i className="fa fa-cloud"/>
                                            <a target="_blank"
                                               href="http://www.aminsaman.com/FundData/Prospectus/omidname_avalie.pdf">
                                                امیدنامه
                                            </a>
                                        </li>
                                        <li className="col list-group-item">
                                            <i className="fa fa-cloud"/>
                                            <a target="_blank"
                                               href="http://www.aminsaman.com/FundData/Statute/asasname_avalie.pdf">
                                                اساسنامه
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                                <div className="col-md-4 w-100"/>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="text-center more-link">
                                        جهت دریافت تغییرات
                                        <a target="_blank" href={_default.fundDetailIdentity.asasName}> اساسنامه </a>
                                        و
                                        <a target="_blank" href={_default.fundDetailIdentity.omidName}> امیدنامه </a>
                                        به سایت صندوق مراجعه نمائید
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mstp = state => ({
    fundDetailModal: state.fundDetailModal
});
export default connect(mstp)(FundDetailModal);