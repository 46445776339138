import React, {Component} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

export default class extends Component {
    state = {
        nextPage: 0,
        loadMore: true
    };

    handleLoadInfo = () => {

    };

    render() {
        return (
            <div className="tbl">
                <div className={["tbl-head flexed", this.props.class ? this.props.class : ""].join(" ")}>
                    {
                        this.props.head.map((item, index) => {
                            return (
                                <span key={index}
                                      className={`p-1 ${this.props.cols ? 'flex' + this.props.cols[index] : 'flex1'}`}>{item}</span>
                            )
                        })
                    }
                </div>
                <div className="overflow-auto tbl-body flexed fdir-col">
                    {
                        this.props.children.map((item, index) => {
                            return (
                                <div key={index} className="flex1 tbl-row flexed width100">
                                    {
                                        item.map((d, i) => {
                                            return (
                                                <span key={i}
                                                      className={`p-1 ${this.props.cols ? 'flex' + this.props.cols[i] : 'flex1'}`}>{d}</span>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}