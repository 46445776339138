import React, {Component} from 'react';
import {connect, setStore} from "trim-redux";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {checkTimeStatus, convertCurrencyTpye, setRevokeOrder} from "../../../../services/fund";
import {checkValidation, prepareSelectData} from "../../../../services/dashboard";
import Select from "react-virtualized-select";
import _error from "../../../../assets/helper/errorMsgHelper";
import {toast} from "react-toastify";
import InlineSVG from "svg-inline-react";
import CurrencyInput from 'react-currency-input';
import remSep from "string-remove-thousand-separators";

class SellFundModal extends Component {

    state = {
        fields: {
            bankAccount: '',
            currentAmount: '',
            amount: "",
            unit: "",
            description: '',
            rights: null
        },
        mask: {
            amount: "",
            unit: ""
        },
        reqiredField: {
            bankAccount: {numeric: true},
            amount: {},
            unit: {},
            rights: {}
        },
        errors: {}
    };

    resetForm() {
        this.setState({});
    }

    handleClose() {
        setStore({sellFundModal: false});
        this.resetForm();
    }

    handleChangeSelect = (info, name) => {
        let val = null;
        let fields = this.state.fields;
        if (info !== null) {
            val = info.value
        }
        fields[name] = val;
        this.setState({fields});
    };

    handleChangeInput = (event) => {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    };

    handleRightsAccept = () => {
        let fields = this.state.fields;
        fields['rights'] = fields['rights'] === null ? "check" : null;
        console.log(fields['rights']);
        this.setState({
            fields
        })
    };

    checkValidationForm(callback) {
        let {fields, reqiredField} = this.state;
        let errors = {};
        let formIsValid = true;

        for (let fieldIndex in reqiredField) {
            const validUnit = checkValidation(fields[fieldIndex] === null ? '' : fields[fieldIndex], reqiredField[fieldIndex]);
            if (!validUnit) {
                errors[fieldIndex] = _error.isRequire;
            }
            formIsValid = formIsValid && validUnit;
        }
        this.setState({errors}, () => {
            return callback(formIsValid);
        });

    }

    handleSubmitRevoke(e) {
        e.preventDefault();
        let {fields} = this.state;
        const {fundDetail, assets} = this.props;
        if (assets === null) {
            toast.error('واحد صدور شده ای وجود ندارد!')
        } else {
            this.checkValidationForm(sts => {
                if (sts) {
                    setRevokeOrder(fields, fundDetail.fund.registerNumber, assets.licenseNumber)
                        .then(res => {
                            console.log("oksell", res);
                            if (res.data.isSuccess) {
                                let currentOrders = this.props.orders;
                                currentOrders.push(res.data.order);
                                setStore({orders: currentOrders, orderUpdatedFlag: true, sellFundModal: false});
                                toast.success("ابطال با موفقیت انجام شد");
                            } else {
                                let msg = res.data.exceptions[0];
                                msg = msg.exception.persianDescription;
                                toast.error(msg);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            })
        }

    }

    calcUnit2Amount = (value, name) => {
        if (value === "0" || value === 0 || value === "0 واحد") {
            value = "";
        }
        let fields = this.state.fields;
        let mask = this.state.mask;
        const {fundDetail} = this.props;
        const sellPrice = parseInt(fundDetail.fund.navSale);
        const dailyConst = parseFloat(fundDetail.fund.statisticSimpleDaily);
        let finalPrice = 0;
        if (name === 'unit') {
            if (value === "") {
                fields['unit'] = "";
                fields['amount'] = "";
                mask['amount'] = "";
                mask['unit'] = "";
            } else {
                value = value.replace(" واحد", "");
                value = remSep(value);
                finalPrice = ((sellPrice * dailyConst) + sellPrice) * parseInt(value);
                fields['unit'] = value;
                fields['amount'] = Math.ceil(finalPrice).toString();
                mask['amount'] = Math.ceil(finalPrice);
            }

        } else if (name === 'amount') {
            if (value === "") {
                fields['amount'] = "";
                fields['unit'] = "";
                mask['amount'] = "";
                mask['unit'] = "";
            } else {
                value = value.replace(" ریال", "");
                let pureVal = remSep(value);
                finalPrice = (parseInt(remSep(value)) / (sellPrice + (sellPrice * dailyConst)));
                finalPrice = Math.floor(finalPrice);
                fields['amount'] = pureVal;
                fields['unit'] = finalPrice.toString();
                mask['unit'] = finalPrice;
            }

        }
        fields[name] = remSep(value);
        mask[name] = value;
        this.setState({fields, mask});
    };


    render() {
        const svgSource = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="50px" height="50px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B69F33;}
</style>
<g>
	<g>
	<polygon class="tp-1 sp-1 st0" points="47.5,32 35.4,25.1 35.4,39 47.5,46 		"/>
	<polygon class="tp-2 sp-1 st0" points="64.5,39.1 64.5,25 52.5,32 52.4,46 		"/>
	<polygon class="tp-3 st0 sp-2" points="35.4,5.4 35.4,19.4 47.6,26.5 47.6,12.5 		"/>
	<polygon class="tp-4 st0 sp-2" points="64.5,5.4 52.3,12.5 52.3,26.6 64.5,19.5 		"/>
	<polygon class="tp-5 sp-1 st0" points="66.9,43.2 54.7,50.2 66.9,57.2 79,50.2 		"/>
	<polygon class="tp-6 st0 sp-2" points="81.4,15.2 69.2,22.2 69.2,36.4 81.4,29.4 		"/>
	<polygon class="tp-7 st0 sp-2" points="71.6,40.4 83.8,47.6 96,40.5 83.8,33.4 		"/>
	<polygon class="tp-8 sp-1 st0" points="64.5,75.3 64.5,61.4 52.4,54.4 52.4,68.3 		"/>
	<polygon class="tp-9 st0 sp-2" points="71.5,60 83.7,67.1 96,60.1 83.8,53 		"/>
	<polygon class="tp-10 st0 sp-2" points="69.2,78.3 81.3,85.3 81.3,71.1 69.2,64.1 		"/>
	<polygon class="tp-11 sp-1 st0" points="35.4,75.4 47.5,68.4 47.5,54.3 35.4,61.3 		"/>
	<polygon class="tp-12 st0 sp-2" points="52.3,73.8 52.3,87.9 64.5,94.9 64.5,81 		"/>
	<polygon class="tp-13 st0 sp-2" points="35.4,94.9 47.6,87.9 47.6,73.8 35.4,80.8 		"/>
	<polygon class="tp-14 sp-1 st0" points="33,43.2 20.8,50.1 33,57.2 45.1,50.2 		"/>
	<polygon class="tp-15 st0 sp-2" points="18.5,85.2 30.7,78.1 30.7,64 18.5,71 		"/>
	<polygon class="tp-16 st0 sp-2" points="19.4,54.7 16.1,52.8 3.9,59.9 16.1,67 28.4,59.9 		"/>
	<polygon class="tp-17 st0 sp-2" points="19.4,45.5 28.4,40.4 16.2,33.3 3.9,40.3 16.1,47.4 		"/>
	<polygon class="tp-18 st0 sp-2" points="30.6,22.1 18.5,15.1 18.5,29.2 30.6,36.3 		"/>
		
	</g>
</g>
</svg>`;
        const {fundDetail, assets, bankAccountList, inProcess,fundStatus} = this.props;
        const timeStatus = checkTimeStatus(this.props.timeInfo);
        if (inProcess) {
            return (
                <Modal className="rtl modal-style buy-fund" show={this.props.sellFundModal}
                       backdropClassName="multi-level-modal"
                       onHide={this.handleClose.bind(this)} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>درخواست ابطال</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row px-4">
                                <div className="col-md-12 p-3 text-center">
                                    <InlineSVG src={svgSource}/>
                                    <p className="text-center mt-2">لطفا کمی صبر کنید...</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        } else {
            const fundInfo = fundDetail.fund;
            const {bankAccount} = this.state.fields;
            const {errors, fields, mask} = this.state;
            const bankAccountOption = prepareSelectData('bankList', bankAccountList);
            let btnActive = true;
            if (assets === null) {
                btnActive = false;
            }
            return (
                <Modal className="rtl modal-style buy-fund" show={this.props.sellFundModal}
                       backdropClassName="multi-level-modal"
                       onHide={this.handleClose.bind(this)} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>درخواست ابطال</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row mb-3">
                                <div className="col-md-8 col-8">
                                    <div className="text-dark pt-md-3 pl-md-3">
                                        <h5 className="title">صندوق سرمایه گذاری {fundInfo.abbreviation}</h5>
                                        <p className="subtitle">
                                            <small className="bold">
                                                {fundInfo.fundTypeTitle}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-4">
                                    <img src="/img/admin/logo-admin.svg" className="logo"
                                         alt="..."/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 px-0 px-md-2">
                                    <div className="table-responsive">
                                        <table className="table table-bordered assets">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <span>تعداد واحد های شما:</span>
                                                    <span>{assets ? convertCurrencyTpye(assets.quantity) : "0"}</span>
                                                </td>
                                                <td>
                                                    <span>ارزش دارایی شما:</span>
                                                    <span>{assets ? convertCurrencyTpye(assets.assetValue) : "0"}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <form className="w-100" onSubmit={this.handleSubmitRevoke.bind(this)}>
                                        <div className="">
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="bankAccount" className="account-label">شماره حساب
                                                        بانکی
                                                        شما</label>

                                                    <Select
                                                        className={["select-search ", errors['bankAccount'] ? 'is-invalid' : ''].join('')}
                                                        value={bankAccount}
                                                        onChange={(info) => this.handleChangeSelect(info, 'bankAccount')}
                                                        options={bankAccountOption}
                                                        name="bankAccount"
                                                        isSearchable
                                                        placeholder="شماره حساب واریزی"
                                                    />

                                                </div>

                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">

                                                    <CurrencyInput
                                                        className={["form-control ", errors['amount'] ? 'is-invalid' : ''].join('')}
                                                        value={mask.amount}
                                                        name="amount"
                                                        inputType="text"
                                                        onChange={(input) => {
                                                            this.calcUnit2Amount(input, "amount");
                                                        }}
                                                        placeholder="مبلغ معادل (ريال)"
                                                        suffix=" ریال"
                                                        precision="0"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">

                                                    <OverlayTrigger placement={"bottom"}
                                                                    overlay={<Tooltip id="loginDescription">
                                                                        با توجه به بروز نبودن قیمت صدور تعداد واحد های
                                                                        شما برابر یا کمتر از این تعداد خواهد بود
                                                                    </Tooltip>}>
                                                        <CurrencyInput
                                                            className={["form-control ", errors['unit'] ? 'is-invalid' : ''].join('')}
                                                            inputType="text"
                                                            value={mask.unit}
                                                            allowEmpty={false}
                                                            name="unit"
                                                            onChange={(input) => {
                                                                this.calcUnit2Amount(input, "unit");
                                                            }}
                                                            placeholder="تعداد ابطال"
                                                            suffix=" واحد"
                                                            precision="0"
                                                        />
                                                    </OverlayTrigger>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                            <textarea name="description" rows="4"
                                      className={["form-control ", errors['description'] ? 'is-invalid' : ''].join('')}
                                      onChange={this.handleChangeInput.bind(this)}
                                      placeholder="توضیحات"
                            />
                                                </div>

                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <p
                                                        className={["rights", fields.rights === null ? "" : "active", errors['rights'] && fields.rights === null ? "invalid" : ""].join(' ')}
                                                    >
                                                        <span className="choose" onClick={this.handleRightsAccept}>
                                        <i className="fa fa-circle"/>
                                        قوانین و مقررات را می پذیرم
                                    </span>

                                                    </p>
                                                    {
                                                        timeStatus ? (
                                                            <p className="rights necessary">در صورت ثبت درخواست بعد از
                                                                ساعت 16
                                                                درخواست در روز کاری بعد بررسی خواهد
                                                                شد.</p>
                                                        ) : (
                                                            <p className="rights necessary"/>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 w-100"/>
                                            <div className="col-md-6 text-right my-2 action-area">
                                                <button type="submit" className="btn btn-sell btn-lg w-100"
                                                        disabled={!btnActive || !fundStatus}
                                                >
                                                    <i className="fa fa-download"/>
                                                    ابطال
                                                    <span>{convertCurrencyTpye(fundInfo.navSale)}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        }


    }
}

const mstp = state => ({
    sellFundModal: state.sellFundModal,
    timeInfo: state.timeInfo,
    fundStatus: state.fundStatus
});

export default connect(mstp)(SellFundModal);