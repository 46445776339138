import React, {Component} from 'react';
import Property from "./property";
import About from "./hometab";
import Footer from "../../main/footer";
import TopSlide1 from "./topSlide1";
import TopSlide2 from "./topSlide2";
import TopSlide3 from "./topSlide3";
import VideoCenter from "./videoCenter";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./../../main/Header";
import {setStore} from "trim-redux";
import {connect} from 'trim-redux';
import {clearUserInfoLoginAgain, setBackgroundDefault} from "../../../services/dashboard";
import {pagingHandler} from "../../../services/fund";

AOS.init({
    duration: 1200,
});

class Home extends Component {
    constructor(props) {
        super(props);
        clearUserInfoLoginAgain();
        setBackgroundDefault();
    }

    componentDidMount() {
        localStorage.removeItem('auth');
        setStore({loading: false});
    }

    render() {
        return (
            <div>
                <Header/>
                <TopSlide1/>
                <TopSlide2/>
                <TopSlide3/>
                <About/>
                <Property/>
                <VideoCenter/>
                <Footer/>
            </div>
        );
    }
}

const mstp = state => ({
    loading: state.loading
});

export default connect(mstp)(Home);
