import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <section className=" rtl py-5 bg-black">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <ul className="text-white footer-list">
                                <li>
                                    <i className="fa fa-circle"/>
                                    <a target="_blank" href="https://www.seo.ir/">
                                        سازمان بورس اوراق بهادار
                                    </a>
                                </li>
                                <li>
                                    <i className="fa fa-circle"/>
                                    <a target="_blank" href="https://www.ifb.ir/">
                                        فرابورس ایران
                                    </a>
                                </li>
                                <li>
                                    <i className="fa fa-circle"/>
                                    <a target="_blank" href="http://www.fipiran.com/">
                                        مرکز پردازش اطلاعات مالی
                                    </a>
                                </li>
                                <li>
                                    <i className="fa fa-circle"/>
                                    <a target="_blank" href="http://www.tsetmc.com/">
                                        مدیری فن آوری بورس تهران
                                    </a>
                                </li>
                                <li>
                                    <i className="fa fa-circle"/>
                                    <a target="_blank" href="http://www.sejam.ir/">
                                        سامانه جامع ثبت اطلاعات مشتریان (سجام)
                                    </a>
                                </li>
                                <li>
                                    <i className="fa fa-circle"/>
                                    <a target="_blank" href="http://www.sena.ir/">
                                        پایگاه خبری بازار سرمایه ایران
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="mt-4">
                                <ul className="list-inline">
                                    <li className="list-inline-item mx-2">
                                        <img src="/img/icon/shaparak.png" alt=""/>
                                    </li>
                                    <li className="list-inline-item mx-2">
                                        <img src="/img/icon/saman.png" alt=""/>
                                    </li>
                                    <li className="list-inline-item mx-2">
                                        <img src="/img/icon/cbank.png" alt=""/>
                                    </li>
                                    <li className="list-inline-item mx-2">
                                        <img src="/img/icon/enamad.png" alt=""/>
                                    </li>
                                    <li className="list-inline-item mx-2">
                                        <img src="/img/icon/sazmandehi.png" alt=""/>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Footer;