import React, {Component} from 'react';
import {getFundAssetsBarChart} from "../../../services/fund";
import {toast} from "react-toastify";
import _error from "../../../assets/helper/errorMsgHelper";

class CustomBarChart extends Component {
    state = {
        series: [
            {id: 1, index: "bankDeposit", name: "سپرده بانکی", value: "", color: "#ff4968"},
            {id: 2, index: "bonds", name: "اوراق مشارکت", value: "", color: "#38b998"},
            {id: 3, index: "fiveSharesWithHighestWeight", name: "پنج سهم", value: "", color: "#90278e"},
            {id: 4, index: "otherShares", name: "سایر سهام ها", value: "", color: "#2095f2"},
            {id: 5, index: "otherAssets", name: "سایر دارایی ها", value: "", color: "#2e3140"},
        ],
        fundAssetsInfo: {},
        inProcess: true
    };

    componentDidMount() {
        getFundAssetsBarChart()
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({
                        fundAssetsInfo: res.data.fundAsset,
                        inProcess: false
                    })
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(() => {
                toast.error(_error.SystemError);
            })
    }

    render() {
        const {fundAssetsInfo, series} = this.state;
        return (
            <div className="col-md-12 h-bar-chart p-0">
                <div className="d-flex flex-row bd-highlight mb-1">
                    {
                        series.map(row => {
                                return (
                                    <div key={row.id}
                                         style={{width: fundAssetsInfo[row.index] + "%", background: row.color}}
                                         className="bd-highlight text-center px-2 py-2 unit">%{fundAssetsInfo[row.index]}</div>
                                )
                            }
                        )
                    }
                </div>
                <div className="d-flex flex-row bd-highlight label">
                    {
                        series.map(row => {
                            return (
                                <div key={row.id} className="bd-highlight label-tag col">
                                    {row.name}
                                    <i
                                        style={{color: row.color}}
                                        className="fa fa-square-full"/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default CustomBarChart;