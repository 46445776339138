import React, {Component} from 'react';
import {Link} from "react-router-dom";

class AdminSideBar extends Component {
    render() {
        return (

            <nav className="rtl bg-light border-left sidebar">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link to="/dashboard" className="nav-link active">
                                <img src="/img/admin/icon/fund.svg" width="35" height="auto" alt=""/>
                                <span className="sr-only">(current)</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>

        );
    }
}

export default AdminSideBar;