import React from "react";


class Aside extends React.Component {
    render() {
        return (
            <div className={`aside-div relative flexed ${this.props.active ? 'active' : null}`}
                 onClick={() => this.props.handleDetail(this.props.i)}
            >
                <div className="rtl">
                    <i className={["icon fa ",this.props.isNew ? "fa-circle" : ""].join(" ")}/>
                    <span className={["title", this.props.isNew ? "bold" : ""].join(' ')}>موضوع نامه:</span>
                    <span className={[this.props.isNew ? "bold" : ""].join(' ')}>{this.props.title}</span>
                </div>
                <span className="small-date">{this.props.date}</span>
            </div>
        );
    }
}

export default Aside;