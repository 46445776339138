import React, {Component} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {
    getLocalStorage,
    getMessages,
    getMessagesById, getMsgTag,
    setMessageReplay
} from './../../../services/fund';
import _error from './../../../assets/helper/errorMsgHelper.json';
import {toast} from 'react-toastify';
import moment from 'moment-jalaali';
import Aside from "./message/aside"
import Ticket from "./message/ticket";
import {checkValidation, persianDigit} from "../../../services/dashboard";
import _default from "../../../assets/helper/default";
import _ from "lodash";
import NewMessageModal from "./modal/newMessageModal";
import {setStore} from "trim-redux";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageList: [],
            messageDetail: [],
            targetMessage: {},
            activeMessage: 0,
            fields: {
                textMsg: ""
            },
            reqiredField: {
                textMsg: {}
            },
            errors: {},
            noReplay: false,
            tags: []
        }
    }

    componentDidMount() {
        this.getAllMsg();
    }

    getAllMsg = () => {
        getMessages().then(res => {
            if (res.data.isSuccess) {
                this.setState({
                    messageList: res.data.contactUsMessages.contactUsMessages,
                    activeMessage: 0,
                    targetMessage: [],
                    messageDetail: []
                })
            } else {
                let msg = res.data.exceptions[0];
                msg = msg.exception.persianDescription;
                toast.error(msg);
            }

        }).catch(err => {
            console.log(err)
        });
    };

    handleChangeInput = (event) => {
        let fields = this.state.fields;
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    };

    handleScrollBottom = () => {
        let div = document.querySelector(".to-scroll");
        div.scrollTop = div.scrollHeight;
    };

    handlegetDetailMsg = (i) => {
        getMessagesById(i).then(res => {
            if (res.data.isSuccess) {
                let target = this.state.messageList.filter(row => row.id === i);
                target = target[0];
                this.notAllowReplay = target.contactUsMessageStateId === 4 || target.contactUsMessageStateId === 5;
                this.setState({
                    messageDetail: res.data.contactUsMessage,
                    activeMessage: i,
                    targetMessage: target,
                    fields: {textMsg: ""},
                    noReplay: this.notAllowReplay
                });
                this.handleScrollBottom();
            } else {
                let msg = res.data.exceptions[0];
                msg = msg.exception.persianDescription;
                // toast.error(msg);
            }
        }).catch(err => {
            console.log(err)
        });
    };

    handleNewMessage = () => {
        setStore({newMessageModal: true});
        getMsgTag()
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({tags: res.data.tags})
                }
            })
            .catch(err => console.log(err))
    };

    checkValidationForm(callback) {
        let {fields, reqiredField} = this.state;
        let errors = {};
        let formIsValid = true;

        for (let fieldIndex in reqiredField) {
            const validUnit = checkValidation(fields[fieldIndex] === null ? '' : fields[fieldIndex], reqiredField[fieldIndex]);
            if (!validUnit) {
                errors[fieldIndex] = _error.isRequire;
            }
            formIsValid = formIsValid && validUnit;
        }
        this.setState({errors}, () => {
            return callback(formIsValid);
        });

    }

    handleSendReplay(e) {
        e.preventDefault();
        let {fields, errors, targetMessage, noReplay} = this.state;
        if (!noReplay) {
            this.checkValidationForm(sts => {
                if (sts) {
                    setMessageReplay(fields.textMsg, targetMessage.id)
                        .then(res => {
                            if (res.data.isSuccess) {
                                this.handlegetDetailMsg(targetMessage.id);
                            } else {
                                let msg = res.data.exceptions[0];
                                msg = msg.exception.persianDescription;
                                // toast.error(msg);
                            }
                        })
                        .catch(err => console.log(err));
                } else {
                    console.log(errors);
                }
            })
        } else {
            toast.error(_error.notAllowReplay)
        }


    }

    needy = {id: false};

    checkRepeated(id) {
        let res = true;
        if (this.needy.id == false || this.needy.id != id) {
            res = false;
            this.needy.id = id;
        } else {
            res = true;
            this.needy.id = id;
        }
        return res;
    }

    render() {
        const userInfo = getLocalStorage();
        const userId = userInfo[_default.userInfoTokenName]['userId'];
        const {targetMessage, messageDetail, activeMessage, fields, errors} = this.state;
        return (
            <section className="user-panel main-body height100vh">
                <div className="chat-wrapper height100">
                    <div className="row height100 pl-3">
                        <a className="btn-add text-white circle flexed" onClick={this.handleNewMessage.bind(this)}>
                            <i className="fa fa-plus"/>
                        </a>
                        <aside className="col-md-3 back-silver height100">
                            <div className="search-holder relative">
                                <input
                                    onChange={(e) => {
                                    }}
                                    type="search"
                                    className="search-input width100 padding8 radius8 centered"
                                    value="جستجو"/>
                            </div>
                            <div className="asides overflow-auto">
                                {
                                    this.state.messageList.map((item, index) => {
                                        return (
                                            <Aside key={index} title={item.subject} i={item.id}
                                                   handleDetail={this.handlegetDetailMsg.bind(this)}
                                                   isNew={item.lastChangeByAdmin}
                                                   active={this.state.activeMessage === item.id}
                                                   date={moment(item.createDateTime, 'YYYY-M-DTHH:mm').format('jYYYY-jM-jD - HH:mm')}/>
                                        )
                                    })
                                }
                            </div>
                        </aside>
                        <div className="col-md-9 tickets height100">
                            {activeMessage === 0 ?
                                (
                                    <div className="text-center pt-5">
                                        <p>لطفا پیام مورد نظر را انتخاب کنید</p>
                                    </div>
                                )
                                :
                                (
                                    <>
                                        <div style={{'flex': '4'}}>
                                            <div className="main-ticket rtl radius8">
                                                <div className="line relative p-2">
                                                    <span className="title">موضوع نامه:</span>
                                                    <span>{targetMessage.subject}</span>
                                                    <span
                                                        className="small-date fl padding8">{persianDigit(moment(targetMessage.createDateTime).format('jYYYY/jM/jD hh:mm'))}</span>
                                                </div>
                                                <span className="p-2 fr">متن نامه:</span>
                                                <div
                                                    className="cleared overflow-auto padding168 rtl back-white radius8">
                                                    {messageDetail.body}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid overflow-auto to-scroll" style={{'flex': '7'}}>
                                            <div className="row padding168">
                                                {
                                                    _.isEmpty(messageDetail) ?
                                                        ("ok") :
                                                        messageDetail.histories.map(msg => {
                                                            return (
                                                                <Ticket key={msg.id}
                                                                        repeated={this.checkRepeated(msg.entryUserId)}
                                                                        date={persianDigit(moment(msg.entryDate).format("jYYYY/jM/jD-hh:mm"))}
                                                                        dir={msg.entryUserId === userId ? "outgo" : "income"}>
                                                                    {msg.description}
                                                                </Ticket>
                                                            );
                                                        })
                                                }


                                            </div>
                                        </div>
                                        <div className="flexed p-3 rtl replay-msg" style={{'flex': '1'}}>
                                            <button className="btn-send circle flexed"
                                                    disabled={this.state.noReplay}
                                                    onClick={this.handleSendReplay.bind(this)}>
                                                <i className="fas fa-chevron-right"/>
                                            </button>
                                            <textarea
                                                disabled={this.state.noReplay}
                                                rows="3"
                                                name="textMsg"
                                                onChange={this.handleChangeInput.bind(this)}
                                                value={fields.textMsg}
                                                className={["form-control send back-silver radius16 border-silver py-3 px-4 ml-2", errors['textMsg'] ? 'is-invalid' : ''].join(' ')}
                                                placeholder="متن پیام"

                                            />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <NewMessageModal getAllMsg={this.getAllMsg.bind(this)} tags={this.state.tags}/>
            </section>
        );
    }
}

export default App;
