import React, {Component} from 'react';
import TopFundBox from "./topFundBox";
import FundListSlide from "./fundListSlide";
import FundDetail from "./fundDetail";
import {getCustomerAssets, GetFundInfoByFundId, getFundSimples} from "../../../services/fund";
import {toast} from "react-toastify";
import _error from "../../../assets/helper/errorMsgHelper";
import OrderBar from "./orderBar";



class Dashboard extends Component {

    state = {
        fundList: [],
        customerAssets: [],
        inProcessFundInfo: true,
        inProcessFundDetail: true,
        inProcessCustometrAssets: true,
        selectedFund: 0,
        selectedFundInfo: {}
    };

    componentDidMount() {
        getFundSimples()
            .then(res => {
                if (res.data.isSuccess) {
                    const info = res.data.fundSimpleInfos.fundSimples;
                    this.setState({
                        fundList: info,
                        inProcessFundInfo: false,
                        selectedFund: info[0]["registerNumber"]
                    });
                    this.getFundDetail(info[0]["registerNumber"]);
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(() => {
                toast.error(_error.SystemError)
            });

        getCustomerAssets()
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({
                        customerAssets: res.data.fundLicenseAsset,
                        inProcessCustometrAssets: false
                    })
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    // toast.error(msg);
                }
            })
            .catch(() => {
                toast.error(_error.SystemError)
            })
    }

    getFundDetail(fundRegId) {
        GetFundInfoByFundId(fundRegId)
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({
                        selectedFundInfo: res.data.fundInfo,
                        inProcessFundDetail: false
                    })
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(() => {
                toast.error(_error.SystemError)
            })
    }


    render() {
        return (
            <div className="main-body">
                <TopFundBox/>
                <FundListSlide fundList={this.state.fundList}
                               inProcess={this.state.inProcessFundInfo}
                               inProcessCustomerAssets={this.state.inProcessCustometrAssets}
                               customerAssetInfo={this.state.customerAssets}
                />
                <FundDetail inProcess={this.state.inProcessFundDetail}
                            inProcessCustomerAssets={this.state.inProcessCustometrAssets}
                            customerAssetInfo={this.state.customerAssets}
                            fundDetail={this.state.selectedFundInfo}

                />
                <OrderBar
                    inProcessCustomerAssets={this.state.inProcessCustometrAssets}
                    customerAssetInfo={this.state.customerAssets}
                />
            </div>
        );
    }
}

export default Dashboard;