import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {connect, setStore} from "trim-redux";
import {GetOrderReceiptByOrderId} from "../../../../services/fund";
import {toast} from "react-toastify";

class SuccessFundBuyModal extends Component {


    state = {};

    handleClose() {
        setStore({successFundBuyReceiptModal: false, newOrder: {}});
    }

    handleOrderReceiptInfo = (orderId) => {
        GetOrderReceiptByOrderId(orderId)
            .then(res => {
                if (res.data.isSuccess) {
                    setStore({orderReceiptInfo: res.data.htmlBody, successFundBuyReceiptPreviewModal: true})
                } else {
                    let msg = res.data.exceptions[0];
                    msg = msg.exception.persianDescription;
                    toast.error(msg);
                }
            })
            .catch(err => console.log(err))
    };

    render() {
        const {newOrder} = this.props;
        return (
            <>
                <Modal className="rtl modal-style success-buy" show={this.props.successFundBuyReceiptModal}
                       backdropClassName="multi-level-modal"
                       onHide={this.handleClose.bind(this)} backdrop="static">
                    <Modal.Header closeButton={false}>
                        <Modal.Title>صدور موفق</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-0 pt-2 pb-4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-center py-3">
                                        <img className="icon" src="/img/admin/icon/fund-buy-green.svg" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="row px-5">
                                <div className="col-md-12 description">
                                    <p>
                                        {
                                            `
                                           کاربر عزیز سفارش شما به شماره ${newOrder.id} با موفقیت ثبت شد. برای مشاهده رسید، روی
                                        "رسید سفارش" کلیک کنید.
                                            `
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="row px-5">
                                <div className="col-md-12 actions">
                                    <div className="row">
                                        <div className="col-md-1 w-100"/>
                                        <div className="col-md-5">
                                            <a className="btn btn-confirm d-block"
                                               onClick={this.handleClose.bind(this)}
                                            >تایید</a>
                                        </div>
                                        <div className="col-md-5">
                                            <a className="btn btn-receipt d-block"
                                               onClick={() => this.handleOrderReceiptInfo(newOrder.id)}
                                            >رسید سفارش</a>
                                        </div>
                                        <div className="col-md-1 w-100"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mstp = state => ({
    successFundBuyReceiptModal: state.successFundBuyReceiptModal,
    newOrder: state.newOrder
});

export default connect(mstp)(SuccessFundBuyModal);